<template>
  <div class="section-container" id="digitize">
    <WelcomeVideo />
    <div class="horizontal-container">
      <div class="section-text">
        <h2 class="hidden hidden-left" v-focus="'showElement'">
          {{ t.m11000_7 || "La opción perfecta para tu" }}
          <span class="highlighted">{{ t.m11000_8 || "restaurante" }}</span>
        </h2>
        <p class="hidden" v-focus="'showElement'">
          {{
            t.m11000_366 ||
            "Incrementa la eficiencia de tu negocio y obtén todo lo necesario para seguir creciendo en una sola plataforma. Nos adaptamos a todo tipo de tamaños, cocinas y despensas."
          }}
        </p>
        <b-button
          v-if="show"
          class="button-black-white"
          @click="businessTypes()"
          >{{ t.m11000_9 || "Ver tipos de negocio" }}</b-button
        >
      </div>
      <div class="visual-content desktop">
        <video
          autoplay
          loop
          muted
          playsinline
          disablePictureInPicture
          controlsList="nodownload"
        >
          <source
            src="../../../public/videos/tipos-de-negocio.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
    <div class="horizontal-container" id="digitize-cards">
      <div
        v-for="(item, index) in cardsList"
        v-bind:key="index"
        class="info-card hidden hidden-bottom"
        id="black-card"
        v-focus="'showElement'"
      >
        <img class="icon" :src="item.icon" :alt="item.alt" />
        <div class="card-text">
          <p class="explanation">{{ item.description }}</p>
          <a
            v-if="item.name === 'toteatUser'"
            @click="goToPricing('/cliente-toteat', item.name, 'support-title')"
          >
            {{ item.link }}
          </a>
          <a v-else @click="redirect(item.name)">{{ item.link }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixinGlobals from "@/components/_mixins/globalMixins";
import WelcomeVideo from "./WelcomeVideoHome.vue";
import plansIcon from "../../../public/svgs/icons-svg/icono-planes-toteat.svg";
import demoIcon from "../../../public/svgs/icons-svg/icono-demo-toteat.svg";
import growWhiteIcon from "../../../public/svgs/icons-svg/icono-blog-blanco-toteat.svg";

export default {
  name: "DigitizeComponent",
  components: {
    WelcomeVideo,
  },
  mixins: [mixinGlobals],
  data() {
    return {
      show: false,
    };
  },
  computed: {
    cardsList() {
      return [
        {
          icon: plansIcon,
          name: "prices",
          alt: this.t.m11000_10 || "planes toteat",
          description:
            this.t.m11000_13 || "Digitalízate con nuestro plan de inicio",
          link: `${this.t.m11000_16 || "Ver planes"} ->`,
        },
        {
          icon: demoIcon,
          name: "contactSales",
          alt: this.t.m11000_11 || "demo toteat",
          description: this.t.m11000_14 || "Explora el software Toteat",
          link: `${this.t.m11000_6 || "Agenda una DEMO"} ->`,
        },
        {
          icon: growWhiteIcon,
          name: "toteatUser",
          alt: this.t.m11000_12 || "blog toteat",
          description:
            this.t.m11000_367 || "¿Eres usuario Toteat y tienes dudas?",
          link: `${this.t.m11000_368 || "Contáctanos"} ->`,
        },
      ];
    },
  },
  methods: {
    goTo(refName) {
      const elem = document.getElementById(refName);
      const yOffset = -200;
      const y = elem.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    },
    goToPricing(path, pathName, refName) {
      if (!window.location.pathname.includes(path)) {
        var th = this;
        this.$router.push({ name: pathName }).then(() => {
          setTimeout(function () {
            th.goTo(refName);
          }, 200);
        });
      } else {
        this.goTo(refName);
      }
    },
    redirect(value) {
      if (this.$store.state.luces.language) {
        this.$router.push({
          name: value,
          params: {
            lang: this.$store.state.luces.language,
          },
        });
      }
    },
  },
};
</script>
