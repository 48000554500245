<template>
  <div class="sort-images">
    <div class="left-images">
      <img
        id="first-img"
        src="../../../public/images/toteat-services/reportes-toteat.jpg"
        alt="reportes toteat"
      />
      <img
        id="second-img"
        src="../../../public/images/bank-images/pago-mesa-toteat.jpg"
        alt="pago mesa toteat"
      />
    </div>
    <div class="right-images">
      <img
        id="third-img"
        src="../../../public/images/toteat-services/punto-de-venta-toteat.jpg"
        alt="punto de venta toteat"
      />
      <div class="middle-images">
        <img
          id="fourth-img"
          src="../../../public/images/bank-images/pedido-qr-toteat.jpg"
          alt="pedidos qr toteat"
        />
        <img
          id="fifth-img"
          src="../../../public/images/bank-images/codigo-qr-toteat.jpg"
          alt="codigo qr toteat"
        />
      </div>
      <img
        id="sixth-img"
        src="../../../public/images/toteat-services/punto-de-venta-1-toteat.jpg"
        alt="pos toteat"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SortImagesSolutions",
};
</script>
