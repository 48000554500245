var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-container",attrs:{"id":"toteat-integrations"}},[_c('div',{staticClass:"vertical-container"},[_c('div',{staticClass:"vertical-container",attrs:{"id":"middle-text"}},[_c('h3',{attrs:{"id":"delivery-integration"}},[_vm._v(" "+_vm._s(_vm.t.m11000_99 || "Elige las integraciones que necesitas")+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.t.m11000_100 || "Para hacer nuestro servicio aún más perfecto contamos con muchas integraciones para ofrecer una gama más amplia de soluciones.")+" ")])]),_c('div',{staticClass:"horizontal-container",attrs:{"id":"final-integrations"}},[_c('div',{staticClass:"vertical-container"},[(
            _vm.integrations[_vm.countryIntegrations].digitalPaymentsList.length > 0
          )?_c('div',{staticClass:"integration",attrs:{"id":"payment-method"}},[_c('p',[_vm._v(" "+_vm._s(_vm.t.m11000_101 || "Integraciones de medios de pago digitales")+" ")]),_c('div',{staticClass:"integrations-blocks"},_vm._l((_vm.integrations[_vm.countryIntegrations]
                .digitalPaymentsList),function(item,index){return _c('b-tooltip',{key:index,attrs:{"label":item.name,"position":"is-bottom"}},[_c('img',{staticClass:"block",attrs:{"src":item.logo,"alt":item.name}})])}),1)]):_vm._e(),(_vm.integrations[_vm.countryIntegrations].billingList.length > 0)?_c('div',{staticClass:"integration",attrs:{"id":"billing"}},[_c('p',[_vm._v(_vm._s(_vm.t.m11000_103 || "Integraciones de facturación"))]),_c('div',{staticClass:"integrations-blocks"},_vm._l((_vm.integrations[_vm.countryIntegrations]
                .billingList),function(item,index){return _c('b-tooltip',{key:index,attrs:{"label":item.name,"position":"is-bottom"}},[_c('img',{staticClass:"block",attrs:{"src":item.logo,"alt":item.name}})])}),1)]):_vm._e(),(_vm.integrations[_vm.countryIntegrations].lastMilleList.length > 0)?_c('div',{staticClass:"integration",attrs:{"id":"last-mille"}},[_c('p',[_vm._v(_vm._s(_vm.t.m11000_432 || "Integraciones de última milla"))]),_c('div',{staticClass:"integrations-blocks"},_vm._l((_vm.integrations[_vm.countryIntegrations]
                .lastMilleList),function(item,index){return _c('b-tooltip',{key:index,attrs:{"label":item.name,"position":"is-bottom"}},[_c('img',{staticClass:"block",attrs:{"src":item.logo,"alt":item.name}})])}),1)]):_vm._e()]),_c('div',{staticClass:"vertical-container"},[(
            _vm.integrations[_vm.countryIntegrations].devicesPaymentsList.length > 0
          )?_c('div',{staticClass:"integration",attrs:{"id":"payment-processor"}},[_c('p',[_vm._v(" "+_vm._s(_vm.t.m11000_102 || "Integraciones de medios de pago presenciales")+" ")]),_c('div',{staticClass:"integrations-blocks"},_vm._l((_vm.integrations[_vm.countryIntegrations]
                .devicesPaymentsList),function(item,index){return _c('b-tooltip',{key:index,attrs:{"label":item.name,"position":"is-bottom"}},[_c('img',{staticClass:"block",attrs:{"src":item.logo,"alt":item.name}})])}),1)]):_vm._e(),(_vm.integrations[_vm.countryIntegrations].deliveryList.length > 0)?_c('div',{staticClass:"integration",attrs:{"id":"delivery"}},[_c('p',[_vm._v(_vm._s(_vm.t.m11000_104 || "Integraciones de delivery"))]),_c('div',{staticClass:"integrations-blocks"},_vm._l((_vm.integrations[_vm.countryIntegrations]
                .deliveryList),function(item,index){return _c('b-tooltip',{key:index,attrs:{"label":item.name,"position":"is-bottom"}},[_c('img',{staticClass:"block",attrs:{"src":item.logo,"alt":item.name}})])}),1)]):_vm._e(),(_vm.integrations[_vm.countryIntegrations].selfServiceList.length > 0)?_c('div',{staticClass:"integration",attrs:{"id":"self-service"}},[_c('p',[_vm._v(_vm._s(this.t.m11000_445 || "Integraciones de autoservicio"))]),_c('div',{staticClass:"integrations-blocks"},_vm._l((_vm.integrations[_vm.countryIntegrations]
                .selfServiceList),function(item,index){return _c('b-tooltip',{key:index,attrs:{"label":item.name,"position":"is-bottom"}},[_c('img',{staticClass:"block",attrs:{"src":item.logo,"alt":item.name}})])}),1)]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }