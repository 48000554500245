import { decodeSpecial, encodeSpecial } from "./comun";

var storage =
  typeof window.localStorage === "undefined" ? undefined : window.localStorage;
var sStorage =
  typeof window.sessionStorage === "undefined"
    ? undefined
    : window.sessionStorage;

function set(key, value, sesionStorage = 0) {
  let st = sesionStorage ? sStorage : storage;
  if (st) {
    st.setItem(key, JSON.stringify(value));
    return true;
  }
  return false;
}

function get(key, sesionStorage) {
  let st = sesionStorage ? sStorage : storage;
  if (st) {
    let temp = st.getItem(key);
    if (temp) {
      try {
        return JSON.parse(temp);
      } catch (err) {
        return temp;
      }
    }
  }
  return null;
}

function remove(key, sesionStorage = 0) {
  let st = sesionStorage ? sStorage : storage;
  if (st) {
    st.removeItem(key);
    return true;
  }
  return false;
}

var car = 69;
var AB = "US";
var BA = String.fromCharCode(65) + String.fromCharCode(car);
// Esto se coloca con funciones de nombre no tan explicito por seguridad
function saveU(data) {
  // Por ahora se hace sin encriptar, a futuro encriptar para dificultar lectura
  set(AB + BA, data);
}

function loadU() {
  return get(AB + BA);
}

function removeU() {
  remove("ORDHIS", 1);
  remove("ORDONP", 1);
  remove("lastlistado", 1);
  remove("FBT");
  return remove(AB + BA);
}

function setEncoded(key, value, sesionStorage = 0, callback) {
  value = encodeSpecial(value);
  return set(key, value, sesionStorage, callback);
}

function getEncoded(key, sesionStorage = 0, callback) {
  var newCallback = function (value) {
    value = decodeSpecial(value);
    if (callback) callback(value);
  };
  var temp = get(key, sesionStorage, newCallback);
  return decodeSpecial(temp);
}

window.setEncoded = setEncoded;
window.getEncoded = getEncoded;

function borrarLocalStorage(callback, mantieneLogin = false) {
  if (storage) {
    console.log("Borrando datos locales");
    var dgid = window.localStorage.getItem("DGID");
    if (mantieneLogin) {
      var amb = storage.getItem("AMB");
      var lastrl = storage.getItem("LASTRL");
      var LT = storage.getItem("LT3");
      var KDS = storage.getItem("KDSUSER");
    }

    storage.clear();
    sessionStorage.clear();
    if (amb) storage.setItem("AMB", amb);
    if (dgid) storage.setItem("DGID", dgid);
    if (lastrl) storage.setItem("LASTRL", lastrl);
    if (LT) storage.setItem("LT3", LT);
    if (KDS) storage.setItem("KDSUSER", KDS);
    try {
      window.GLOBALsw.sw.postMessage({ msg: "deletecache" });
    } catch (err) {
      console.log("Error al mandar mensaje de deletecache a sw");
    }
    if (callback) callback(true);
  } else {
    if (callback) callback(false);
  }
}

export {
  set,
  get,
  remove,
  saveU,
  loadU,
  removeU,
  getEncoded,
  setEncoded,
  borrarLocalStorage,
};
