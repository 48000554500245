<template>
  <div class="section-container" id="reviews">
    <div v-if="width > 1023" class="review-container-desktop">
      <div class="user-review" id="first-user">
        <img
          v-if="country === 'co'"
          src="../../../public/images/toteat-clients/amor-perfecto-exitos-colombia-toteat.png"
          alt="amor perfecto toteat"
          class="hidden hidden-left"
          v-focus="'showElement'"
        />
        <img
          v-else
          src="../../../public/images/toteat-clients/calderon-elpatron-restaurante-toteat.webp"
          alt="muncher toteat"
          class="hidden hidden-left"
          v-focus="'showElement'"
        />
        <div class="section-text" id="review-text">
          <div class="upper-text">
            <h1 class="hidden hidden-right" v-focus="'showElement'">
              {{ t.m11000_47 || "#Éxitos" }}
            </h1>
            <h1
              id="spacing-left"
              class="hidden hidden-left"
              v-focus="'showElement'"
            >
              Toteat
            </h1>
            <div class="circle-divider">
              <img
                id="icon-divider"
                v-for="index in 5"
                :key="index"
                src="../../../public/svgs/icons-svg/divider.svg"
                :alt="t.m11000_52 || 'testimonios toteat'"
              />
            </div>
            <p id="review-title" class="hidden" v-focus="'showElement'">
              {{ t.m11000_48 || "“Nos permite estar tranquilos...”" }}
            </p>
            <p id="review-description" class="hidden" v-focus="'showElement'">
              {{
                t.m11000_49 ||
                "“Yo quiero hacer una recomendación, de lo que ha sido para nosotros la buena experiencia de manejar el sistema Toteat. Nos permite estar tranquilos en el manejo de nuestro restaurante, en cualquier lugar que estemos podemos tener control de él y acceso a lo que está pasando día a día...”"
              }}
            </p>
          </div>
          <div class="lower-text hidden hidden-bottom" v-focus="'showElement'">
            <hr />
            <p id="review-description">
              {{
                t.m11000_389 ||
                "Carlos Calderón - Restaurante El Patrón, Valparaíso"
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="user-review" id="second-user">
        <img
          v-if="country === 'co'"
          src="../../../public/images/toteat-clients/dilip-dark-kitchen-colombia-toteat.png"
          alt="dilip colombia"
          class="hidden hidden-right"
          v-focus="'showElement'"
        />
        <img
          v-else
          src="../../../public/images/toteat-clients/karla-martinez-siam-thai-toteat.jpg"
          alt="le birra cervecería"
          class="hidden hidden-right"
          v-focus="'showElement'"
        />
        <div class="section-text" id="review-text">
          <div class="upper-text">
            <div class="circle-divider">
              <img
                id="icon-divider"
                v-for="index in 5"
                :key="index"
                src="../../../public/svgs/icons-svg/divider.svg"
                alt="reviews toteat"
              />
            </div>
            <p id="review-title" class="hidden" v-focus="'showElement'">
              {{
                t.m11000_50 ||
                "“Necesitábamos un sistema de administración digital.”"
              }}
            </p>
            <p id="review-description" class="hidden" v-focus="'showElement'">
              {{
                t.m11000_51 ||
                "“Lo que me encanta de los reportes es que puedo revisar en mi teléfono las mesas que hay, cuánto se está vendiendo, qué plato está saliendo más. Puedo llevar el control de las cosas que tengo que comprar…”"
              }}
            </p>
          </div>
          <div class="lower-text hidden hidden-bottom" v-focus="'showElement'">
            <hr />
            <p id="review-description">
              {{ t.m11000_390 || "Karla Martínez - Restaurante Siam Thai" }}
            </p>
          </div>
        </div>
      </div>
      <b-button class="button-black-white" @click="successStories()">{{
        t.m11000_53 || "Ver más casos de éxito"
      }}</b-button>
    </div>
    <div v-else class="review-container-mobile">
      <b-carousel
        :arrow-hover="arrowHover"
        :indicator="indicator"
        :interval="interval"
        :icon-size="iconSize"
      >
        <b-carousel-item>
          <div class="user-review" id="first-user">
            <div class="img-review">
              <img
                v-if="country === 'co'"
                src="../../../public/images/toteat-clients/amor-perfecto-exitos-colombia-mobile-toteat.png"
                alt="amor perfecto toteat"
              />
              <img
                v-else
                src="../../../public/images/toteat-clients/elpatron-restaurante-mobile-toteat.webp"
                alt="muncher dark kitchen"
              />
            </div>
            <div class="section-text" id="review-text">
              <div class="upper-text">
                <h1>{{ t.m11000_47 || "#Éxitos" }}</h1>
                <h1 id="spacing-left">Toteat</h1>
                <div class="circle-divider">
                  <img
                    id="icon-divider"
                    v-for="index in 5"
                    :key="index"
                    src="../../../public/svgs/icons-svg/divider.svg"
                    alt="feedback toteat"
                  />
                </div>
                <p id="review-title">
                  {{ t.m11000_48 || "“Nos permite estar tranquilos...”" }}
                </p>
                <p id="review-description">
                  {{
                    t.m11000_49 ||
                    "“Yo quiero hacer una recomendación, de lo que ha sido para nosotros la buena experiencia de manejar el sistema Toteat. Nos permite estar tranquilos en el manejo de nuestro restaurante, en cualquier lugar que estemos podemos tener control de él y acceso a lo que está pasando día a día...”"
                  }}
                </p>
              </div>
              <div class="lower-text">
                <hr />
                <p id="review-description">
                  {{
                    t.m11000_389 ||
                    "Carlos Calderón - Restaurante El Patrón, Valparaíso"
                  }}
                </p>
              </div>
            </div>
          </div>
        </b-carousel-item>
        <b-carousel-item>
          <div class="user-review" id="second-user">
            <div class="img-review">
              <img
                v-if="country === 'co'"
                src="../../../public/images/toteat-clients/dilip-dark-kitchen-colombia-toteat.png"
                alt="dilip dark kitchen"
              />
              <img
                v-else
                src="../../../public/images/toteat-clients/karla-martinez-siam-thai-toteat.jpg"
                alt="le birra toteat"
              />
            </div>
            <div class="section-text" id="review-text">
              <div class="upper-text">
                <h1>{{ t.m11000_47 || "#Éxitos" }}</h1>
                <h1 id="spacing-left">Toteat</h1>
                <div class="circle-divider">
                  <img
                    id="icon-divider"
                    v-for="index in 5"
                    :key="index"
                    src="../../../public/svgs/icons-svg/divider.svg"
                    alt="retroalimentación toteat"
                  />
                </div>
                <p id="review-title">
                  {{
                    t.m11000_50 ||
                    "“Necesitábamos un sistema de administración digital.”"
                  }}
                </p>
                <p id="review-description">
                  {{
                    t.m11000_51 ||
                    "“Lo que me encanta de los reportes es que puedo revisar en mi teléfono las mesas que hay, cuánto se está vendiendo, qué plato está saliendo más. Puedo llevar el control de las cosas que tengo que comprar…”"
                  }}
                </p>
              </div>
              <div class="lower-text">
                <hr />
                <p id="review-description">
                  {{ t.m11000_390 || "Karla Martínez - Restaurante Siam Thai" }}
                </p>
              </div>
            </div>
          </div>
        </b-carousel-item>
      </b-carousel>
      <b-button class="button-black-white" @click="successStories()">{{
        t.m11000_53 || "Ver más casos de éxito"
      }}</b-button>
    </div>
  </div>
</template>

<script>
import mixinGlobals from "@/components/_mixins/globalMixins";

export default {
  name: "ReviewsComponent",
  props: { country: String },
  mixins: [mixinGlobals],
  data() {
    return {
      indicator: false,
      interval: 10000,
      arrowHover: false,
      iconPack: "mdi",
      iconSize: "is-medium",
      width: window.innerWidth,
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
    },
    successStories() {
      this.$router.push({ name: "successStories" });
    },
  },
};
</script>
