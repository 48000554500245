<template>
  <div class="ruta idiomas" v-on:click="idiomaAbierto = !idiomaAbierto">
    {{ t.m1_68 }} <i :class="'cus-' + idiomaActual.ix"></i>
    <div v-if="idiomaAbierto">
      <div
        v-for="idioma in idiomasDisponibles"
        v-bind:key="idioma"
        v-on:click="cambiaIdioma(idioma.ix)"
      >
        {{ idioma.nati }} <i :class="'cus-' + idioma.ix"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { reloadPagina } from "@/plugins/BaseToteat/libs/comun";
import * as $lstorage from "@/plugins/BaseToteat/libs/lstorage";
import { BASE_TRADS } from "./core";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Selectidioma",
  data() {
    return {
      idiomaAbierto: true,
    };
  },
  computed: {
    luces() {
      return this.$store.state.luces;
    },
    idiomasDisponibles() {
      return this.t.idiomas;
    },
    idiomaActual() {
      let temp = this.idiomasDisponibles.filter(
        (idi) => idi.ix === this.t.idiomaActual
      );
      if (temp && temp.length) return temp[0];
      else {
        return { ix: "es-ES", nati: "Español" };
      }
    },
  },
  methods: {
    cambiaIdioma(idi) {
      // if (idi != this.idiomaActual.ix) {
      $lstorage.set(BASE_TRADS.storageIdiomaUser, idi);
      // console.log("y, Va a hacer reload luego de timeout");
      setTimeout(reloadPagina, 10);
      // }
      return true;
    },
  },
  created() {
    console.log("En created Selectidioma");
  },
  mounted() {},
};
</script>
