<template>
  <div class="main-container" id="success-stories">
    <div class="main-text">
      <h2>{{ t.m11000_76 || "Casos de éxito" }}</h2>
      <p class="header-paragraph">
        {{
          t.m11000_382 ||
          "Descubre cómo nuestra plataforma mejora la experiencia de nuestras marcas."
        }}
      </p>
    </div>
    <div class="desktop">
      <div class="horizontal-review-container">
        <div class="section-text">
          <p class="user-name">{{ mainReview.name }}</p>
          <p class="user-testimony">
            {{ mainReview.review }}
          </p>
        </div>
        <iframe
          id="ytplayer"
          type="text/html"
          height="315"
          :src="mainReview.youtubeUrl"
          frameborder="0"
          allowfullscreen
        />
      </div>
    </div>
    <div class="mobile">
      <div class="vertical-review-container">
        <iframe
          id="ytplayer"
          type="text/html"
          height="315"
          :src="mainReview.youtubeUrl"
          frameborder="0"
          allowfullscreen
          allow="autoplay"
        />
        <div class="section-text">
          <div class="open-container">
            <p class="user-title">
              {{ mainReview.name }}
            </p>
            <div
              :class="{ 'clicked-on': mainReview.isShowing }"
              class="clickable-icon"
              id="hover-black"
              v-on:click="toggleShow"
            ></div>
          </div>
          <p v-if="mainReview.isShowing" class="user-testimony">
            {{ mainReview.review }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mainReview: {
        isShowing: false,
        name: "Muncher",
        review:
          this.t.m11000_383 ||
          "Gracias a las funcionalidades que tiene #Toteat 😎, Muncher logró tener el control total de sus despachos, centralizar todas sus apps de delivery, organizar a su staff de cocina y sus ingredientes están a salvo! ¿Y tú qué esperas para sumarte a la digitalización? 🙌",
        youtubeUrl:
          "https://www.youtube.com/embed/hd-slSjxXUc?controls=0&modestbranding=1&color=white",
      },
    };
  },
  methods: {
    toggleShow() {
      this.mainReview.isShowing = !this.mainReview.isShowing;
    },
  },
};
</script>
