<template>
  <div class="main-container" id="not-found-page">
    <div class="not-found-container">
      <div class="section-text">
        <h1>404</h1>
        <h4>{{ t.m11000_422 || "Lo sentimos, página no encontrada." }}</h4>
        <b-button class="button-black" @click="redirect('home')">{{
          t.m11000_423 || "Home"
        }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import mixinGlobals from "@/components/_mixins/globalMixins";

export default {
  name: "NotFoundView",
  mixins: [mixinGlobals],
  metaInfo() {
    return {
      title: "Not Found 404",
    };
  },
  methods: {
    redirect(value) {
      if (this.$store.state.luces.language) {
        this.$router.push({
          name: value,
          params: {
            lang: this.$store.state.luces.language,
          },
        });
      }
    },
  },
  mounted() {
    var language = document.location.pathname.split("/")[1];
    var routeName = this.$route.name;
    this.updateLang(language, routeName);
  },
};
</script>
