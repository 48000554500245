<template>
  <div v-if="content" v-on="scrollSolutions()" class="solutions">
    <Header />
    <SortImages />
    <CheckInSolution />
    <ServicesA />
    <Integrations :country="country" />
    <ServicesB />
    <Marketplace />
  </div>
</template>

<script>
import Header from "@/components/Solutions/HeaderSolutions.vue";
import SortImages from "@/components/Solutions/SortImagesSolutions.vue";
import CheckInSolution from "@/components/Solutions/CheckInSolutions.vue";
import ServicesA from "@/components/Solutions/ServicesASolutions.vue";
import Integrations from "@/components/Solutions/IntegrationsSolutions.vue";
import ServicesB from "@/components/Solutions/ServicesBSolutions.vue";
import Marketplace from "@/components/Solutions/MarketplaceSolutions.vue";
import mixinGlobals from "@/components/_mixins/globalMixins";

export default {
  name: "SolutionsView",
  mixins: [mixinGlobals],
  metaInfo() {
    return {
      title: this.t.m11000_426 || "Soluciones para tu negocio",
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            this.t.m11000_78 ||
            "Sin importar el servicio que ofrezcas, todas las herramientas que necesitas en un solo lugar; Punto de Venta, Kitchen Display System (KDS), Integraciones de Delivery, Check In, Autoatención y más.",
        },
      ],
      // link: [
      //   {
      //     rel: "canonical",
      //     href: window.location.href,
      //   },
      // ],
    };
  },
  components: {
    Header,
    SortImages,
    CheckInSolution,
    ServicesA,
    Integrations,
    ServicesB,
    Marketplace,
  },
  props: {
    refName: {
      type: String,
      default: "",
    },
  },
  methods: {
    scrollSolutions() {
      setTimeout(() => this.scrolling(this.refName), 500);
    },
    scrolling(refName) {
      if (refName) {
        const elem = document.getElementById(refName);
        const yOffset = -150;
        const y =
          elem.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    },
    setContent() {
      this.content = this.t.m11000_78;
    },
  },
  computed: {
    country() {
      return this.$store.state.luces.language.split("-")[1].toLowerCase();
    },
    content() {
      return this.t.m11000_78;
    },
    title() {
      return "Soluciones y funcionalidades";
    },
  },
  mounted() {
    var language = document.location.pathname.split("/")[1];
    var routeName = this.$route.name;
    this.updateLang(language, routeName);
  },
  watch: {
    country: {
      handler: function () {
        this.country;
      },
    },
  },
};
</script>
