<template>
  <div class="section-container" id="services">
    <div
      v-if="country != 'pe' && country != 'mx'"
      class="info-container-left-white"
    >
      <div class="base-container">
        <img
          id="main-img-desktop"
          src="../../../public/images/bank-images/ticket-example.jpeg"
          alt="punto de venta"
        />
        <img
          id="main-img-mobile"
          src="../../../public/images/bank-images/ticket-example-bigger.jpeg"
          alt="punto de venta"
        />
        <div class="container-text">
          <h4 class="hidden hidden-right" v-focus="'showElement'">
            {{
              t.m11000_36 ||
              "Documentos Tributarios Electrónicos (DTE): Boletas y Facturas."
            }}
          </h4>
          <p
            class="hidden hidden-right"
            id="container-description"
            v-focus="'showElement'"
          >
            {{
              t.m11000_37 ||
              "Folios ilimitados e historial de tus ventas en un solo lugar."
            }}
          </p>
          <ul>
            <li
              v-for="(item, index) in DTEList"
              v-bind:key="index"
              class="hidden hidden-right"
              v-focus="'showElement'"
            >
              <img
                src="../../../public/svgs/icons-svg/check.svg"
                id="check-icon"
                :alt="t.m11000_38 || 'dte toteat'"
              />
              <p id="list-text">
                {{ item }}
              </p>
            </li>
          </ul>
        </div>
        <img
          id="overhead"
          src="../../../public/images/toteat-services/electronic-ticket.png"
          :alt="t.m11000_39 || 'boleta electrónica'"
        />
      </div>
    </div>
    <div class="info-container-right-white">
      <div class="base-container" id="integration-animation">
        <div class="container-text">
          <h4 class="hidden hidden-top" v-focus="'showElement'">
            {{
              t.m11000_43 || "Todas tus órdenes de delivery en un solo lugar."
            }}
          </h4>
          <p
            id="container-description"
            class="hidden hidden-bottom"
            v-focus="'showElement'"
          >
            {{
              t.m11000_44 ||
              "Olvídate de todo el desorden. Toteat concentra todos tus deliveries en un solo dispositivo que puedes vincular directamente a cada punto de venta."
            }}
          </p>
        </div>
        <div class="visual-content">
          <Lottie :options="defaultOptions" />
        </div>
        <!-- <img
          src="../../../public/images/toteat-services/integrations.png"
          alt="tipos de negocio toteat"
          class="mobile"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import mixinGlobals from "@/components/_mixins/globalMixins";
import Lottie from "./LottieHome.vue";
import { animation } from "../../../public/videos/integraciones";
export default {
  data() {
    return {
      DTEList: [
        this.t.m11000_40 || "Conexión directa con su sistema tributario.", //VER CÓMO SE HARÁ PARA CADA PAÍS!!!
        this.t.m11000_41 || "Impresión de boletas sin comisión extra.",
        this.t.m11000_42 || "Genera notas de crédito de forma fácil y rápida.",
      ],
      defaultOptions: { animationData: animation },
    };
  },
  mixins: [mixinGlobals],
  components: {
    Lottie,
  },
  computed: {
    country() {
      return this.$store.state.luces.language.split("-")[1].toLowerCase();
    },
  },
  watch: {
    country: {
      handler: function () {
        this.country;
      },
    },
  },
};
</script>
