import { countryWhatsapp, AVAILABLES_LANG } from "@/js/utils";
import { reloadPagina } from "@/plugins/BaseToteat/libs/comun";
import * as $lstorage from "@/plugins/BaseToteat/libs/lstorage";
import { BASE_TRADS } from "@/plugins/Traducciones/core.js";

const mixinGlobals = {
  computed: {
    luces() {
      return this.$store.state.luces;
    },
    whatsappNumber() {
      return (
        "https://api.whatsapp.com/send?phone=" +
        countryWhatsapp(
          this.$store.state.luces.language.split("-")[1].toLowerCase()
        ) +
        "&text=Hola,%20Necesito%20mas%20informaci%C3%B3n!"
      );
    },
  },
  methods: {
    updateLang(language, routeName) {
      if (language != "") {
        if (
          AVAILABLES_LANG.includes(language) &&
          language != this.$store.state.luces.language
        ) {
          this.$store.dispatch("updateLucesProp", { language: language });
          $lstorage.set(BASE_TRADS.storageIdiomaUser, language);
          setTimeout(reloadPagina);
        } else {
          this.$router
            .push({
              name: routeName,
              params: { lang: this.$store.state.luces.language },
            })
            .catch(() => {});
        }
      } else {
        if (routeName != "home") {
          this.$router
            .push({
              name: routeName,
              params: { lang: this.$store.state.luces.language },
            })
            .catch(() => {});
        } else {
          this.$router
            .push({
              name: "homeLang",
              params: { lang: this.$store.state.luces.language },
            })
            .catch(() => {});
        }
      }
    },
  },
  directives: {
    focus: {
      isLiteral: true,
      inserted: (el, binding) => {
        let f = () => {
          let rect = el.getBoundingClientRect();
          let inView =
            rect.width > 0 &&
            rect.height > 0 &&
            rect.top >= 0 &&
            rect.bottom <=
              (window.innerHeight || document.documentElement.clientHeight);
          if (inView) {
            el.classList.add(binding.value);
            window.removeEventListener("scroll", f);
          }
        };
        window.addEventListener("scroll", f);
        f();
      },
    },
  },
};

export default mixinGlobals;
