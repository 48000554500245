/* eslint-disable vue/multi-word-component-names */
// console.log("Leyendo codigo de index Traducciones");
import { actualizaBASEtrads, TRADS, tp } from "./core";
import Selectidioma from "./Selectidioma.vue";

const Traducciones = {
  install(Vue, options) {
    if (!options || !options.modulo) {
      throw new Error(
        "Por favor inicialice el plugin Traducciones con al menos modulo en opciones."
      );
    }
    // console.log("Instalando Traducciones...");
    actualizaBASEtrads(options);
    //const t = {}
    Vue.component("Selectidioma", Selectidioma);
    TRADS.t.install = function () {
      Object.defineProperty(Vue.prototype, "t", {
        get() {
          return TRADS.t;
        },
      });
      // console.log("Instalando Traducciones seteado t");
    };
    Vue.use(TRADS.t);
    // console.log("Instalando Traducciones finalizada primera fase");
    tp.install = function () {
      Object.defineProperty(Vue.prototype, "tp", {
        get() {
          return tp;
        },
      });
      // console.log("Instalando Traducciones seteado tp");
    };
    Vue.use(tp);

    // console.log("Instalado traducciones");
  },
};

export default Traducciones;
