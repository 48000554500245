/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-vars */
import * as $lstorage from "@/plugins/BaseToteat/libs/lstorage.js";
import axios from "axios";
import {
  horaActualUnix,
  resolvePromesa,
  rejectPromesa,
  getBASE_URL_SERVICES,
  getBASE_URL_SERVICES11000,
  ambienteInicial,
  esString,
  esArray,
} from "@/plugins/BaseToteat/libs/comun";
import { getLangFromCountry } from "@/js/utils";

const ambiente = ambienteInicial();

const TRADS = {
  idiomas: [
    {
      pr: 99,
      ix: "en-US",
      nati: "English",
      sidi: "es-ES",
      nidi: "Inglés Internacional",
      tool: "Select English language...",
      ac: true,
    },
    {
      pr: 100,
      ix: "es-ES",
      nati: "Español",
      sidi: "es-ES",
      nidi: "Español",
      tool: "Idioma Español...",
      ac: true,
    },
  ],
  idiomaActual: "es-ES",
  ok: false,
  t: {},
};
const t = TRADS.t;

var tp = function () {
  // numero de parametros es variable
  // Se pasa como primer parámetro el nombre de la variable que guarda la traduccion ej:m9000_1 o p9000_1
  // y en los parametros siguientes los valores a incluir en la traducción si se trata de una
  // traduccion con reemplazos.
  // a su vez el segundo parametro puede ser un arreglo con todos los parametros para el reemplazo
  // si se desea invocar así cuando al construirla se ignora el numero exacto de parametros (usado en mensajes)

  //console.log("En tp")
  //console.log(arguments)
  var l = arguments.length;
  if (l == 0) return "";
  var trad = arguments[0];
  if (typeof trad == "undefined") return "";

  if (esString(trad)) {
    if (typeof t[trad] != "undefined") var objtrad = t[trad];
    else objtrad = trad;
  } else objtrad = trad;

  if (esString(objtrad)) return objtrad;

  var tempArg = [];
  if (typeof arguments[1] != "undefined") {
    if (esArray(arguments[1])) tempArg = arguments[1];
    else {
      for (i = 1; i < arguments.length; i++) tempArg.push(arguments[i]);
    }
  }

  var abuscar = "";
  var reemplazo = "";

  if (typeof objtrad != "undefined") {
    l = objtrad["p"]; // Numero de parametros definido en la traduccion
    var texto = objtrad["t"];

    for (var i = 0; i < l; i++) {
      var tt = i + 1;
      abuscar = "%" + tt + "%";
      reemplazo = "";
      if (typeof tempArg[i] != "undefined") reemplazo = tempArg[i];

      var re = new RegExp(abuscar, "g");

      texto = texto.replace(re, tempArg[i]);
    }
  } else {
    console.log("ERROR EN TRADUCCION!!!!!");
    console.log(arguments);
    texto = "";
  }

  return texto;
};

const BASE_TRADS = {
  modulo: 11000,
  baseURL: getBASE_URL_SERVICES11000(),
  servicio: "/rest/idiomas",
  cacheDEV: 120,
  cachePROD: 600,
  storageKeyIdiomas: "IDIOMAS",
  storageIdiomaUser: "IDI",
};

function actualizaBASEtrads(options) {
  console.log("EN actualizaBASEtrads");
  for (var prop in BASE_TRADS) {
    if (BASE_TRADS.hasOwnProperty(prop)) {
      if (typeof options[prop] != "undefined") BASE_TRADS[prop] = options[prop];
    }
  }
  if (BASE_TRADS.modulo == 2000) {
    BASE_TRADS.baseURL = getBASE_URL_SERVICES();
    BASE_TRADS.servicio = "/srv/idiomas";
  }
  console.log("Seteadas opciones de Traducciones modulo " + BASE_TRADS.modulo);
}

const MESES = {
  en: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  es: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
  ca: [
    "Gener",
    "Febrer",
    "Mar\u00e7",
    "Abril",
    "Maig",
    "Juny",
    "Juliol",
    "Agost",
    "Setembre",
    "Octubre",
    "Novembre",
    "Desembre",
  ],
};

const DIAS = {
  en: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  es: [
    "Domingo",
    "Lunes",
    "Martes",
    "Mi\u00e9rcoles",
    "Jueves",
    "Viernes",
    "S\u00e1bado",
  ],

  ca: [
    "Diumenge",
    "Dilluns",
    "Dimarts",
    "Dimecres",
    "Dijous",
    "Divendres",
    "Dissabte",
  ],
};

const DIAS_SHORT = {
  en: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  es: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],

  ca: ["Dg", "Dl", "Dt", "Dc", "Dj", "Dv", "Ds"],
};

const RELATIVOS = {
  en: ["Yesterday", "Today", "Tomorrow"],
  es: ["Ayer", "Hoy", "Mañana"],
  ca: ["Ahir", "Avui", "Demà"],
};

function getArrayMeses(short, idioma) {
  if (!idioma) idioma = getIdioma();
  idioma = idioma.toLowerCase().substr(0, 2);
  if (typeof MESES[idioma] == "undefined") idioma = "en";
  var meses = MESES[idioma];
  if (short) {
    if (short === true || isNaN(short)) short = 3;
    return meses.map((mes) => mes.substr(0, short));
  } else return meses;
}
function getArrayDias(short, idioma) {
  if (!idioma) idioma = getIdioma();
  idioma = idioma.toLowerCase().substr(0, 2);
  if (typeof DIAS[idioma] == "undefined") idioma = "en";
  if (!short || (short !== true && short !== 2 && !isNaN(short))) {
    var dias = DIAS[idioma];
    if (short) {
      return dias.map((dia) => dia.substr(0, short));
    } else return dias;
  } else {
    return DIAS_SHORT[idioma];
  }
}

function getDiaRelativo(tipo, idioma) {
  //-1 = ayer, 0=hoy, 1=Mañana
  tipo = tipo + 1;
  if (!idioma) idioma = getIdioma();
  idioma = idioma.toLowerCase().substr(0, 2);
  if (typeof DIAS[idioma] == "undefined") idioma = "en";
  return RELATIVOS[idioma][tipo];
}

import jsonES from "@/plugins/Traducciones/fallbacks/es-ES.json";
import jsonEN from "@/plugins/Traducciones/fallbacks/en-US.json";

function getTraducciones(idioma) {
  return new Promise((resolve, reject) => {
    if (TRADS.t && TRADS.t.m1_1) {
      resolve(TRADS.t);
      return;
    }
    if (!idioma) idioma = getIdioma();
    idioma = idioma.toLowerCase().substr(0, 2);
    if (idioma === "en") resolve(jsonEN);
    else resolve(jsonES);
  });
}

const IDIOMAS_DISPONIBLES = [
  "es",
  "en",
  "es-ES",
  "es-CL",
  "es-CO",
  "es-AR",
  "en-US",
];
var idiomaActual = null;

function getIdioma() {
  if (idiomaActual) return idiomaActual;

  var temp = getIdiomaDevice();
  if (temp && IDIOMAS_DISPONIBLES.indexOf(temp.substr(0, 2)) >= 0) {
    idiomaActual = temp;
  } else {
    idiomaActual = "es";
  }
  return idiomaActual;
}

function normalizaCodIdioma(codIdioma) {
  console.log("En normalizaCodIdioma, ", codIdioma);
  if (!codIdioma) return "";
  let temp = codIdioma.split("-");
  let idioma = temp[0].toLowerCase();
  if (temp.length > 1) idioma += "-" + temp[1].toUpperCase();
  else {
    if (idioma === "es") idioma = "es-ES";
    else if (idioma === "en") idioma = "en-US";
  }
  return idioma;
}

function getIdiomaDevice() {
  if (idiomaActual) return idiomaActual;

  // Primero revisa preferencia de idioma o ultimo idioma usuario no logueado
  var temp = $lstorage.get(BASE_TRADS.storageIdiomaUser);
  if (temp) {
    return normalizaCodIdioma(temp);
  }

  // Luego revisa info de conexion
  temp = $lstorage.get("CNIF");
  if (temp && temp.lang) {
    temp = temp.lang.split(",");
    temp = temp[0].split(";");
    return normalizaCodIdioma(temp[0]);
  } else {
    console.log(getLangFromCountry(window.GLOBALuserCountry));
    return normalizaCodIdioma(getLangFromCountry(window.GLOBALuserCountry));
  }
  // if (navigator && navigator.language)
  //   return normalizaCodIdioma(navigator.language);

  // return null;
}

function aFecha(fechaISO) {
  var fecha = fechaISO + "+00:00";
  var date = new Date(fecha);
  return date.toLocaleString();
}

function getIdiomasDisponibles() {
  return new Promise((resolve, reject) => {
    var url =
      BASE_TRADS.baseURL + BASE_TRADS.servicio + "?xmod=" + BASE_TRADS.modulo;
    if (ambiente === "DEV") var cache = BASE_TRADS.cacheDEV;
    else cache = BASE_TRADS.cachePROD;
    var ahora = horaActualUnix();
    var tempIdiomas = $lstorage.get(BASE_TRADS.storageKeyIdiomas);
    if (tempIdiomas && tempIdiomas.hora && ahora - tempIdiomas.hora < cache) {
      console.log("Se devuelven idiomas de storage");
      TRADS.idiomas = tempIdiomas.data;
      resolvePromesa(tempIdiomas.data, resolve);
      return true;
    }
    axios
      .get(url, {})
      .then(function (response) {
        console.log("Llego respuesta de axios");
        console.log(response);
        if (
          response &&
          response.data &&
          response.data.ok &&
          response.data.data
        ) {
          $lstorage.set(BASE_TRADS.storageKeyIdiomas, {
            hora: ahora,
            data: response.data.data,
          });
          TRADS.idiomas = response.data.data;
          resolvePromesa(response.data.data, resolve);
        } else {
          if (tempIdiomas && tempIdiomas.data) {
            TRADS.idiomas = tempIdiomas.data;
            resolvePromesa(tempIdiomas.data, resolve);
          } else rejectPromesa(null, resolve, reject);
        }
      })
      .catch((err) => {
        console.log(err);
        rejectPromesa(null, resolve, reject);
      });
  });
}

function getUrlStorageIdioma(idiomaObj, modulo) {
  var url = "";
  if (!modulo) modulo = BASE_TRADS.modulo;
  if (idiomaObj && idiomaObj.et && idiomaObj.ix) {
    url =
      "https://storage.googleapis.com/lang.toteat.com/trads/" +
      modulo +
      "/" +
      idiomaObj.ix +
      "/" +
      idiomaObj.et;
  }
  return url;
}

function setIdiomasTraducciones() {
  return new Promise((resolve, reject) => {
    console.log("En setIdiomasTraducciones");
    var idi = getIdiomaDevice();
    console.log("El idioma seteado es: ", idi);
    TRADS.ok = false;
    getIdiomasDisponibles()
      .then((idiomas) => {
        var urlTraducciones = "";
        if (idiomas) {
          let temp = idiomas.filter((idix) => idix.ix == idi);
          if (temp.length === 0) {
            idi = normalizaCodIdioma(idi.substr(0, 2));
            temp = idiomas.filter((idix) => idix.ix == idi);
          }
          if (temp.length === 0) {
            temp = idiomas.filter((idix) => idix.ix == "es-ES");
          }
          if (temp.length) {
            urlTraducciones = getUrlStorageIdioma(temp[0]);
          }
        }
        if (urlTraducciones) {
          axios
            .get(urlTraducciones, {})
            .then(function (response) {
              if (response && response.data) {
                TRADS.ok = true;
                TRADS.idiomaActual = idi;
                console.log("Leidas traducciones " + idi);
                resolvePromesa(response.data, resolve);
              } else {
                console.log("Se devuelven idiomas embebidos (no URL) " + idi);
                if (idi.substr(0, 2) === "es") {
                  TRADS.idiomaActual = "es-ES";
                  resolvePromesa(jsonES, resolve);
                } else {
                  TRADS.idiomaActual = "en-US";
                  resolvePromesa(jsonEN, resolve);
                }
              }
            })
            .catch((err) => {
              console.log(err);
              console.log(
                "ERROR en get traducciones, Se devuelven idiomas embebidos " +
                  idi
              );
              if (idi.substr(0, 2) === "es") {
                TRADS.idiomaActual = "es-ES";
                rejectPromesa(jsonES, resolve, reject);
              } else {
                TRADS.idiomaActual = "en-US";
                rejectPromesa(jsonEN, resolve, reject);
              }
            });
        } else {
          if (idi.substr(0, 2) === "es") {
            TRADS.idiomaActual = "es-ES";
            console.log("Se devuelven idiomas embebidos " + idi);
            rejectPromesa(jsonES, resolve, reject);
          } else {
            console.log("Se devuelven idiomas embebidos " + idi);
            TRADS.idiomaActual = "en-US";
            rejectPromesa(jsonEN, resolve, reject);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(
          "ERROR en get idiomas, Se devuelven idiomas embebidos " + idi
        );
        if (idi.substr(0, 2) === "es") {
          TRADS.idiomaActual = "es-ES";
          rejectPromesa(jsonES, resolve, reject);
        } else {
          TRADS.idiomaActual = "en-US";
          rejectPromesa(jsonEN, resolve, reject);
        }
      });
  });
}

function inicializaTraducciones(appT) {
  return new Promise((resolve) => {
    setIdiomasTraducciones()
      .then((resp) => {
        seteaTrads(resp, appT);
        resolvePromesa(true, resolve);
      })
      .catch((resp) => {
        seteaTrads(resp, appT);
        resolvePromesa(true, resolve);
      });
  });
}

function seteaTrads(trads, appT) {
  //TRADS.t=trads
  if (appT) {
    for (var prop in trads) {
      if (trads.hasOwnProperty(prop)) {
        TRADS.t[prop];
        appT[prop] = trads[prop];
      }
    }
    appT.idiomas = TRADS.idiomas;
    appT.idiomaActual = TRADS.idiomaActual;
  }
  console.log("seteaTrads Traducciones OK");
}

window.getIdiomasDisponibles = getIdiomasDisponibles;
window.setIdiomasTraducciones = setIdiomasTraducciones;
window.TRADS = TRADS;

export {
  getArrayDias,
  getArrayMeses,
  getDiaRelativo,
  getIdioma,
  getTraducciones,
  aFecha,
  seteaTrads,
  setIdiomasTraducciones,
  inicializaTraducciones,
  actualizaBASEtrads,
  BASE_TRADS,
  TRADS,
  t,
  tp,
};
