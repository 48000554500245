<template>
  <div v-if="show" class="section-container" id="marketplace">
    <div class="horizontal-container">
      <div class="section-text">
        <div class="upper-text">
          <h2>
            <span class="highlighted">Marketplace</span>para restaurantes y
            proveedores
          </h2>
        </div>
        <div class="container-img-mobile" v-if="width <= 915">
          <img
            src="../../../public/images/toteat-services/toteat-app.png"
            alt="check-in"
          />
        </div>
        <div class="horizontal-container" id="accordeon-text">
          <div class="section-text" id="restaurantes-text">
            <h6>PARA RESTAURANTES</h6>
            <h4>Abastece tu restaurante de una manera sencilla y eficaz.</h4>
            <p>
              Compra productos de diferentes proveedores en un solo lugar,
              optimiza tu tiempo y reduce el error en los procesos de compra.
              Usar Toteat Marketplace no tiene costo alguno para ti.
            </p>
            <b-button class="button-empty-white">
              Quiero comprar productos para mi restaurante
            </b-button>
          </div>
        </div>
      </div>
      <img
        v-if="width > 915"
        src="../../../public/images/toteat-services/toteat-app.png"
        alt="check-in"
      />
    </div>
    <div class="horizontal-container" id="accordeon-text">
      <img
        id="suppliers-img"
        src="../../../public/images/bank-images/marketplace-proveedores-toteat.jpg"
        alt="punto de venta"
      />
      <div class="section-text" id="suppliers-text">
        <h6>PARA PROVEEDORES</h6>
        <h4>Conectamos tu negocio con cientos de restaurantes</h4>
        <p>
          Vende a cientos de restaurantes que ya usan Toteat para llevar su
          operación. Contáctanos para empezar a distribuir tus productos y
          escalar tu negocio.
        </p>
        <b-button class="button-empty-white">
          Quiero vender mis productos en Toteat
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      width: window.innerWidth,
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
    },
  },
};
</script>
