<template>
  <div class="section-container" id="toteat-integrations">
    <div class="vertical-container">
      <div class="vertical-container" id="middle-text">
        <h3 id="delivery-integration">
          {{ t.m11000_99 || "Elige las integraciones que necesitas" }}
        </h3>
        <p>
          {{
            t.m11000_100 ||
            "Para hacer nuestro servicio aún más perfecto contamos con muchas integraciones para ofrecer una gama más amplia de soluciones."
          }}
        </p>
      </div>
      <div class="horizontal-container" id="final-integrations">
        <div class="vertical-container">
          <div
            v-if="
              integrations[countryIntegrations].digitalPaymentsList.length > 0
            "
            class="integration"
            id="payment-method"
          >
            <p>
              {{ t.m11000_101 || "Integraciones de medios de pago digitales" }}
            </p>
            <div class="integrations-blocks">
              <b-tooltip
                v-for="(item, index) in integrations[countryIntegrations]
                  .digitalPaymentsList"
                v-bind:key="index"
                :label="item.name"
                position="is-bottom"
              >
                <img :src="item.logo" :alt="item.name" class="block" />
              </b-tooltip>
            </div>
          </div>
          <div
            v-if="integrations[countryIntegrations].billingList.length > 0"
            class="integration"
            id="billing"
          >
            <p>{{ t.m11000_103 || "Integraciones de facturación" }}</p>
            <div class="integrations-blocks">
              <b-tooltip
                v-for="(item, index) in integrations[countryIntegrations]
                  .billingList"
                v-bind:key="index"
                :label="item.name"
                position="is-bottom"
              >
                <img :src="item.logo" :alt="item.name" class="block" />
              </b-tooltip>
            </div>
          </div>
          <div
            v-if="integrations[countryIntegrations].lastMilleList.length > 0"
            class="integration"
            id="last-mille"
          >
            <p>{{ t.m11000_432 || "Integraciones de última milla" }}</p>
            <div class="integrations-blocks">
              <b-tooltip
                v-for="(item, index) in integrations[countryIntegrations]
                  .lastMilleList"
                v-bind:key="index"
                :label="item.name"
                position="is-bottom"
              >
                <img :src="item.logo" :alt="item.name" class="block" />
              </b-tooltip>
            </div>
          </div>
        </div>
        <div class="vertical-container">
          <div
            v-if="
              integrations[countryIntegrations].devicesPaymentsList.length > 0
            "
            class="integration"
            id="payment-processor"
          >
            <p>
              {{
                t.m11000_102 || "Integraciones de medios de pago presenciales"
              }}
            </p>
            <div class="integrations-blocks">
              <b-tooltip
                v-for="(item, index) in integrations[countryIntegrations]
                  .devicesPaymentsList"
                v-bind:key="index"
                :label="item.name"
                position="is-bottom"
              >
                <img :src="item.logo" :alt="item.name" class="block" />
              </b-tooltip>
            </div>
          </div>
          <div
            v-if="integrations[countryIntegrations].deliveryList.length > 0"
            class="integration"
            id="delivery"
          >
            <p>{{ t.m11000_104 || "Integraciones de delivery" }}</p>
            <div class="integrations-blocks">
              <b-tooltip
                v-for="(item, index) in integrations[countryIntegrations]
                  .deliveryList"
                v-bind:key="index"
                :label="item.name"
                position="is-bottom"
              >
                <img :src="item.logo" :alt="item.name" class="block" />
              </b-tooltip>
            </div>
          </div>
          <div
            v-if="integrations[countryIntegrations].selfServiceList.length > 0"
            class="integration"
            id="self-service"
          >
            <p>{{ this.t.m11000_445 || "Integraciones de autoservicio" }}</p>
            <div class="integrations-blocks">
              <b-tooltip
                v-for="(item, index) in integrations[countryIntegrations]
                  .selfServiceList"
                v-bind:key="index"
                :label="item.name"
                position="is-bottom"
              >
                <img :src="item.logo" :alt="item.name" class="block" />
              </b-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mercadoPago from "../../../public/images/logos/toteat-integrations/mercado-pago-integracion-toteat.webp";
import chek from "../../../public/images/logos/toteat-integrations/chek-integracion-toteat.webp";
import mach from "../../../public/images/logos/toteat-integrations/mach-integracion-toteat.webp";
// import redsys from "../../../public/images/logos/toteat-integrations/redsys-integracion-toteat.webp";
import stripe from "../../../public/images/logos/toteat-integrations/stripe-integracion-toteat.webp";
import paypal from "../../../public/images/logos/toteat-integrations/paypal-integracion-toteat.webp";
// import globalpay from "../../../public/images/logos/toteat-integrations/globalPay-integracion-toteat.webp";
import nubox from "../../../public/images/logos/toteat-integrations/nubox-integracion-toteat.webp";
import bsale from "../../../public/images/logos/toteat-integrations/bsale-integracion-toteat.webp";
import dispapeles from "../../../public/images/logos/toteat-integrations/dispapeles-integracion-toteat.webp";
import detecno from "../../../public/images/logos/toteat-integrations/detecno-integracion-toteat.webp";
import factura from "../../../public/images/logos/toteat-integrations/factura-1-integracion-toteat.webp";
// import contaline from "../../../public/images/logos/toteat-integrations/contaline-integracion-toteat.webp";
// import gdexpress from "../../../public/images/logos/toteat-integrations/gdexpress-integracion-toteat.webp";
import facturante from "../../../public/images/logos/toteat-integrations/facturante-integracion-toteat.webp";
import sunat from "../../../public/images/logos/toteat-integrations/sunat-integracion-toteat.webp";
import redelcom from "../../../public/images/logos/toteat-integrations/redelcom-integracion-toteat.webp";
import transbank from "../../../public/images/logos/toteat-integrations/transbank-integracion-toteat.webp";
import clip from "../../../public/images/logos/toteat-integrations/clip-integracion-toteat.webp";
import uberEats from "../../../public/images/logos/toteat-integrations/uber-eats-integracion-toteat.webp";
import pedidosYa from "../../../public/images/logos/toteat-integrations/pedidos-ya-integracion-toteat.webp";
import rappi from "../../../public/images/logos/toteat-integrations/rappi-integracion-toteat.webp";
import justo from "../../../public/images/logos/toteat-integrations/justo-integracion-toteat.webp";
// import hambriento from "../../../public/images/logos/toteat-integrations/hambriento-integracion-toteat.webp";
import mercat from "../../../public/images/logos/toteat-integrations/mercat-integracion-toteat.webp";
import didi from "../../../public/images/logos/toteat-integrations/didi-integracion-toteat.webp";
// import stuart from "../../../public/images/logos/toteat-integrations/stuart-integracion-toteat.webp";
import cleverlynk from "../../../public/images/logos/toteat-integrations/cleverlynk-integracion-toteat.webp";
import uberDirect from "../../../public/images/logos/toteat-integrations/uber-direct-integracion-toteat.webp";
// import tapBeer from "../../../public/images/logos/toteat-integrations/tap-beer-integracion-toteat.webp";

export default {
  props: { country: String },
  data() {
    return {
      countryIntegrations: "cl",
    };
  },
  computed: {
    integrations() {
      return {
        ar: {
          digitalPaymentsList: [
            {
              name: "Mercado Pago",
              logo: mercadoPago,
            },
          ],
          devicesPaymentsList: [],
          billingList: [
            {
              name: "Facturante",
              logo: facturante,
            },
          ],
          deliveryList: [
            {
              name: "Pedidos Ya",
              logo: pedidosYa,
            },
            {
              name: "Rappi",
              logo: rappi,
            },
          ],
          lastMilleList: [],
          selfServiceList: [],
        },
        cl: {
          digitalPaymentsList: [
            {
              name: "Mercado Pago",
              logo: mercadoPago,
            },
            {
              name: "Chek",
              logo: chek,
            },
            {
              name: "Mach",
              logo: mach,
            },
          ],
          devicesPaymentsList: [
            {
              name: "Redelcom",
              logo: redelcom,
            },
            {
              name: "Transbank",
              logo: transbank,
            },
          ],
          billingList: [
            {
              name: "Nubox",
              logo: nubox,
            },
            {
              name: "Bsale",
              logo: bsale,
            },
          ],
          deliveryList: [
            {
              name: "Uber Eats",
              logo: uberEats,
            },
            {
              name: "Pedidos Ya",
              logo: pedidosYa,
            },
            {
              name: "Rappi",
              logo: rappi,
            },
            {
              name: "Justo",
              logo: justo,
            },
            {
              name: "Mercat",
              logo: mercat,
            },
            {
              name: "Didi",
              logo: didi,
            },
          ],
          lastMilleList: [
            {
              name: "Uber Direct",
              logo: uberDirect,
            },
          ],
          selfServiceList: [],
        },
        co: {
          digitalPaymentsList: [
            {
              name: "Mercado Pago",
              logo: mercadoPago,
            },
          ],
          devicesPaymentsList: [],
          billingList: [
            {
              name: "Factura 1",
              logo: factura,
            },
            {
              name: "Dispapeles",
              logo: dispapeles,
            },
          ],
          deliveryList: [
            {
              name: "Cleverlynk",
              logo: cleverlynk,
            },
            {
              name: "Didi",
              logo: didi,
            },
            {
              name: "Rappi",
              logo: rappi,
            },
            {
              name: "Justo",
              logo: justo,
            },
          ],
          lastMilleList: [],
          selfServiceList: [],
        },
        mx: {
          digitalPaymentsList: [
            {
              name: "Mercado Pago",
              logo: mercadoPago,
            },
            {
              name: "PayPal",
              logo: paypal,
            },
            {
              name: "Stripe",
              logo: stripe,
            },
          ],
          devicesPaymentsList: [
            {
              name: "Clip",
              logo: clip,
            },
          ],
          billingList: [
            {
              name: "Facturante",
              logo: facturante,
            },
            {
              name: "Detecno",
              logo: detecno,
            },
          ],
          deliveryList: [
            {
              name: "Uber Eats",
              logo: uberEats,
            },
            {
              name: "Didi",
              logo: didi,
            },
            {
              name: "Rappi",
              logo: rappi,
            },
            {
              name: "Justo",
              logo: justo,
            },
          ],
          lastMilleList: [
            {
              name: "Uber Direct",
              logo: uberDirect,
            },
          ],
          selfServiceList: [],
        },
        pe: {
          digitalPaymentsList: [
            {
              name: "Mercado Pago",
              logo: mercadoPago,
            },
          ],
          devicesPaymentsList: [],
          billingList: [
            {
              name: "Sunat",
              logo: sunat,
            },
            {
              name: "Bsale",
              logo: bsale,
            },
          ],
          deliveryList: [
            {
              name: "Rappi",
              logo: rappi,
            },
            {
              name: "Pedidos Ya",
              logo: pedidosYa,
            },
            {
              name: "Didi",
              logo: didi,
            },
          ],
          lastMilleList: [],
          selfServiceList: [],
        },
      };
    },
  },
  mounted() {
    if (Object.prototype.hasOwnProperty.call(this.integrations, this.country)) {
      this.countryIntegrations = this.country;
    }
  },
};
</script>
