<template>
  <div class="toteat-footer">
    <div class="footer-container">
      <div class="left-side">
        <img
          class="logo"
          alt="Toteat"
          src="../../public/images/logos/toteat-white-4k.png"
        />
        <div class="social-media" id="desktop">
          <a
            v-for="(social, index) in socialMedia"
            v-bind:key="index"
            :href="social.link"
            target="_blank"
            ><b-icon :icon="social.social" custom-size="mdi-36px"></b-icon
          ></a>
        </div>
        <div class="last-text">
          <div
            v-for="(place, index) in officesLocations"
            v-bind:key="index"
            class="toteat-all-locations"
          >
            {{ place.country }}<br />
            <a
              class="toteat-location"
              :href="place.googleMaps"
              target="_blank"
              >{{ place.location }}</a
            >
          </div>
        </div>
      </div>
      <div class="right-side">
        <div class="texts">
          <div class="first-text">
            <div class="section">
              <h5>{{ t.m11000_447 || "Productos" }}</h5>
              <ul class="link-elements">
                <li>
                  <a @click="goToPricing('/productos', 'point-of-sale')">POS</a>
                </li>
                <li>
                  <a @click="goToPricing('/productos', 'KDS-system')">KDS</a>
                </li>
                <li>
                  <a
                    @click="goToPricing('/productos', 'delivery-integration')"
                    >{{ t.m11000_69 || "Integraciones" }}</a
                  >
                </li>
                <li>
                  <a @click="goToPricing('/productos', 'invoices-title')">{{
                    t.m11000_67 || "Boleta y Factura Electrónica"
                  }}</a>
                </li>
                <li>
                  <a @click="goToPricing('/productos', 'checkin-title')">{{
                    t.m11000_25 || "Menú QR y Tienda Virtual"
                  }}</a>
                </li>
                <li>
                  <a @click="goToPricing('/productos', 'inventary-stock')">{{
                    t.m11000_70 || "Recetas e Inventario"
                  }}</a>
                </li>
                <li>
                  <a @click="goToPricing('/productos', 'analytics')">{{
                    t.m11000_110 || "Toteat Analytics"
                  }}</a>
                </li>
                <!-- <li>
                  <a style="pointer-events: none">Marketplace</a>
                </li> -->
              </ul>
            </div>

            <div class="section">
              <h5>{{ t.m11000_62 || "Tipos de negocio" }}</h5>
              <ul class="link-elements">
                <li>
                  <router-link
                    :to="{
                      name: 'restaurant',
                      params: { lang: luces.language },
                    }"
                  >
                    <a>{{ t.m11000_71 || "Restaurantes" }}</a>
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{ name: 'coffee', params: { lang: luces.language } }"
                  >
                    <a>{{ t.m11000_72 || "Cafeterías" }}</a>
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{ name: 'fastFood', params: { lang: luces.language } }"
                  >
                    <a>{{ t.m11000_140 || "Fast Food" }}</a>
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'darkKitchen',
                      params: { lang: luces.language },
                    }"
                  >
                    <a>{{ t.m11000_135 || "Dark Kitchen" }}</a>
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{ name: 'bar', params: { lang: luces.language } }"
                  >
                    <a>{{ t.m11000_73 || "Bares & Cervecerías" }}</a>
                  </router-link>
                </li>
                <!-- <li>
                  <router-link
                    :to="{
                      name: 'minimarket',
                      params: { lang: luces.language },
                    }"
                  >
                    <a>{{ t.m11000_74 || "Minimarkets & Almacenes" }}</a>
                  </router-link>
                </li> -->
              </ul>
            </div>
          </div>
          <div class="second-text">
            <div class="section">
              <h5>{{ t.m11000_65 || "Soy Toteat" }}</h5>
              <ul class="link-elements">
                <li>
                  <router-link
                    :to="{
                      name: 'toteatUser',
                      params: { lang: luces.language },
                    }"
                  >
                    <a>{{ t.m11000_370 || "Atención al cliente" }}</a>
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'getCertified',
                      params: { lang: luces.language },
                    }"
                  >
                    <a>{{ t.m11000_360 || "Certifícate con Toteat" }}</a>
                  </router-link>
                </li>
                <li>
                  <a class="button-white" @click="openModal">{{
                    t.m11000_244 || "¿Necesitas ayuda?"
                  }}</a>
                  <modal-web-to-case
                    :show-modal="showModal"
                    @close="closeModal"
                  />
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'toteatHelp',
                      params: { lang: luces.language },
                    }"
                  >
                    <a>ayuda.toteat.com</a>
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="section">
              <h5>{{ t.m11000_372 || "¿Aún no eres cliente?" }}</h5>
              <ul class="link-elements">
                <li>
                  <a :href="whatsappNumber" target="_blank">{{
                    t.m11000_66 || "Contáctate con ventas"
                  }}</a>
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'contactSales',
                      params: { lang: luces.language },
                    }"
                  >
                    <a>{{ t.m11000_6 || "Solicita una DEMO" }}</a>
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="section">
              <h5>{{ t.m11000_121 || "Sobre nosotros" }}</h5>
              <ul class="link-elements">
                <li>
                  <router-link
                    :to="{
                      name: 'whyToteat',
                      params: { lang: luces.language },
                    }"
                  >
                    <a>{{ t.m11000_63 || "¿Por qué Toteat?" }}</a>
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'successStories',
                      params: { lang: luces.language },
                    }"
                  >
                    <a>{{ t.m11000_76 || "Casos de éxito" }}</a>
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{ name: 'blog', params: { lang: luces.language } }"
                  >
                    <a>{{ t.m11000_120 || "Foodnology" }}</a>
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'workWithToteat',
                      params: { lang: luces.language },
                    }"
                  >
                    <a>{{ t.m11000_60 || "Trabaja con nosotros" }}</a>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="last-text-mobile">
          <div class="social-media" id="mobile">
            <a
              v-for="(social, index) in socialMedia"
              v-bind:key="index"
              :href="social.link"
              ><b-icon :icon="social.social" custom-size="mdi-36px"></b-icon
            ></a>
          </div>
          <div
            v-for="(place, index) in officesLocations"
            v-bind:key="index"
            class="toteat-location"
          >
            {{ place.country }}<br />
            <a
              class="toteat-location"
              :href="place.googleMaps"
              target="_blank"
              >{{ place.location }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="country-container">
      <hr />
      <div class="country-location">
        <div class="current-country">
          <b-icon icon="map-marker" custom-size="mdi-36px"></b-icon>
          <p class="editable-country" @click="modalActive = true">
            {{ (t.m11000_119 || "País:") + " " }}
            {{ toteatLanguages[selectedCountry].text }}
          </p>
        </div>
        <b-modal class="footer-modal" v-model="modalActive">
          <template>
            <div class="card">
              <ul>
                <li v-for="(value, key) in toteatCountries" v-bind:key="key">
                  <div
                    :class="{ 'active-country': selectedCountry === key }"
                    class="content"
                    v-on:click="
                      changeLanguage(toteatLanguages[key].ix, key, false)
                    "
                  >
                    {{ toteatLanguages[key].text }}
                  </div>
                </li>
              </ul>
            </div>
          </template>
        </b-modal>
      </div>
      <hr />
      <div class="last-text">
        <div class="terms-and-cookies">
          <a href="https://toteat.com/shared/privacy.html" target="_blank">{{
            t.m11000_358 || "Términos y Privacidad"
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../store/index";
// import { reloadPagina } from "@/plugins/BaseToteat/libs/comun";
import * as $lstorage from "@/plugins/BaseToteat/libs/lstorage";
import { BASE_TRADS } from "@/plugins/Traducciones/core.js";
import { COUNTRIES_LANG, COUNTRIES_NAMES } from "@/js/utils.js";
import mixinGlobals from "@/components/_mixins/globalMixins";
import ModalWebToCase from "@/components/WebToCaseModal.vue";

export default {
  mixins: [mixinGlobals],
  components: {
    "modal-web-to-case": ModalWebToCase,
  },
  data() {
    return {
      showModal: false,
      modalActive: false,
      toteatCountries: null,
      selectedCountry: null,
      officesLocations: [
        {
          country: "ARGENTINA",
          location: "Corrientes 800, CABA. Buenos Aires.",
          googleMaps: "https://goo.gl/maps/7916AbdqDULcYgqf9",
        },
        {
          country: "CHILE",
          location:
            "Av. Vitacura 2771, Piso 5 Oficina 504, Las Condes, Santiago de Chile.",
          googleMaps: "https://maps.app.goo.gl/T7TjVEAtmzGYAZMW8",
        },
        {
          country: "COLOMBIA",
          location:
            "Carrera 14 #93-68, Co Work Piso 3, Edificio Cortezza Esquina Empresarial 93, Bogotá.",
          googleMaps: "https://goo.gl/maps/1b9oJR8wTqc8w2vN6",
        },
        {
          country: "MÉXICO",
          location:
            "Edificio WeWork, Av. P.º de la Reforma 296, Piso 24, Juárez, Cuauhtémoc, 06600 Ciudad de México.",
          googleMaps: "https://goo.gl/maps/bW9oSvJdPCFJL2Fi9",
        },
        {
          country: "ESPAÑA",
          location: "Diagonal 427 4to 1ra 08036, Barcelona.",
          googleMaps: "https://maps.app.goo.gl/Qerxh5v6nri7uWmA6",
        },
      ],
    };
  },
  computed: {
    socialMedia() {
      return [
        {
          social: "twitter",
          link: "https://twitter.com/toteatcom?lang=es",
        },
        {
          social: "linkedin",
          link: "https://www.linkedin.com/company/toteat/",
        },
        {
          social: "instagram",
          link: "https://www.instagram.com/toteat_" + this.selectedCountry,
        },
        {
          social: "facebook",
          link: "https://www.facebook.com/ToteatHQ",
        },
        {
          social: "youtube",
          link: "https://www.youtube.com/c/Toteat",
        },
        {
          social: "whatsapp",
          link: this.whatsappNumber,
        },
      ];
    },
    language() {
      return this.$store.getters.getLanguage;
    },
  },
  availableLanguages() {
    return this.t.idiomas;
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    changeLanguage(lang, country, bool) {
      store.dispatch("updateLucesProp", { country: country });
      this.selectedCountry = this.$store.state.luces.country;
      if (lang != this.currentLanguage) {
        $lstorage.set(BASE_TRADS.storageIdiomaUser, lang);
        console.log("PATH1", window.location.pathname);
        let pathList = document.location.pathname.split("/");
        pathList[1] = lang;
        var newPath = pathList.slice(1, pathList.length + 1).join("/");
        window.location.href = newPath;
      }
      this.modalActive = bool;
      return true;
    },
    goTo(refName) {
      const elem = document.getElementById(refName);
      const yOffset = -150;
      const y = elem.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    },
    goToPricing(path, refName) {
      if (!window.location.pathname.includes(path)) {
        var th = this;
        this.$router.push({ name: "solutions" }).then(() => {
          setTimeout(function () {
            th.goTo(refName);
          }, 200);
        });
      } else {
        this.goTo(refName);
      }
    },
    changeCountry(value, bool) {
      store.dispatch("updateLucesProp", { country: value });
      this.selectedCountry = this.$store.state.luces.country;
      this.modalActive = bool;
    },
  },
  created() {
    this.selectedCountry = this.$store.state.luces.language
      .split("-")[1]
      .toLowerCase();
    this.toteatCountries = COUNTRIES_NAMES;
    this.toteatLanguages = COUNTRIES_LANG;
  },
  mounted() {
    const modal = this.$refs.modal;
    if (modal) {
      modal.$on("hidden", () => {
        this.showModal = false;
      });
    }
  },
};
</script>
