<template>
  <div class="main-container" id="solutions-header">
    <div class="main-text">
      <div class="title">
        <h1>
          {{ t.m11000_77 || "Toteat se adapta a todo" }}
          <span class="underline">{{
            t.m11000_373 || "tipo de negocios gastronómicos"
          }}</span>
        </h1>
      </div>
      <div class="description">
        <p class="header-paragraph">
          {{
            t.m11000_78 ||
            "Sin importar el servicio que ofrezcas, todas las herramientas que necesitas en un solo lugar; Punto de Venta, Kitchen Display System (KDS), Integraciones de Delivery, Check In, Autoatención y más."
          }}
        </p>
      </div>
    </div>
    <div class="header-buttons">
      <ul class="buttons-contact">
        <li>
          <b-button class="button-red" @click="whatsapp()">{{
            t.m11000_5 || "Súmate"
          }}</b-button>
        </li>
        <li>
          <b-button
            class="button-white-red"
            @click="redirect('contactSales')"
            >{{ t.m11000_6 || "Solicita una DEMO" }}</b-button
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import mixinGlobals from "@/components/_mixins/globalMixins";

export default {
  name: "HeaderSolutions",
  mixins: [mixinGlobals],
  methods: {
    redirect(value) {
      if (this.$store.state.luces.language) {
        this.$router.push({
          name: value,
          params: {
            lang: this.$store.state.luces.language,
          },
        });
      }
    },
    whatsapp() {
      window.open(this.whatsappNumber, "_blank");
    },
  },
};
</script>
