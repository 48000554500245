<template>
  <div class="successStories">
    <Header />
    <Users />
  </div>
</template>

<script>
import Header from "@/components/ResourcesToteat/SuccesStories/HeaderSuccessStories.vue";
import Users from "@/components/ResourcesToteat/SuccesStories/UsersSuccessStories.vue";
import mixinGlobals from "@/components/_mixins/globalMixins";

export default {
  name: "SuccessStoriesView",
  mixins: [mixinGlobals],
  metaInfo() {
    return {
      title: this.t.m11000_76 || "Casos de éxito",
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            this.t.m11000_382 ||
            "Descubre cómo nuestra plataforma mejora la experiencia de nuestras marcas.",
        },
      ],
      // link: [
      //   {
      //     rel: "canonical",
      //     href: window.location.href,
      //   },
      // ],
    };
  },
  components: {
    Header,
    Users,
  },
  mounted() {
    var language = document.location.pathname.split("/")[1];
    var routeName = this.$route.name;
    this.updateLang(language, routeName);
  },
};
</script>
