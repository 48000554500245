import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import SolutionsView from "../views/SolutionsView.vue";
import PricesView from "../views/PricesView.vue";
import SuccessStoriesView from "../views/SuccessStoriesView.vue";
import WorkWithUsView from "../views/WorkWithUsView.vue";
import NotFound from "../views/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/:lang/",
    name: "homeLang",
    component: HomeView,
  },
  {
    path: "/:lang/productos",
    name: "solutions",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    props: true,
    component: SolutionsView,
    children: [
      {
        path: "/:lang/productos/:refName",
        name: "solutionsSection",
        props: true,
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/SolutionsView.vue"),
      },
    ],
  },
  {
    path: "/:lang/tipos-de-negocios/restaurante",
    name: "restaurant",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RestaurantView.vue"),
  },
  {
    path: "/:lang/tipos-de-negocios/cafeteria",
    name: "coffee",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CoffeeView.vue"),
  },
  {
    path: "/:lang/tipos-de-negocios/fast-food",
    name: "fastFood",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FastFoodView.vue"),
  },
  {
    path: "/:lang/tipos-de-negocios/dark-kitchen",
    name: "darkKitchen",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DarkKitchenView.vue"),
  },
  {
    path: "/:lang/tipos-de-negocios/bar-y-cerveceria",
    name: "bar",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/BarView.vue"),
  },
  {
    path: "/:lang/tipos-de-negocios/minimarket-y-almacen",
    name: "minimarket",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MinimarketView.vue"),
  },
  {
    path: "/:lang/por-que-toteat",
    name: "whyToteat",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/WhyToteatView.vue"),
  },
  {
    path: "/:lang/recursos/precios",
    name: "prices",
    component: PricesView,
  },
  {
    path: "/:lang/recursos/casos-de-exito",
    name: "successStories",
    component: SuccessStoriesView,
  },
  {
    path: "/:lang/recursos/blog",
    name: "blog",
    beforeEnter() {
      window.location.href = "https://foodnology.toteat.com/";
    },
  },
  {
    path: "/:lang/recursos/trabaja-en-toteat",
    name: "workWithToteat",
    component: WorkWithUsView,
  },
  {
    path: "/:lang/cliente-toteat",
    name: "toteatUser",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ToteatUserView.vue"),
  },
  {
    path: "/:lang/cliente-toteat/iniciar-sesion",
    name: "toteatLogIn",
    beforeEnter() {
      window.location.href = "https://res3.toteat.com/#/";
    },
  },
  {
    path: "/:lang/cliente-toteat/ayuda-toteat",
    name: "toteatHelp",
    beforeEnter() {
      window.location.href = "http://ayuda.toteat.com/es/";
    },
  },
  {
    path: "/:lang/cliente-toteat/certificate",
    name: "getCertified",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/GetCertifiedView.vue"),
  },
  {
    path: "/sitemap.xml",
    name: "sitemap",
  },
  {
    path: "/robots.txt",
    name: "robots",
  },
  {
    path: "/:lang/cliente-toteat/seguimiento-solicitud",
    name: "followTicket",
    beforeEnter() {
      window.location.href = "https://toteat5634.freshdesk.com/support/login";
    },
  },
  {
    path: "/:lang/contacto-ventas",
    name: "contactSales",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContactSalesView.vue"),
  },
  {
    path: "/:lang/gracias",
    name: "thankYouPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ThankYou.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "notFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
