<template>
  <div class="section-container" id="clients-counter">
    <div class="container-banner">
      <div class="banner-clients">
        <div class="number-banner">
          <number
            ref="number1"
            :from="0"
            :to="clients"
            :format="theFormat"
            :duration="durationTransition"
            :delay="2"
            easing="Power1.easeOut"
          />
        </div>
        <div class="text-banner">
          <p>{{ t.m11000_79 || "Clientes" }}</p>
        </div>
      </div>
      <div>
        <div class="number-banner">
          <number
            ref="number2"
            :from="0"
            :to="functionalities"
            :format="theFormat"
            :duration="durationTransition"
            :delay="2"
            easing="Power1.easeOut"
          />
        </div>
        <div class="text-banner">
          <p>{{ t.m11000_80 || "Funcionalidades" }}</p>
        </div>
      </div>
      <div>
        <div class="number-banner">
          <number
            ref="number3"
            :from="0"
            :to="partners"
            :format="theFormatTwo"
            :duration="durationTransition"
            :delay="2"
            easing="Power1.easeOut"
          />
        </div>
        <div class="text-banner">
          <p>Partners</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientsCounter",
  data() {
    return {
      clients: 3000,
      functionalities: 100,
      partners: 4,
      durationTransition: 5,
    };
  },
  props: {
    msg: String,
  },
  methods: {
    formatString(number) {
      let finalNumber = "";
      while (number / 1000 > 1) {
        finalNumber = "." + number.slice(-3) + finalNumber;
        number = number.slice(0, number.length - 3);
      }
      if (finalNumber === "") {
        return number;
      } else {
        return number + finalNumber;
      }
    },
    theFormat(number) {
      number = number.toFixed(0);
      return "+" + this.formatString(number);
    },
    theFormatTwo(number) {
      return this.theFormat(number);
    },
  },
};
</script>
