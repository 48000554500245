<template>
  <div id="app">
    <Navbar
      v-if="
        $route.name != 'sitemap' &&
        $route.name != 'robots' &&
        $route.name != 'thankYouPage'
      "
    />
    <router-view />
    <Form
      v-if="
        $route.name != 'contactSales' &&
        $route.name != 'getCertified' &&
        $route.name != 'notFound' &&
        $route.name != 'sitemap' &&
        $route.name != 'robots' &&
        $route.name != 'thankYouPage'
      "
    />
    <Footer
      v-if="
        $route.name != 'sitemap' &&
        $route.name != 'robots' &&
        $route.name != 'thankYouPage'
      "
    />
  </div>
</template>

<script>
import store from "./store/index";
import Navbar from "@/components/NavbarComponent.vue";
import Form from "@/components/FormHome.vue";
import Footer from "@/components/FooterComponent.vue";
import { getUserCountry } from "@/js/utils.js";

export default {
  name: "App",
  metaInfo: {
    titleTemplate: "%s | Toteat",
  },
  components: {
    Navbar,
    Form,
    Footer,
  },
  created() {
    if (
      !Object.prototype.hasOwnProperty.call(localStorage, "GLOBALuserCountry")
    ) {
      store.dispatch("updateLucesProp", { country: getUserCountry(false) });
    }
  },
};
</script>

<style lang="scss">
@import "./styles/global.scss";
</style>
