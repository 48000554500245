<template>
  <div class="section-container" id="services">
    <div class="info-container-right-white" id="stock-inventary">
      <div class="base-container">
        <div class="container-text" id="stock">
          <h2 id="inventary-stock">
            {{ t.m11000_70 || "Recetas e Inventario" }}
          </h2>
          <p id="container-description">
            {{
              t.m11000_105 ||
              "Que nunca te falten ingredientes para tu receta secreta. Optimiza tu inventario con Toteat, para que puedas controlar la entrada y salida de materias primas en todo momento."
            }}
          </p>
          <ul>
            <li
              v-for="(stockItem, stockIndex) in stockList"
              v-bind:key="stockIndex"
            >
              <img
                src="../../../public/svgs/icons-svg/check.svg"
                id="check-icon"
                :alt="t.m11000_188 || 'stock e inventario'"
              />
              <p id="list-text">{{ stockItem.item }}</p>
            </li>
          </ul>
        </div>
        <div class="images-container">
          <img
            id="stock-img"
            src="../../../public/images/illustrations/stock-inventario-toteat.png"
            :alt="t.m11000_188 || 'stock e inventario'"
          />
        </div>
      </div>
    </div>
    <div
      class="info-container-left-white"
      ref="analytics"
      id="toteat-analytics"
    >
      <div class="base-container">
        <div class="images-container">
          <img
            id="analytics-img"
            class="desktop"
            src="../../../public/images/toteat-services/analitycs-1-toteat.jpg"
            :alt="t.m11000_110 || 'toteat analytics'"
          />
          <img
            id="analytics-img"
            class="mobile"
            src="../../../public/images/toteat-services/analitycs-1-mobile-toteat.png"
            :alt="t.m11000_110 || 'toteat analytics'"
          />
        </div>
        <div class="container-text" id="analytics">
          <h2 id="analytics">{{ t.m11000_110 || "Toteat Analytics" }}</h2>
          <p id="container-description">
            {{
              t.m11000_111 ||
              "Aprovecha al máximo los reportes en tiempo real desde donde quieras que estés. Consolida la información en una plataforma simple y fácil de usar."
            }}
          </p>
          <ul>
            <li
              v-for="(dataItem, dataIndex) in analyticsList"
              v-bind:key="dataIndex"
            >
              <img
                src="../../../public/svgs/icons-svg/check.svg"
                id="check-icon"
                :alt="t.m11000_110 || 'toteat analytics'"
              />
              <p id="list-text">{{ dataItem.item }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIntegrationIndex: 0,
      width: window.innerWidth,
      stockList: [
        {
          item: this.t.m11000_106 || "Gestiona el costo de tus platos",
        },
        {
          item: this.t.m11000_107 || "Monitorea el costo de tus insumos",
        },
        {
          item:
            this.t.m11000_108 ||
            "Maneja diferentes bodegas y envía mercancía entre ellas",
        },
        {
          item: this.t.m11000_109 || "Transforma insumos en subrecetas",
        },
      ],
      analyticsList: [
        {
          item:
            this.t.m11000_112 ||
            "Aprende de tu rendimiento y del consumo de tus clientes.",
        },
        {
          item:
            this.t.m11000_113 ||
            "Cuenta con data real para tomar decisiones estratégicas e inteligentes.",
        },
        {
          item:
            this.t.m11000_114 ||
            "Analiza qué es lo que más consumen tus comensales y elige el mejor momento del día para ofrecer tus preparaciones.",
        },
      ],
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
    },
  },
};
</script>
