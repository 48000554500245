<template>
  <div class="main-container" id="header">
    <div class="main-text">
      <div class="title">
        <h1 class="hidden hidden-right" v-focus="'showElement'">
          {{ t.m11000_1 || "Digitaliza e impulsa tu negocio gastronómico" }}
        </h1>
      </div>
      <div class="sub-title desktop hidden" v-focus="'showElement'">
        <p class="header-paragraph">
          {{ t.m11000_2 || "Restaurantes - Cafeterías - Fast food" }} -
          {{ t.m11000_3 || "Bares - Dark Kitchens" }}
        </p>
      </div>
      <div class="sub-title mobile hidden" v-focus="'showElementDelay'">
        <p class="header-paragraph">
          {{ t.m11000_2 || "Restaurantes - Cafeterías - Fast food" }}
        </p>
      </div>
      <div class="sub-title mobile hidden" v-focus="'showElement'">
        <p class="header-paragraph">
          {{ t.m11000_3 || "Bares - Dark Kitchens" }}
        </p>
      </div>
      <div class="description hidden-left" v-focus="'showElement'">
        <p class="header-paragraph">
          {{
            t.m11000_4 ||
            "Somos un software intuitivo, que te permite manejar todas las operaciones en tiempo real y desde un solo lugar."
          }}
        </p>
      </div>
    </div>
    <div class="header-buttons">
      <ul class="buttons-contact">
        <li>
          <b-button class="button-red" @click="whatsapp()">{{
            t.m11000_5 || "Súmate"
          }}</b-button>
        </li>
        <li>
          <router-link
            :to="{ name: 'contactSales', params: { lang: luces.language } }"
          >
            <b-button class="button-white-red">{{
              t.m11000_6 || "Solicita una DEMO"
            }}</b-button>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import mixinGlobals from "@/components/_mixins/globalMixins";

export default {
  name: "HeaderComponent",
  mixins: [mixinGlobals],
  props: {
    msg: String,
  },
  methods: {
    whatsapp() {
      window.open(this.whatsappNumber, "_blank");
    },
  },
};
</script>
