/* eslint-disable no-prototype-builtins,no-redeclare,no-extra-semi,no-useless-escape */
import CryptoJS from "crypto-js";

const ETAG_VACIO_ARRAY = "d751713988987e9331980363e24189ce";
const ETAG_VACIO_OBJETO = "99914b932bd37a50b983c5e7c90ae93b";
const ETAG_VACIO_NULL = "37a6259cc0c1dae299a7866489dff0bd"; // Si se hace hash de None o null
const GLOBALtags = { vacio: ["", ETAG_VACIO_ARRAY] };

String.prototype.lpad = function (padString, length, truncado) {
  if (typeof truncado == "undefined") truncado = false;
  var str = this + "";
  while (str.length < length) str = padString + str;
  if (truncado) str = str.substr(0, length);
  return str;
};
Date.prototype.aFechaLocal = function () {
  let year = this.getFullYear() + "";
  let month = this.getMonth() + 1 + "";
  let day = this.getDate() + "";
  return year + "-" + month.lpad("0", 2) + "-" + day.lpad("0", 2);
};

String.prototype.rpad = function (padString, length, truncado) {
  if (typeof truncado == "undefined") truncado = false;
  var str = this + "";
  while (str.length < length) str = str + padString;
  if (truncado) str = str.substr(0, length);
  return str;
};

String.prototype.toProperCase = function () {
  return this.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

try {
  var xx = " a ";
  // eslint-disable-next-line no-unused-vars
  xx = xx.trim();
} catch (err) {
  String.prototype.trim = function () {
    return this.replace(/^\s+|\s+$/g, "");
  };
}

String.prototype.centrado = function (padString, length, truncado) {
  if (typeof truncado == "undefined") truncado = false;
  var str = this + "";
  while (str.length < length) {
    if ((length - str.length) % 2) str = str + padString;
    else str = padString + str;
  }

  if (truncado) str = str.substr(0, length);
  return str;
};

function esTagVacio(etag) {
  if (GLOBALtags.vacio.indexOf(etag) >= 0) return true;
  return 0;
}

function esArray(objeto) {
  if (Object.prototype.toString.call(objeto) === "[object Array]") return true;
  else return false;
}

function esObject(objeto) {
  if (Object.prototype.toString.call(objeto) === "[object Object]") return true;
  else return false;
}

function esString(objeto) {
  if (Object.prototype.toString.call(objeto) === "[object String]") return true;
  else return false;
}

function esInteger(a) {
  return typeof a !== "number" || a % 1 !== 0 ? false : true;
}

function esFuncion(objeto) {
  if (Object.prototype.toString.call(objeto) === "[object Function]")
    return true;
  else return false;
}

function isEmptyObject(obj) {
  if (!obj) return true;
  return Object.keys(obj).length === 0;
}

function copiaObjeto(obj) {
  if (typeof obj == "undefined") obj = null;
  return JSON.parse(JSON.stringify(obj));
}

function getCodeRestoLocal(strIRL) {
  var resp = [0, 0, 0];
  let irl = strIRL.split("-");
  resp[0] = parseInt(irl[0]);
  resp[1] = parseInt(irl[1]);
  if (irl[2]) {
    try {
      var temp = parseInt(irl[2]);
      if (!isNaN(temp)) resp[2] = temp;
    } catch (err) {
      //
    }
  }
  return resp;
}

function claveRestoLocal(
  ir,
  il,
  darkKitchen,
  forzarDarkKitchen = false,
  delimiter = "-"
) {
  var temp = "" + parseInt(il);
  temp = ir + delimiter + temp.lpad("0", 5);
  if (darkKitchen || (typeof darkKitchen != "undefined" && forzarDarkKitchen))
    temp = temp + delimiter + parseInt(darkKitchen); // Solo para indices mayores que 0
  return temp;
}

function urlName(txt) {
  // Usado para URL en Toteat App
  txt = normalizar(txt, [1]); // Remueve acentos
  txt = txt.replace(/ /g, "-");
  return encodeURIComponent(txt);
}

function nombreAPP() {
  var app = "TOTEAT";
  if (document.location.host.indexOf("gurmi.") >= 0) {
    app = "GURMI";
  } else {
    if (
      [
        "kds.toteat.com",
        "kds1.toteat.com",
        "kds2.toteat.com",
        "kdsdev.toteat.com",
        "kdsdev1.toteat.com",
        "kdsdev2.toteat.com",
      ].indexOf(document.location.host) >= 0
    )
      app = "KDS";
    else {
      if (
        document.location.host.indexOf("utils.toteat.com") >= 0 ||
        document.location.host.indexOf("utilsdev.toteat.com") >= 0
      )
        app = "UTILS";
    }
  }

  return app;
}

function setDeviceGeneratedID(borrar) {
  // Esta funcion genera un pseudo ID único para el equipo que graba en local storage
  var texto = "";
  if (!borrar) {
    var userAgent = window.navigator.userAgent;

    texto =
      userAgent.substr(userAgent.length - 25) +
      horaActualUnix() +
      "TOTEAT" +
      userAgent;
    if (getMD5) texto = getMD5(texto);
    else texto = btoa(texto).substr(0, 32);
  }
  window.localStorage.setItem("DGID", texto);

  return texto;
}

function getDeviceGeneratedID(crear) {
  var id = window.localStorage.getItem("DGID");
  if (!id) {
    if (crear) return setDeviceGeneratedID(false);
    if (id !== "") return setDeviceGeneratedID(true);
  }
  return id;
}

getDeviceGeneratedID(true); // Para que se cree la primera vez

function btoaURL(string, noPadding = false) {
  // Convierte string a BASE64 pero cambia / y == por _ y -
  var temp = btoa(string);
  temp = temp.replace(/\//g, "_"); // / por _
  if (noPadding) {
    temp = temp.replace(/=/g, ""); // = por nada, no padding
  } else {
    temp = temp.replace(/=/g, "-"); // = por -
  }

  return temp;
}

function btoaNoPad(string) {
  // Convierte string a BASE64 eliminando paddings
  var temp = btoa(string);
  temp = temp.replace(/=/g, ""); // = por nada, no padding

  return temp;
}

window.btoaURL = btoaURL;
function atobURL(string) {
  // Convierte de BASE64 a string pero cambia / y == por _ y -
  var temp = string.replace(/_/g, "/"); // / por _
  temp = temp.replace(/-/g, "="); // = por -
  temp = atob(temp);
  return temp;
}
window.atobUR = atobURL;

function encodeSpecial(texto) {
  if (!texto && texto !== "") return null;
  let rnd =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);
  rnd = rnd.substr(0, 12);
  texto = JSON.stringify(texto);
  texto = btoa(texto);
  texto = texto.replace(/=/g, ""); // = por -
  texto = texto.split("").reverse().join("");
  texto = rnd + texto;
  return texto;
}

function decodeSpecial(texto) {
  if (!texto && texto !== "") return null;
  if (texto.substr(0, 1) === '"') texto = texto.substr(1);
  texto = texto.substr(12);
  texto = texto.split("").reverse().join("");
  if (texto.substr(0, 1) === '"') texto = texto.substr(1);
  try {
    texto = atob(texto);
    texto = JSON.parse(texto);
  } catch (err) {
    texto = null;
  }
  return texto;
}

const radix62CHARS = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

function toRadix62(number) {
  if (number === 0) {
    return radix62CHARS[0];
  }

  let residual = number;
  let result = "";
  while (residual) {
    const char = residual % 62;
    result = radix62CHARS[char] + result;
    residual = Math.floor(residual / 62);
  }

  return result;
}

function fromRadix62(string) {
  let result = 0;
  for (let i = 0; i < string.length; ++i) {
    result = result * 62 + radix62CHARS.indexOf(string[i]);
  }

  return result;
}

function getMD5(texto) {
  var md5 = CryptoJS.MD5(texto).toString();
  return md5;
}
window.getMD5 = getMD5;

function QueryString(querys) {
  var query_string = { querystringurl: "" };
  var query = querys;
  if (querys.indexOf("?") > -1) {
    var q1 = querys.split("?");
    query = q1[1];
    query_string["querystringurl"] = q1[0];
  }

  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    // If first entry with this name
    if (typeof query_string[pair[0]] === "undefined") {
      query_string[pair[0]] = pair[1];
      // If second entry with this name
    } else if (typeof query_string[pair[0]] === "string") {
      var arr = [query_string[pair[0]], pair[1]];
      query_string[pair[0]] = arr;
      // If third or later entry with this name
    } else {
      query_string[pair[0]].push(pair[1]);
    }
  }
  return query_string;
}

function objToUrlParam(obj) {
  // Convierte un objeto key=value, key2=value2 en parametros URL a fin de no usar $.param de JQuery
  var query = "";
  var name, value, fullSubName, subName, subValue, innerObj, i;

  for (name in obj) {
    if (!obj.hasOwnProperty(name)) continue;

    value = obj[name];

    if (value instanceof Array) {
      for (i = 0; i < value.length; ++i) {
        subValue = value[i];
        fullSubName = name + "[" + i + "]";
        innerObj = {};
        innerObj[fullSubName] = subValue;
        query += objToUrlParam(innerObj) + "&";
      }
    } else if (value instanceof Object) {
      for (subName in value) {
        if (!value.hasOwnProperty(subName)) continue;

        subValue = value[subName];
        fullSubName = name + "[" + subName + "]";
        innerObj = {};
        innerObj[fullSubName] = subValue;
        query += objToUrlParam(innerObj) + "&";
      }
    } else if (value !== undefined && value !== null) {
      query += encodeURIComponent(name) + "=" + encodeURIComponent(value) + "&";
    }
  }

  return query.length ? query.substr(0, query.length - 1) : query;
}

function navigatorLanguage() {
  let available = ["es", "en"];
  let temp = navigator.language;
  if (temp) {
    temp = temp.substr(0, 2);
    if (available.indexOf(temp) >= 0) {
      return temp;
    }
  }
  if (navigator.languages) {
    for (var i = 0; i < navigator.languages.length; i++) {
      temp = navigator.languages[i].substr(0, 2);
      if (available.indexOf(temp) >= 0) {
        return temp;
      }
    }
  }
  return "es"; // Por ahora default
}

function reloadPagina() {
  if (window.GLOBALloc !== "" && typeof window.GLOBALloc != "undefined") {
    // Parche phonegap
    //navigator.app.loadUrl('file:///android_asset/www/index.js');
    console.log("Reload con url");
    window.location.href = window.GLOBALloc + "index.js";
  }
  console.log("reload...");
  window.location.reload();
}

function previeneDefault(idNodo, nombreEvento = "touchmove", forzado = false) {
  let nodo = document.getElementById(idNodo);

  nodo.addEventListener(
    nombreEvento,
    function (event) {
      if (!forzado && nombreEvento === "touchmove") {
        // En este caso revisa el offset y el alto primero
        if (nodo.scrollHeight > nodo.offsetHeight) return true;
      }
      event.preventDefault();
      return false;
    },
    { passive: false }
  );
}

function isOverflown(element) {
  if (esString(element)) element = document.getElementById(element);
  if (!element) {
    console.log("No viene elelemnto de overflow");
    return false;
  }
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

function cancelaEvento(event) {
  if (typeof event != "undefined") {
    //console.log("Cancelando evento funcion...")
    event.stopPropagation();
    event.preventDefault();
    event.cancelBubble = true;
  }
  return false;
}

function sortArray(arreglo, campo, esNumero, descendente, funcionCampo) {
  if (typeof descendente == "undefined") descendente = false;

  if (typeof esNumero == "undefined") esNumero = false;

  //Opcion de que campo sea sub campo
  if (esString(campo)) {
    var campos = campo.split(".");
  } else {
    campos = [campo]; // Caso integer o indices de array
  }

  function valor(x, xcampos) {
    var v = x;

    if (typeof xcampos != "undefined") {
      // Nuevo algorito multi campos, a es array u objeto, campo ="fecha.dia" o "im.0.nombre" campos=["fecha", "dia"], ["im","0","nombre"
      var l = xcampos.length;
      try {
        for (var i = 0; i < l; i++) {
          v = v[xcampos[i]];
        }
      } catch (err) {
        v = null;
      }
    }
    // Como estaba antes
    if (funcionCampo && funcionCampo instanceof Function)
      return funcionCampo(v);
    if (esNumero == false) return v;
    var temp = parseInt(v);
    if (isNaN(temp)) return v;
    else return temp;
  }

  function compara(a, b) {
    var multiplo = 1;
    if (descendente) multiplo = -1;
    if (esObject(a) || esArray(a)) {
      if (valor(a, campos) > valor(b, campos)) return 1 * multiplo;
      if (valor(a, campos) < valor(b, campos)) return -1 * multiplo;
      else return 0;
    } else {
      if (valor(a) > valor(b)) return 1 * multiplo;
      if (valor(a) < valor(b)) return -1 * multiplo;
      else return 0;
    }
  }

  arreglo.sort(compara);
  return true;
}

function iguales(a, b, precision) {
  // Funcion para comparar igualdad de numeros flotantes
  if (typeof precision == "undefined") precision = 0.0001;
  var dife = Math.abs(a - b);
  if (dife <= precision) return true;
  else return false;
}

function redondea(num, decimales, fill, puntodecimal, puntomilesima) {
  if (typeof decimales == "undefined") decimales = 0;
  var temp = +(Math.round(num + "e+" + decimales) + "e-" + decimales);
  if (isNaN(temp)) {
    var pot = Math.pow(10, decimales);
    temp = Math.round(num * pot) / pot;
  }
  if (isNaN(temp) || iguales(0, temp, 0.00000001)) temp = 0;
  if (fill === true) {
    if (decimales > 0) temp = parseFloat(temp).toFixed(decimales);
    else temp = "" + temp;
    if (typeof puntodecimal != "undefined") {
      temp = temp.replace(".", puntodecimal);
      if (typeof puntomilesima != "undefined") {
        var parts = temp.toString().split(puntodecimal);
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, puntomilesima);
        temp = parts.join(puntodecimal);
      }
    }
  }

  return temp;
}
window.redondea = redondea;

function objetoSegunId(arreglo, campoId, valor, devuelvePos, devuelveMultiple) {
  // Si el campoId contiene punto ej "det.ui" busca ui dentro de det (aguanta maximo dos puntos
  // Si el campoId es un arreglo hace los mismo hasta dos niveles ej [1,3,2] = [1][3][2]
  // Si devuelveMultiple devuelve un arreglo con todos los matchs
  // Si campoId="[]" caso especial para buscar en arreglos y devolver multiple agregado 20/Feb/2018

  if (typeof devuelvePos == "undefined") devuelvePos = false;
  if (typeof devuelveMultiple == "undefined") devuelveMultiple = false;
  if (typeof arreglo == "undefined") return null;
  var resultado = null;
  if (devuelveMultiple) resultado = [];
  var l = arreglo.length;
  var i = 0;
  var encontrado = false;
  if (esString(campoId)) {
    if (campoId == "[]") {
      subArreglo = -1;
    } else {
      var tempid = campoId.split(".");
      var subArreglo = tempid.length - 1;
    }
  } else {
    if (esArray(campoId)) {
      tempid = campoId;
      subArreglo = tempid.length - 1;
    } else {
      subArreglo = 0;
    }
  }

  while (i < l && !encontrado) {
    if (subArreglo == -1) {
      // Agregado 20 Feb 2018
      // Caso busqueda en Array
      if (typeof arreglo[i] != "undefined" && arreglo[i] == valor)
        encontrado = true;
    } else {
      if (subArreglo == 0) {
        if (typeof arreglo[i] != "undefined" && arreglo[i] != null)
          if (arreglo[i][campoId] == valor) encontrado = true;
      } else {
        if (subArreglo == 1) {
          if (
            typeof arreglo[i][tempid[0]] != "undefined" &&
            arreglo[i][tempid[0]] != null
          )
            if (arreglo[i][tempid[0]][tempid[1]] == valor) encontrado = true;
        } else {
          if (subArreglo == 2) {
            if (arreglo[i][tempid[0]] && arreglo[i][tempid[0]][tempid[1]])
              if (arreglo[i][tempid[0]][tempid[1]][tempid[2]] == valor)
                encontrado = true;
          } // Se podria continuar con más niveles...
        }
      }
    }

    if (encontrado) {
      if (devuelveMultiple) {
        if (devuelvePos) {
          resultado.push(i);
        } else {
          resultado.push(arreglo[i]);
        }
        encontrado = false;
      } else {
        if (devuelvePos) resultado = i;
        else resultado = arreglo[i];
      }
    }

    i = i + 1;
  }

  return resultado;
}

function normalizar(txt, tipo) {
  /*
 tipo=1 = solo remover acentos y eñes (siempre se hace)
 tipo=2 = solo permitir letrasy numeros (elimina espacios)
 tipo=3 = pasar a minusculas
 tipo=4 = pasar a mayusculas
 tipo=5 = dejar solo numeros
 tipo=6 = dejar solo letras
 tipo=7 = dejar ProperCase
 tipo 8 = Solo Letras, numeros, . - _
 tipo 9 = Solo Letras, numeros, espacios, . - _
 tipo 10 y 13= Solo deja Letras, numeros, espacios, . - _ :$, reemplaza acentos y eñes y ademas otros caracteres por _ (usado imp fiscal)
 tipo 11 = Hace TRIM de espacios
 tipo 12 = Numeros, letras, punto, coma (elimina el resto) // Uusado en fechas blouqeuadas reservas
 tipo 13 = Remueve caracteres no compatibles con fiscal
 tipo 14 = Remueve Backslash \
 tipo 15 = Solo ASCII (reempazando acentos y eñes primero, el resto espacios)
 tipo=16 Solo remueve tildes y acentos a vocales
 */
  if (!txt) {
    return "";
  }
  if (!esArray(tipo)) tipo = [tipo];
  if (typeof tipo == "undefined") tipo = [1];

  if (
    tipo.indexOf(1) != -1 ||
    tipo.indexOf(15) != -1 ||
    tipo.indexOf(16) != -1
  ) {
    var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
      to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
      mapping = {};
    if (tipo.indexOf(16) >= 0) {
      from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüû";
      to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuu";
    }

    for (var i = 0, j = from.length; i < j; i++)
      mapping[from.charAt(i)] = to.charAt(i);

    var ret = [];
    for (var i = 0, j = txt.length; i < j; i++) {
      var c = txt.charAt(i);
      if (mapping.hasOwnProperty(txt.charAt(i))) ret.push(mapping[c]);
      else ret.push(c);
    }
    txt = ret.join("");
  }
  if (tipo.indexOf(15) != -1) {
    // Solo ASCII
    // eslint-disable-next-line no-control-regex
    txt = txt.replace(/[^\x00-\x7F]+/g, " ");
  }
  if (tipo.indexOf(5) != -1) {
    txt = txt.replace(/[^0-9]/g, "");
  }
  if (tipo.indexOf(6) != -1) {
    txt = txt.replace(/[^a-zA-Z]/g, "");
  }
  if (tipo.indexOf(8) != -1) {
    txt = txt.replace(/[^0-9a-zA-Z._-]/g, "");
  }
  if (tipo.indexOf(9) != -1) {
    txt = txt.replace(/[^\s0-9a-zA-Z._-]/g, "");
  }
  if (tipo.indexOf(2) != -1) {
    txt = txt.replace(/[^0-9a-zA-Z]/g, "");
  }
  if (tipo.indexOf(3) != -1) {
    txt = txt.toLowerCase();
  }
  if (tipo.indexOf(4) != -1) {
    txt = txt.toUpperCase();
  }
  if (tipo.indexOf(7) != -1) {
    txt = txt.toProperCase();
  }
  if (tipo.indexOf(10) != -1) {
    txt = txt.replace(/[^\s0-9a-zA-Z._-]/g, "-");
  }

  if (tipo.indexOf(11) != -1) txt = txt.trim();

  if (tipo.indexOf(12) != -1) {
    txt = txt.replace(/[^0-9a-zA-Z.,]/g, "");
  }
  if (tipo.indexOf(13) != -1) {
    // ESpecial Epson Fiscal para comentarios
    txt = txt.replace(/[^\s0-9a-zA-Z.$:%+?=/_-]/g, "_");
  }
  if (tipo.indexOf(14) != -1) {
    // remueve \
    txt = txt.replace(/\\/g, "");
  }

  return txt;
}

function fillZeroFloat(valor, numInt, numDec) {
  // Devuelve string con ceros
  if (!valor) valor = 0;
  valor = redondea(valor, numDec, true); // Acá ya llena de ceros decimales
  let temp = valor.split(".");
  temp[0] = temp[0].lpad(" ", numInt);

  return temp.join(".");
}
window.fillZeroFloat = fillZeroFloat;

function reverseString(str) {
  return str.split("").reverse().join("");
}

function buscaCorchetes(frase, objeto) {
  if (typeof objeto == "undefined") objeto = [];
  if (frase == "") return objeto;
  var a = frase.indexOf("[", 0);
  if (a !== 0) {
    if (a > 0) {
      objeto.push([frase.substring(0, a), ""]);
      buscaCorchetes(frase.substr(a), objeto);
    } else objeto.push([frase, ""]);

    return objeto;
  }
  if (a === 0) {
    var b = frase.indexOf("]", 0);
    if (b !== -1) {
      var interiorcorchete = frase.substr(1, b - 1);

      var restofrase, c;
      if (frase.length == b + 1) {
        restofrase = "";
        c = -1;
      } else {
        restofrase = frase.substr(b + 1);
        c = restofrase.indexOf("[", 0);
      }

      if (interiorcorchete.length >= 2 && interiorcorchete.length <= 6) {
        interiorcorchete = interiorcorchete.toLocaleLowerCase();
        if (c === -1) {
          objeto.push([restofrase, interiorcorchete]);
          return objeto;
        }
        objeto.push([restofrase.substring(0, c), interiorcorchete]);
        buscaCorchetes(restofrase.substring(c), objeto);
      } else {
        //idioma invalido, lo ignora.
        if (objeto.length == 0) objeto.push(["", ""]);
        if (c === -1) {
          objeto[objeto.length - 1][0] += frase;
        } else {
          objeto[objeto.length - 1][0] += frase.substring(0, c);
        }
      }
    } else {
      if (objeto.length > 0) objeto[objeto.length - 1][0] += frase;
      else objeto.push([frase, ""]);
    }
  }
  return objeto;
}

function textoIdioma(texto, idioma = "es") {
  // Permite que un texto pueda incluir en si mismo trads a otros idiomas
  // Ejemplo:  Casa blanca[es-es]White house
  var resultado = buscaCorchetes(texto);
  var l = resultado.length;
  if (l == 0) return texto;
  var resp = resultado[0][0];
  if (typeof idioma == "undefined" || idioma.length < 2) return resp;
  idioma = idioma.toLowerCase();
  var resalt = "";
  var idialt = idioma.substr(0, 2);
  var i = 0;
  var encontrado = false;
  while (i < l && !encontrado) {
    var xidi = resultado[i][1].substr(0, 2);
    if (xidi.length == 2 && xidi == idialt && resalt == "")
      resalt = resultado[i][0];
    if (idioma == resultado[i][1]) {
      resp = resultado[i][0];
      encontrado = true;
    }
    i = i + 1;
  }
  if (encontrado == false && resalt != "") resp = resalt;

  return resp;
}

function tsaText(valor, sinSeparador) {
  // Devuelve -180 -> -0300    240 --> +0400
  tsa = 0;
  if (typeof valor != "undefined" && !isNaN(valor)) var tsa = valor;
  else {
    tsa = -new Date().getTimezoneOffset();
  }
  var separador = ":";
  if (sinSeparador) separador = "";

  var tsahora = parseInt(Math.abs(tsa) / 60);
  var tsamin = Math.abs(tsa) - tsahora * 60;
  tsahora = "" + tsahora;
  tsamin = "" + tsamin;
  if (tsa < 0) var tsaTxt = "-";
  else tsaTxt = "+";
  tsaTxt += tsahora.lpad("0", 2) + separador + tsamin.lpad("0", 2);

  return tsaTxt;
}

function dateLocal(fechaStr, devuelveStr = false) {
  if (!fechaStr) {
    var temp = new Date();
    let mes = "" + (temp.getMonth() + 1);
    let dia = "" + temp.getDate();
    fechaStr =
      temp.getFullYear() + "-" + mes.lpad("0", 2) + "-" + dia.lpad("0", 2);
  } else {
    if (!esString(fechaStr)) {
      // Viene como Date
      fechaStr = fechaStr.aFechaLocal();
    }
  }
  var tsa = tsaText();
  temp = fechaStr + "T00:00:00" + tsa;
  let resp = new Date(temp);
  if (!devuelveStr) return resp;
  else {
    return resp.aFechaLocal();
  }
}
window.dateLocal = dateLocal;

function fechaLocaleString(date) {
  var conHora = true;
  if (esString(date)) {
    if (date.length == 10) {
      date = date + "T00:00:00";
      conHora = false;
    } else {
      if (date.length == 19) date = date + "+00:00";
      else {
        if (date.length == 8) {
          date =
            date.substr(0, 4) +
            "-" +
            date.substr(4, 2) +
            "-" +
            date.substr(6, 2) +
            "T00:00:00";
          conHora = false;
        }
      }
    }
    date = new Date(date);
  }
  if (!conHora) return date.toLocaleDateString();
  else return date.toLocaleString();
}

function sumaMinutosHora(horaString, minutos, devuelveArray = false) {
  let horas = parseInt(minutos / 60);
  let dias = 0;
  minutos = minutos - horas * 60;
  let temp = horaString.split(":");
  let horaIni = parseInt(temp[0]) + horas;
  let minIni = parseInt(temp[1]) + minutos;
  while (minIni >= 60) {
    horaIni++;
    minIni = minIni - 60;
  }
  while (minIni < 0) {
    horaIni--;
    minIni = minIni + 60;
  }
  let tempHora = horaIni + "";
  while (horaIni >= 24) {
    dias++;
    horaIni = horaIni - 24;
  }
  while (horaIni < 0) {
    dias--;
    horaIni = horaIni + 24;
  }

  horaIni += "";
  minIni += "";
  temp = horaIni.lpad("0", 2) + ":" + minIni.lpad("0", 2);
  let temp2 = tempHora.lpad("0", 2) + ":" + minIni.lpad("0", 2); // Usada para comparar y ordenar
  if (!devuelveArray) {
    if (dias >= 0) temp += "+" + dias;
    else temp += dias;

    return temp;
  } else {
    return [temp, dias, temp2];
  }
}
window.sumaMinutosHora = sumaMinutosHora;

function horaAampm(horaString) {
  let final = horaString.substr(5);
  let hora = parseInt(horaString.substr(0, 2));
  let min = horaString.substr(3, 2);
  var tit = " am ";
  if (hora > 11) {
    tit = " pm ";
    if (hora > 12) hora = hora - 12;
  } else {
    if (hora === 0) hora = 12;
  }
  hora = ("" + hora).lpad(0, 2);
  return hora + ":" + min + tit + final;
}

function aFecha(fechaISO) {
  var fecha = fechaISO + "+00:00";
  var date = new Date(fecha);
  return date.toLocaleString();
}

function setDateDif(horaServer, tsa) {
  if (!horaServer || horaServer.length < 19) return false;
  if (horaServer.indexOf("+") == -1) horaServer += "+00:00";

  var ahora = new Date();
  var ahoraSec = ahora.getTime();
  var serv = new Date(horaServer).getTime();
  var difetime = parseInt((ahoraSec - serv) / 1000);
  console.log("Hora de Servidor GAE: " + horaServer);
  console.log("Hora de Cliente: " + ahora.toISOString());
  console.log("Hora Diferencia de hora en segundos: " + difetime);
  localStorage.setItem("TIMEDIF", difetime);
  if (tsa || tsa === 0) {
    localStorage.setItem("TIMETSA", tsa);
  }
  return true;
}

function newFechaActual(noajustar, fechaLocal) {
  var fecha = new Date();

  var dife1 = 0; // En segundos
  if (localStorage && localStorage.getItem("TIMEDIF") && !noajustar)
    dife1 = parseInt(localStorage.getItem("TIMEDIF"));
  var dife2 = 0;
  if (localStorage && localStorage.getItem("TIMETSA") !== null) {
    var dife2 = parseInt(localStorage.getItem("TIMETSA"));
    dife2 = dife2 + fecha.getTimezoneOffset();
    //console.log("Hora dife2="+dife2)
  }

  if (!noajustar && dife1) {
    //console.log("Hora Ajustando segundos "+dife1)
    fecha.setSeconds(fecha.getSeconds() - dife1);
  }

  if (fechaLocal && dife2) {
    console.log("Hora Ajustando minutos " + dife2);
    fecha.setMinutes(fecha.getMinutes() + dife2);
  }

  return fecha;
}

function horaActualUnix(fecha) {
  var newfecha = null;
  try {
    if (!fecha) newfecha = new Date();
    else {
      if (esString(fecha)) {
        if (fecha.length == 10) {
          newfecha = new Date(fecha + "T00:00:00");
        } else {
          if (fecha.length >= 19) {
            if (
              fecha.indexOf("T") > 0 &&
              fecha.indexOf("+", 19) === -1 &&
              fecha.indexOf("-", 19) === -1
            )
              newfecha = new Date(fecha + "+00:00");
            else newfecha = new Date(fecha);
          }
        }
      }
    }
  } catch (err) {
    //
  }
  if (!newfecha) newfecha = new Date();

  return Math.round(newfecha.getTime() / 1000);
}
window.horaActualUnix = horaActualUnix;

function fechaActual(tipo, fecha, noajustar, fechaLocal) {
  /*
    INPUT:
    La fecha puede venir como :
    - integer (asume UNIXTIME en segundos GMT 0)
    - Objeto Date()
    - STRING, puede venir:
        2016-01-31T22:30:35    Asume GMT 0
        2016-01-31 22:30:35    Asume hora local del Restaurante, dado por el time zone guardado en locallocalStorage TSA
        2016-01-31T22:30:35-05:00  Asume hora GMT -5

    Devuelve la fecha actual en distintos formatos predefinidos.
        Si tipo == 0 en formato datetime (objeto), fecha viene en string
    Si tipo==1 convierte fecha a string ISO "%Y-%m-%dT%H:%M:%S", fecha viene como datetime
    Si tipo==2 convierte fecha a ISO solo "YYYY-mm-dd", fecha viene como datetime
    Si tipo==3 incluye milisegundos, igual que tipo 1.
    Si tipo==4 devuelvestring YYYYMMDD
    Si tipo==5 devuelve UNIXTIME (segundos)
    OJO asume hora GMT 0
    Por defecto ajusta con diferencia server GAE, si noajustar =true la ajusta, solo caso que no venga fecha
    si fechaLocal=true y fecha es ISO con GMT igual a la de resto y en este formato "2016-01-32 22:30:35"
    */

  var dife = 0;

  try {
    if (localStorage && localStorage.getItem("TIMETSA")) {
      dife = parseInt(localStorage.getItem("TIMETSA"));
    }
  } catch (err) {
    //
  }

  if (typeof noajustar == "undefined") noajustar = false;
  if (typeof tipo == "undefined" || !tipo) tipo = 0;
  if (typeof fecha == "undefined" || !fecha) {
    fecha = newFechaActual(noajustar);
  } else {
    //console.log(fecha)
    if (
      esString(fecha) &&
      [8, 10, 19, 20, 16, 24, 25].indexOf(fecha.length) >= 0
    ) {
      /*
          8   20170821
          10  2017-08-21
          16  2017-08-21 04:32
          19  2017-08-21 04:32:21  o 2017-08-21T04:32:21
          20  2017-08-21T04:32:21Z
          24  2017-08-21T04:32:21+0000 o 2017-08-21 04:32:21-0300
          25  2017-08-21T04:32:21+00:00 o 2017-08-21 04:32:21-03:00
          */

      if (fecha.length == 16) {
        fecha = fecha + ":00"; // Se le agregan segundos para que no falle en Safari
      }
      if (fecha.length == 8) {
        fecha =
          fecha.substr(0, 4) +
          "-" +
          fecha.substr(4, 2) +
          "-" +
          fecha.substr(6, 2);
      }
      if (fecha.length < 19) {
        // Solo fecha, sin hora
        var temp = fecha.split("-");
        fecha = new Date(
          parseInt(temp[0]),
          parseInt(temp[1]) - 1,
          parseInt(temp[2], 0, 0, 0)
        );
        fecha.setFullYear(parseInt(temp[0]));
        fecha.setMonth(parseInt(temp[1]) - 1);
        fecha.setDate(parseInt(temp[2]));
        fecha.setHours(0);
        fecha.setMinutes(0);
      } else {
        temp = fecha.substr(0, 19);
        if (temp.substr(10, 1) == " ") {
          // Se reemplaza espacio
          temp = temp.substr(0, 10) + "T" + temp.substr(11);
          if (fecha.length == 19) {
            // No llega time zone se le agrega
            fecha = temp + tsaText(dife);
          } else {
            fecha = temp + fecha.substr(19);
          }
        } else {
          // Viene "T"
          if (fecha.length == 19)
            fecha = fecha.substr(0, 10) + "T" + fecha.substr(11) + "+00:00";
        }
        //var tempf=new Date(temp)

        if (fecha.length == 24) {
          // Hay que convertirla a largo 25, le falta separador en timezone
          fecha = fecha.substr(0, 22) + ":" + fecha.substr(22);
        }
      }
    } else {
      if (esInteger(fecha)) {
        //Fecha viene en UNIX TIME
        fecha = new Date(fecha * 1000);
      } else {
        fecha = new Date(fecha);
      }
    }
  }
  try {
    if (esString(fecha)) {
      fecha = new Date(fecha);
    }
  } catch (err) {
    console.log("Error en conversion de fecha.");
  }
  if (fechaLocal && tipo != 5 && tipo != 0) {
    fecha.setMinutes(fecha.getMinutes() + dife);
  }

  if (tipo == 0) return fecha;
  try {
    if (tipo == 5) {
      return Math.round(fecha.getTime() / 1000);
    }

    fecha = fecha.toISOString(); // Ej 2012-10-10T22:10:05.232Z

    if (tipo == 1) {
      if (fechaLocal && fecha.substr(10, 1) == "T") {
        return fecha.substr(0, 19).split("T").join(" ");
      } else {
        return fecha.substr(0, 19);
      }
    }

    if (tipo == 2) return fecha.substr(0, 10);
    if (tipo == 4)
      return fecha.substr(0, 4) + fecha.substr(5, 2) + fecha.substr(8, 2);
    // Tipo==3
    if (fechaLocal && fecha.substr(10, 1) == "T") {
      return fecha.substr(0, 23).split("T").join(" ");
    } else {
      return fecha.substr(0, 23); // sin la Z otros casos
    }
  } catch (err) {
    console.log("Error en Fecha...");
    console.log(fecha);
  }

  if (fechaLocal) {
    // return fechaUTCaLocalISO(tipo, fecha)
  }
}
window.fechaActual = fechaActual;

function esEmailValido(email) {
  var expr = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (!expr.test(email)) return false;
  else return true;
}

// Funciones usadas para simplificar respuesta de promesas y en errores para no estar detectando si se definieron o no las funciones resolve y reject
function rejectPromesa(valor, resolve, reject) {
  if (esFuncion(reject)) {
    reject(valor);
    return true;
  } else if (esFuncion(resolve)) {
    resolve(valor);
    return true;
  }

  return false;
}
function resolvePromesa(valor, resolve) {
  if (esFuncion(resolve)) {
    resolve(valor);
    return true;
  }
  return false;
}

const dominiosExcepcionesDev = ["app.toteat.net", "gurmi.toteat.net"];
const dominiosExcepcionesProd = [];

function getBASE_URL_SERVICES() {
  let temp = location.hostname;
  if (dominiosExcepcionesDev.indexOf(temp) >= 0) temp = "dev.toteat.app";
  if (dominiosExcepcionesProd.indexOf(temp) >= 0 || temp.indexOf("file") >= 0)
    temp = "toteat.app";
  if (localStorage.getItem("AMB") === "PROD") {
    if (temp === "dev.toteat.app") temp = "devprod.toteat.app";
  }
  if (location.hostname.indexOf("gurmi") >= 0) {
    temp = temp.replace("toteat", "gurmi");
  }
  return "https://" + temp;
}

function getBASE_URL_SERVICES11000() {
  let temp = location.hostname;
  let dominio = "toteatglobal.appspot.com";
  let amb = ambienteInicial();
  console.log("amb", amb);
  if ((temp.indexOf("dev") >= 0 && amb !== "PROD") || amb === "DEV")
    dominio = "toteatdev.appspot.com";

  return "https://" + dominio;
}

function ambienteInicial() {
  var amb = localStorage.getItem("AMB");

  if (
    window.document.location.pathname.startsWith("/r/") ||
    [
      "resdevprod.toteat.com",
      "toteat.app",
      "www.toteat.app",
      "devprod.toteat.app",
      "gurmi.app",
      "www.gurmi.app",
      "devprod.gurmi.app",
      "utils.toteat.com",
      "kds.toteat.com",
    ].indexOf(window.document.location.hostname) >= 0
  ) {
    // En produccion siempre el ambiente es produccion
    amb = "PROD";
  } else {
    if (window.document.location.pathname.startsWith("/rd/")) {
      amb = "DEV";
    }
  }
  if (!amb) {
    if (
      window.document.location.hostname === "app.toteat.net" ||
      window.document.location.hostname === "dev.toteat.app" ||
      window.document.location.hostname === "gurmi.toteat.net" ||
      window.document.location.hostname === "dev.gurmi.app" ||
      window.document.location.hostname === "kdsdev.toteat.com" ||
      window.document.location.hostname === "kdsdev1.toteat.com" ||
      window.document.location.hostname === "kdsdev2.toteat.com" ||
      window.document.location.hostname === "utilsdev.toteat.com" ||
      window.document.location.hostname.indexOf("localhost") >= 0 ||
      window.document.location.hostname.indexOf(".toteat.net") >= 0
    )
      amb = "DEV";
    else amb = "PROD";
  }
  return amb;
}

function convierteHostSSLToteat(host, ipa, numPort, defaultPort) {
  // Convierte direccion ej 192.168.1.104 a 192-168-1-104.toteat.net
  // ipa es lo que se conugura en TR Ajustes generales ejemplo: "*:8080:8080:8080:8084"
  var port = "";
  if (defaultPort) port = ":" + defaultPort;
  var hostDefault = "";

  if (ipa && numPort && esString(ipa) && (!host || host.indexOf(":") === -1)) {
    temp = ipa.split(":");
    if (temp.length > 0) {
      hostDefault = temp[0];
      if (temp.length > numPort) port = ":" + temp[numPort];
    }
  }
  if (!host || hostDefault.toLowerCase().indexOf(".toteat.net") > 0)
    return hostDefault + port;
  if (hostDefault.toLowerCase() == "localhost") {
    return "localhost.toteat.net" + port;
  }

  var temp = host.split(".");
  try {
    if (temp.length === 4 && temp[0] == parseInt(temp[0])) {
      var newhost = temp.join("-") + ".toteat.net" + port;
      return newhost;
    }
  } catch (err) {
    //
  }

  return host + port;
}

export {
  ETAG_VACIO_ARRAY,
  ETAG_VACIO_NULL,
  ETAG_VACIO_OBJETO,
  esTagVacio,
  esArray,
  esObject,
  esString,
  esInteger,
  esFuncion,
  isEmptyObject,
  copiaObjeto,
  getCodeRestoLocal,
  claveRestoLocal,
  urlName,
  nombreAPP,
  getDeviceGeneratedID,
  setDeviceGeneratedID,
  btoaURL,
  btoaNoPad,
  atobURL,
  encodeSpecial,
  decodeSpecial,
  toRadix62,
  fromRadix62,
  getMD5,
  QueryString,
  objToUrlParam,
  navigatorLanguage,
  reloadPagina,
  previeneDefault,
  isOverflown,
  cancelaEvento,
  sortArray,
  iguales,
  redondea,
  objetoSegunId,
  normalizar,
  fillZeroFloat,
  reverseString,
  textoIdioma,
  tsaText,
  dateLocal,
  fechaLocaleString,
  sumaMinutosHora,
  horaAampm,
  aFecha,
  setDateDif,
  horaActualUnix,
  fechaActual,
  esEmailValido,
  resolvePromesa,
  rejectPromesa,
  ambienteInicial,
  getBASE_URL_SERVICES,
  getBASE_URL_SERVICES11000,
  convierteHostSSLToteat,
};
