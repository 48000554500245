<template>
  <div class="section-container" id="final-cards">
    <div class="horizontal-container">
      <div class="section-text">
        <h3 class="hidden hidden-right" v-focus="'showElement'">
          {{
            t.m11000_54 ||
            "Somos más que un punto de venta, venimos a humanizar la digitalización."
          }}
        </h3>
      </div>
    </div>
    <div class="horizontal-container">
      <div
        v-for="(item, index) in cardList"
        v-bind:key="index"
        class="info-card hidden hidden-bottom"
        v-focus="'showElement'"
        id="white-card"
      >
        <div class="card-text">
          <div>
            <img class="icon" :src="item.icon" :alt="item.alt" />
            <p class="explanation">{{ item.description }}</p>
          </div>
          <a @click="redirect(index)">{{ item.link }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import featuresIcon from "../../../public/svgs/icons-svg/icono-soluciones-toteat.svg";
import growBlackIcon from "../../../public/svgs/icons-svg/icono-blog-negro-toteat.svg";
import workerIcon from "../../../public/svgs/icons-svg/icono-team-toteat.svg";
import mixinGlobals from "@/components/_mixins/globalMixins";

export default {
  name: "DigitizeComponent",
  mixins: [mixinGlobals],
  data() {
    return {
      cardList: [
        {
          icon: featuresIcon,
          alt: this.t.m11000_55 || "funcionalidades toteat",
          description:
            this.t.m11000_57 || "Explora nuestras +40 funcionalidades.",
          link: `${this.t.m11000_59 || "Ir a soluciones"} ->`,
        },
        {
          icon: growBlackIcon,
          alt: this.m11000_12 || "blog toteat",
          description: `${
            this.t.m11000_15 ||
            "Mira qué está pasando en el mundo de la gastronomía"
          }.`,
          link: `${this.t.m11000_17 || "Ir a foodnology"} ->`,
        },
        {
          icon: workerIcon,
          alt: this.t.m11000_56 || "trabaja con toteat",
          description: `${
            this.t.m11000_58 || "¿Te gustaría formar parte de nuestro equipo?"
          } #ToteatTeam`,
          link: `${this.t.m11000_60 || "Trabaja con nosotros"} ->`,
        },
      ],
    };
  },
  methods: {
    redirect(value) {
      if (value === 0) {
        this.solutions();
      } else if (value === 1) {
        this.blog();
      } else {
        this.workWithUs();
      }
    },
    solutions() {
      this.$router.push({
        name: "solutions",
        params: { lang: this.luces.language },
      });
    },
    blog() {
      this.$router.push({
        name: "blog",
        params: { lang: this.luces.language },
      });
    },
    workWithUs() {
      this.$router.push({
        name: "workWithToteat",
        params: { lang: this.luces.language },
      });
    },
  },
};
</script>
