var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(
      _vm.$route.name != 'sitemap' &&
      _vm.$route.name != 'robots' &&
      _vm.$route.name != 'thankYouPage'
    )?_c('Navbar'):_vm._e(),_c('router-view'),(
      _vm.$route.name != 'contactSales' &&
      _vm.$route.name != 'getCertified' &&
      _vm.$route.name != 'notFound' &&
      _vm.$route.name != 'sitemap' &&
      _vm.$route.name != 'robots' &&
      _vm.$route.name != 'thankYouPage'
    )?_c('Form'):_vm._e(),(
      _vm.$route.name != 'sitemap' &&
      _vm.$route.name != 'robots' &&
      _vm.$route.name != 'thankYouPage'
    )?_c('Footer'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }