<template>
  <div class="section-container" id="users-stories">
    <div class="vertical-container">
      <div v-for="(i, index) in 3" :key="index" class="horizontal-container">
        <div
          v-for="(item, itemIndex) in reviewsList.slice(
            index * 2,
            2 * index + 2
          )"
          v-bind:key="itemIndex"
          class="vertical-review-container"
        >
          <iframe
            id="ytplayer"
            type="text/html"
            height="315"
            :src="item.youtubeUrl"
            frameborder="0"
            allowfullscreen
          />
          <div class="section-text desktop">
            <p class="user-title">
              {{ item.name }}
            </p>
            <p class="user-testimony">
              {{ item.review }}
            </p>
          </div>
          <div class="section-text mobile">
            <div class="open-container">
              <p class="user-title">
                {{ item.name }}
              </p>
              <div
                :class="{ 'clicked-on': item.isShowing }"
                class="clickable-icon"
                id="hover-black"
                @click="toggleShow(2 * index + itemIndex)"
              ></div>
            </div>
            <p v-if="item.isShowing" class="user-testimony">
              {{ item.review }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      reviewsList: [
        {
          isShowing: false,
          name: "Mokka",
          review:
            this.t.m11000_384 ||
            "Pablo Said, dueño de Mokka, lo tiene claro. El utiliza Toteat Analytics para tomar decisiones estratégicas para su cadena de cafeterías. Junto a nosotros operan en sus tiendas físicas y en sus centros de producción vendiendo los mejores sándwiches ave mayo.",
          youtubeUrl:
            "https://www.youtube.com/embed/WPNVY-QUc6Q?controls=0&modestbranding=1&color=white",
        },
        {
          isShowing: false,
          name: "Bar La Virgen y La Cervecería",
          review:
            this.t.m11000_385 ||
            "Juan Alberto Schiavoni nos cuenta el éxito de Bar La Virgen y Bar La Cervecería que llevan más de 4 años usando Toteat, software clave para el crecimiento de su negocio. Hoy cuentan con 12 locales y comentan que Toteat ha sido la herramienta más útil para llevar el control de todos los bares simultáneamente.",
          youtubeUrl:
            "https://www.youtube.com/embed/CUDOuKIgwI0?controls=0&modestbranding=1&color=white",
        },
        {
          isShowing: false,
          name: "Amor Perfecto",
          review:
            this.t.m11000_386 ||
            "Viajamos a Colombia a visitar a uno de nuestros grandes clientes, Amor Perfecto! Durante más de 20 años, Amor perfecto nos deleita con grandes sabores y aromas. Explorar cada rincón de Colombia, es uno de sus secretos para poder crear nuevas combinaciones y recetas.",
          youtubeUrl:
            "https://www.youtube.com/embed/TCtnjlC3AI4?controls=0&modestbranding=1&color=white",
        },
        {
          isShowing: false,
          name: "Coliseo Live",
          review:
            this.t.m11000_387 ||
            "Coliseo Live es el escenario más importantes de Latinoamérica, con una gran responsabilidad de ofrecer a sus visitantes un gran espectáculo. 🤩 Digitalizaron su atención con Toteat 👉🏻🖥 para ayudarles a ofrecer una experiencia agradable que va más allá de simplemente asistir a un concierto o evento.",
          youtubeUrl:
            "https://www.youtube.com/embed/3bjpHqqXhk4?controls=0&modestbranding=1&color=white",
        },
        // {
        //   name: "GLounge",
        //   review:
        //     "Jaime y María Paula, fundaron el taquillero GLounge en Bogotá, y eligieron Toteat para su venta, atención al cliente, reportería y tanto mas. Hoy tienen el control y pueden tomar diariamente las mejores decisiones. ",
        //   youtubeUrl:
        //     "https://www.youtube.com/embed/sMbwHS647Xw?controls=0&modestbranding=1&color=white",
        // },
        // {
        //   name: "GLounge",
        //   review:
        //     "Jaime y María Paula, fundaron el taquillero GLounge en Bogotá, y eligieron Toteat para su venta, atención al cliente, reportería y tanto mas. Hoy tienen el control y pueden tomar diariamente las mejores decisiones. ",
        //   youtubeUrl:
        //     "https://www.youtube.com/embed/sMbwHS647Xw?controls=0&modestbranding=1&color=white",
        // },
      ],
    };
  },
  methods: {
    toggleShow(i) {
      this.reviewsList[i].isShowing = !this.reviewsList[i].isShowing;
    },
  },
};
</script>
