<template>
  <div class="section-container" id="solutions">
    <div class="section-text">
      <h3 class="hidden" v-focus="'showElement'">
        {{ t.m11000_18 || "Diferentes pantallas" }}, <br />{{
          t.m11000_19 || "mismo software"
        }}.
      </h3>
      <p class="hidden" v-focus="'showElement'">
        {{
          t.m11000_20 ||
          "Desde la gestión de tus pedidos, pasando por la operativa de la cocina y terminando en el check-out."
        }}
      </p>
    </div>
    <div class="solutions-images">
      <div
        v-for="(item, index) in solutionsList"
        v-bind:key="index"
        class="plus-function hidden hidden-right"
        v-focus="'showElement'"
        :id="item.containerId"
      >
        <div
          v-if="item.containerId != 'app'"
          class="plus-title"
          @click="goToPricing(item.pathName, item.refName)"
        >
          <img
            src="../../../public/svgs/icons-svg/icono-mas-blanco-toteat.svg"
            id="icon"
            alt="toteat sales manager"
          />
          <h6>{{ item.title }}</h6>
        </div>
        <div
          v-else
          class="plus-title"
          @click="goToPricing(item.pathName, item.refName)"
        >
          <h6>{{ item.title }}</h6>
          <img
            src="../../../public/svgs/icons-svg/icono-mas-blanco-toteat.svg"
            id="icon"
            alt="toteat sales manager"
          />
        </div>
        <div class="plus-description">
          <p>
            {{ item.description }}
          </p>
        </div>
      </div>
      <div class="desktop-image">
        <img
          src="../../../public/images/toteat-services/devices.png"
          :alt="t.m11000_21 || 'soluciones toteat'"
          id="background-solutions"
        />
      </div>
      <div class="mobile-image">
        <img
          src="../../../public/images/toteat-services/devices-mobile.png"
          alt="take away"
          id="take-away-img"
        />
      </div>
    </div>
    <div class="google-cloud">
      <p>{{ t.m11000_32 || "Toteat utiliza" }}</p>
      <img
        src="../../../public/images/logos/google-cloud.png"
        alt="google cloud"
      />
    </div>
  </div>
</template>

<script>
import mixinGlobals from "@/components/_mixins/globalMixins";

export default {
  data() {
    return {
      solutionsList: [
        {
          containerId: "pos",
          pathName: "solutions",
          refName: "point-of-sale",
          title: this.t.m11000_22 || "Punto de venta",
          description:
            this.t.m11000_27 ||
            "Una plataforma de punto de venta para gestionar todo tu restaurante.",
        },
        {
          containerId: "support",
          pathName: "toteatUser",
          refName: "white-card",
          title: this.t.m11000_23 || "Soporte 365 días",
          description:
            this.t.m11000_28 ||
            "Nuestros asesores gastronómicos te ayudarán cuando lo necesites. Los 365 días del año.",
        },
        {
          containerId: "kds",
          pathName: "solutions",
          refName: "KDS-system",
          title: this.t.m11000_24 || "Comandas digitales (KDS)",
          description:
            this.t.m11000_29 ||
            "Digitaliza tus comandas, centraliza todos tus pedidos en una sola pantalla.",
        },
        {
          containerId: "app",
          pathName: "solutions",
          refName: "checkin-title",
          title: this.t.m11000_25 || "Menú QR y Tienda virtual",
          description:
            this.t.m11000_30 ||
            "Ofrece la mejor experiencia digital a tus clientes con el Menú QR, pedidos desde la mesa, delivery y pick up.",
        },
        {
          containerId: "analytics",
          pathName: "solutions",
          refName: "analytics",
          title: this.t.m11000_26 || "Reportería",
          description:
            this.t.m11000_31 ||
            "Data a tiempo real para que tomes decisiones estratégicas e inteligentes.",
        },
      ],
    };
  },
  mixins: [mixinGlobals],
  methods: {
    goTo(refName) {
      const elem = document.getElementById(refName);
      const yOffset = -130;
      const y = elem.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    },
    goToPricing(pathName, refName) {
      if (!window.location.pathname.includes(pathName)) {
        var th = this;
        this.$router.push({ name: pathName }).then(() => {
          setTimeout(function () {
            th.goTo(refName);
          }, 200);
        });
      } else {
        this.goTo(refName);
      }
    },
  },
};
</script>
