<template>
  <div v-if="country && toteatCountries">
    <div class="main-container" id="prices">
      <div class="main-text">
        <h2>{{ t.m11000_266 || "El plan perfecto para tu negocio." }}</h2>
        <p class="header-paragraph">
          {{
            t.m11000_267 ||
            "Selecciona el país y tipo de negocio en el que quieres implementar Toteat."
          }}
        </p>
        <div class="selectors">
          <b-field>
            <b-select
              :value="toteatCountries[country]"
              expanded
              v-model="countryName"
            >
              <option v-for="(value, key) in toteatCountries" v-bind:key="key">
                {{ value }}
              </option>
            </b-select>
          </b-field>
          <!-- <b-field>
          <b-select placeholder="Tipo de negocio" expanded>
            <option
              v-for="(business, index) in BusinessTypes"
              v-bind:key="index"
            >
              {{ business }}
            </option>
          </b-select>
        </b-field> -->
        </div>
      </div>
    </div>
    <ToteatPlans :plans="countryPlan" :country="countryName" />
  </div>
</template>

<script>
import ToteatPlans from "@/components/ResourcesToteat/Prices/ToteatPlanPrices.vue";
import { COUNTRIES_NAMES, countryPlans, findCountryCode } from "@/js/utils";

export default {
  components: {
    ToteatPlans,
  },
  data() {
    return {
      toteatCountries: null,
      countryName: null,
      BusinessTypes: ["Negocio gastronómico", "Minimarket o almacén"],
    };
  },
  computed: {
    country() {
      return this.$store.state.luces.language.split("-")[1].toLowerCase();
    },
    countryPlan() {
      if (this.countryCode) {
        return countryPlans(this.countryCode);
      } else {
        return null;
      }
    },
    countryCode() {
      return findCountryCode(this.countryName);
    },
    // updateCountry() {
    //   if (this.country != this.$store.state.luces.country);
    //   return this.country;
    // },
  },
  methods: {
    updateCountryName() {
      this.countryName = this.toteatCountries[this.country];
    },
  },
  mounted() {},
  created() {
    this.toteatCountries = COUNTRIES_NAMES;
    this.countryName = this.toteatCountries[this.country];
  },
  watch: {
    country: {
      handler: function () {
        this.updateCountryName();
      },
    },
  },
};
</script>
