import axios from "axios";

export default function sendForm(info) {
  return new Promise((resolve, reject) => {
    if (info) {
      axios
        .post("https://toteat.com/forms/contactform/newpage", info)
        .then((response) => {
          console.log("respuesta del formulario");
          console.log(response);
          if (response.data.ok) {
            console.log("Formulario enviado correctamente");
            resolve(true);
          } else {
            if (reject) reject(false);
            else resolve(false);
          }
        })
        .catch((error) => {
          console.error("¡Hubo un error!", error);
          if (reject) reject(false);
          else resolve(false);
        });
    } else {
      console.log("Error en la información ingresada.");
      if (reject) reject(false);
      else resolve(false);
    }
  });
}
