var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all-plan-devices"},[(_vm.width > 1023)?_c('div',{staticClass:"desktop-prices"},[_c('div',{staticClass:"section-container",attrs:{"id":"cards"}},[_c('div',{staticClass:"horizontal-container"},_vm._l((Object.keys(_vm.plans)),function(item){return _c('div',{key:item,staticClass:"card-plan",on:{"click":function($event){_vm.setActivePlan(Object.keys(_vm.plans).indexOf(item), 'plans')}}},[_c('div',{staticClass:"main-card-container"},[_c('div',{staticClass:"selected-plan",class:{
                'selected-on':
                  Object.keys(_vm.plans).indexOf(item) === _vm.activePlan,
              }}),_c('img',{staticClass:"icon",attrs:{"src":require("../../../../public/svgs/icons-svg/kds-black.svg"),"alt":"kitchen-display-system"}}),(_vm.country === 'United States')?_c('h6',[_vm._v(" "+_vm._s(_vm.plans[item].name)+" PLAN ")]):_c('h6',[_vm._v("PLAN "+_vm._s(_vm.plans[item].name))]),_c('p',[_vm._v(" "+_vm._s(_vm.plans[item].content)+" ")])]),(_vm.activePlan === Object.keys(_vm.plans).indexOf(item))?_c('div',{staticClass:"triangle"}):_vm._e()])}),0)]),_c('div',{staticClass:"section-container",attrs:{"id":"plans"}},[_c('div',{staticClass:"vertical-container"},[_c('div',{staticClass:"horizontal-container",attrs:{"id":"price-number"}},[_c('div',{staticClass:"price-container"},[_c('h3',{staticStyle:{"color":"#ff4b33"}},[_vm._v(" "+_vm._s(_vm.plans[Object.keys(_vm.plans)[_vm.activePlan]].percentage)+" ")]),_c('h5',{staticStyle:{"color":"#ff4b33"}},[_c('b',[_vm._v(_vm._s((_vm.t.m11000_277 || "VENTA NETA MENSUAL").toUpperCase()))])]),_c('p',[_c('span',[_vm._v(" "+_vm._s(_vm.t.m11000_276 || "Desde")+" "+_vm._s(_vm.plans[Object.keys(_vm.plans)[_vm.activePlan]].price)+" + "+_vm._s(_vm.plans[Object.keys(_vm.plans)[_vm.activePlan]].taxes)+" ")])]),(_vm.country === 'Argentina')?_c('p',{staticStyle:{"font-size":"smaller"}},[_vm._v(" *IVA no aplica para Tierra del Fuego ")]):_vm._e(),_c('b-button',{staticClass:"button-red",on:{"click":function($event){return _vm.redirect('contactSales')}}},[_vm._v(" "+_vm._s(_vm.t.m11000_431 || "Ver DEMO")+" ")])],1)]),_c('div',{staticClass:"horizontal-container",attrs:{"id":"plans-details"}},_vm._l((Object.keys(_vm.plans).slice(
              0,
              _vm.activePlan + 1
            )),function(elem,indexElem){return _c('div',{key:indexElem,staticClass:"plan-detail"},[_c('div',{staticClass:"horizontal-container"},[_c('h6',[_vm._v(_vm._s(_vm.plans[Object.keys(_vm.plans)[indexElem]].name))]),(indexElem != _vm.activePlan)?_c('div',{staticClass:"plus"},[_vm._v("+")]):_vm._e()]),_c('hr'),_c('ul',_vm._l((_vm.plans[Object.keys(_vm.plans)[indexElem]].list),function(i,j){return _c('li',{key:j},[_c('img',{attrs:{"src":require("../../../../public/svgs/icons-svg/icono-check-rojo-toteat.svg"),"id":"check-icon","alt":"toteat sales manager"}}),_c('p',[_vm._v(_vm._s(i))])])}),0)])}),0),(_vm.plans.basicPlan.specifications.length > 0)?_c('div',{staticStyle:{"padding-top":"5%","font-size":"small","display":"flex","justify-content":"center"}},[_vm._v(" "+_vm._s(_vm.plans.basicPlan.specifications[0])+" ")]):_vm._e()])])]):_c('div',{staticClass:"mobile-prices"},[_c('div',{staticClass:"vertical-container"},[_c('h3',[_vm._v("Planes")]),_c('div',{staticClass:"horizontal-container",attrs:{"id":"accordeon-text"}},[_c('div',{staticClass:"section-text"},_vm._l((Object.keys(_vm.plans)),function(item){return _c('div',{key:item,staticClass:"items-container"},[_c('div',{staticClass:"description-title"},[(_vm.country === 'United States')?_c('h6',[_vm._v(" "+_vm._s(_vm.plans[item].name)+" PLAN ")]):_c('h6',[_vm._v("PLAN "+_vm._s(_vm.plans[item].name))]),_c('div',{staticClass:"clickable-icon",class:{
                  'clicked-on':
                    Object.keys(_vm.plans).indexOf(item) === _vm.activePlan,
                },attrs:{"id":"hover-white"},on:{"click":function($event){_vm.activePlan = Object.keys(_vm.plans).indexOf(item)}}})]),(_vm.activePlan === Object.keys(_vm.plans).indexOf(item))?_c('div',{staticClass:"vertical-container",attrs:{"id":"plans-details"}},[_c('div',{staticClass:"section-text"},[_c('p',{attrs:{"id":"element-description"}},[_vm._v(" "+_vm._s(_vm.plans[item].content)+" ")])]),_c('div',{staticClass:"section-container",attrs:{"id":"plans"}},[_c('div',{staticClass:"vertical-container"},[_c('div',{staticClass:"horizontal-container",attrs:{"id":"price-number"}},[_c('div',{staticClass:"price-container"},[_c('h3',{staticStyle:{"color":"#ff4b33"}},[_vm._v(" "+_vm._s(_vm.plans[Object.keys(_vm.plans)[_vm.activePlan]].percentage)+" ")]),_c('h5',{staticStyle:{"color":"#ff4b33"}},[_c('b',[_vm._v(_vm._s(_vm.t.m11000_277.toUpperCase() || "VENTA NETA MENSUAL"))])]),_c('p',[_c('span',[_vm._v(" "+_vm._s(_vm.t.m11000_276 || "Desde")+" "+_vm._s(_vm.plans[Object.keys(_vm.plans)[_vm.activePlan]].price)+" + "+_vm._s(_vm.plans[Object.keys(_vm.plans)[_vm.activePlan]].taxes)+" ")])]),(_vm.country === 'Argentina')?_c('p',{staticStyle:{"font-size":"9px"}},[_vm._v(" *IVA no aplica para Tierra del Fuego ")]):_vm._e(),_c('b-button',{staticClass:"button-red",on:{"click":function($event){return _vm.redirect('contactSales')}}},[_vm._v(" "+_vm._s(_vm.t.m11000_431 || "Ver DEMO")+" ")])],1)]),(_vm.activePlan != 0 && _vm.country !== 'United States')?_c('h5',[_vm._v(" PLAN "+_vm._s(_vm.plans[Object.keys(_vm.plans)[_vm.activePlan - 1]].name)+" + ")]):_vm._e(),(_vm.activePlan != 0 && _vm.country === 'United States')?_c('h5',[_vm._v(" "+_vm._s(_vm.plans[Object.keys(_vm.plans)[_vm.activePlan - 1]].name)+" PLAN + ")]):_vm._e(),_c('ul',_vm._l((_vm.plans[Object.keys(_vm.plans)[_vm.activePlan]]
                        .list),function(i,j){return _c('li',{key:j},[_c('img',{attrs:{"src":require("../../../../public/svgs/icons-svg/icono-check-rojo-toteat.svg"),"id":"check-icon","alt":"toteat sales manager"}}),_c('p',[_vm._v(_vm._s(i))])])}),0)])])]):_vm._e(),(
                'specifications' in _vm.plans[item] &&
                _vm.activePlan === Object.keys(_vm.plans).indexOf(item)
              )?_c('div',{staticStyle:{"padding-top":"5%","font-size":"small"}},[_vm._v(" "+_vm._s(_vm.plans[item].specifications[0])+" ")]):_vm._e(),_c('hr')])}),0)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }