<template>
  <div class="home">
    <Header />
    <Digitize />
    <Solutions />
    <CheckIn />
    <Services />
    <Clients :country="country" />
    <Reviews :country="country" />
    <Cards />
  </div>
</template>

<script>
import Header from "@/components/Home/HeaderHome.vue";
import Digitize from "@/components/Home/DigitizeHome.vue";
import Solutions from "@/components/Home/SolutionsHome.vue";
import CheckIn from "@/components/Home/CheckinHome.vue";
import Clients from "@/components/Home/ClientsHome.vue";
import Services from "@/components/Home/ServicesHome.vue";
import Reviews from "@/components/Home/ReviewsHome.vue";
import Cards from "@/components/Home/FinalCardsHome.vue";
import mixinGlobals from "@/components/_mixins/globalMixins";
// import { AVAILABLES_LANG } from "@/js/utils.js";
// import { reloadPagina } from "@/plugins/BaseToteat/libs/comun";
// import * as $lstorage from "@/plugins/BaseToteat/libs/lstorage";
// import { BASE_TRADS } from "@/plugins/Traducciones/core.js";

export default {
  name: "HomeView",
  components: {
    Header,
    Digitize,
    Solutions,
    CheckIn,
    Clients,
    Services,
    Reviews,
    Cards,
  },
  mixins: [mixinGlobals],
  metaInfo() {
    return {
      title: this.t.m11000_424 || "Software Gastronómico",
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            this.t.m11000_425 ||
            "Mejora tu operación, aumenta tus ventas y facilita tu experiencia con Toteat: Software Punto de Venta diseñado especialmente para negocios gastronómicos.",
        },
        // {
        //   property: "og:title",
        //   content: this.t.m11000_424 || "Software Gastronómico",
        // },
        // {
        //   property: "og:site_name",
        //   content: "Toteat",
        // },
        // {
        //   property: "og:url",
        //   content: window.location.hostname + window.location.pathname,
        // },
        // {
        //   property: "og:description",
        //   content:
        //     this.t.m11000_425 ||
        //     "Mejora tu operación, aumenta tus ventas y facilita tu experiencia con Toteat: Software Punto de Venta diseñado especialmente para negocios gastronómicos.",
        // },
      ],
      // link: [
      //   {
      //     rel: "canonical",
      //     href: window.location.href,
      //   },
      // ],
    };
  },
  computed: {
    country() {
      return this.$store.state.luces.language.split("-")[1].toLowerCase();
    },
  },
  watch: {
    country: {
      handler: function () {
        this.country;
      },
    },
    changeLanguage: {
      handler: function () {
        this.changeLanguage;
      },
    },
  },
  mounted() {
    var language = document.location.pathname.split("/")[1];
    var routeName = this.$route.name;
    this.updateLang(language, routeName);
  },
};
</script>
