<template>
  <div class="all-plan-devices">
    <div v-if="width > 1023" class="desktop-prices">
      <div class="section-container" id="cards">
        <div class="horizontal-container">
          <div
            class="card-plan"
            v-for="item in Object.keys(plans)"
            v-bind:key="item"
            @click="setActivePlan(Object.keys(plans).indexOf(item), 'plans')"
          >
            <div class="main-card-container">
              <div
                :class="{
                  'selected-on':
                    Object.keys(plans).indexOf(item) === activePlan,
                }"
                class="selected-plan"
              ></div>
              <img
                class="icon"
                src="../../../../public/svgs/icons-svg/kds-black.svg"
                alt="kitchen-display-system"
              />
              <h6 v-if="country === 'United States'">
                {{ plans[item].name }} PLAN
              </h6>
              <h6 v-else>PLAN {{ plans[item].name }}</h6>
              <p>
                {{ plans[item].content }}
              </p>
            </div>
            <div
              v-if="activePlan === Object.keys(plans).indexOf(item)"
              class="triangle"
            ></div>
          </div>
        </div>
      </div>
      <div class="section-container" id="plans">
        <div class="vertical-container">
          <div class="horizontal-container" id="price-number">
            <div class="price-container">
              <h3 style="color: #ff4b33">
                {{ plans[Object.keys(plans)[activePlan]].percentage }}
              </h3>
              <h5 style="color: #ff4b33">
                <b>{{
                  (t.m11000_277 || "VENTA NETA MENSUAL").toUpperCase()
                }}</b>
              </h5>
              <p>
                <span>
                  {{ t.m11000_276 || "Desde" }}
                  {{ plans[Object.keys(plans)[activePlan]].price }} +
                  {{ plans[Object.keys(plans)[activePlan]].taxes }}
                </span>
              </p>
              <p style="font-size: smaller" v-if="country === 'Argentina'">
                *IVA no aplica para Tierra del Fuego
              </p>
              <b-button class="button-red" @click="redirect('contactSales')">
                {{ t.m11000_431 || "Ver DEMO" }}
              </b-button>
            </div>
          </div>
          <div class="horizontal-container" id="plans-details">
            <div
              v-for="(elem, indexElem) in Object.keys(plans).slice(
                0,
                activePlan + 1
              )"
              v-bind:key="indexElem"
              class="plan-detail"
            >
              <div class="horizontal-container">
                <h6>{{ plans[Object.keys(plans)[indexElem]].name }}</h6>
                <div v-if="indexElem != activePlan" class="plus">+</div>
              </div>
              <hr />
              <ul>
                <li
                  v-for="(i, j) in plans[Object.keys(plans)[indexElem]].list"
                  v-bind:key="j"
                >
                  <img
                    src="../../../../public/svgs/icons-svg/icono-check-rojo-toteat.svg"
                    id="check-icon"
                    alt="toteat sales manager"
                  />
                  <p>{{ i }}</p>
                </li>
              </ul>
            </div>
          </div>
          <div
            v-if="plans.basicPlan.specifications.length > 0"
            style="
              padding-top: 5%;
              font-size: small;
              display: flex;
              justify-content: center;
            "
          >
            {{ plans.basicPlan.specifications[0] }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mobile-prices">
      <div class="vertical-container">
        <h3>Planes</h3>
        <div class="horizontal-container" id="accordeon-text">
          <div class="section-text">
            <div
              class="items-container"
              v-for="item in Object.keys(plans)"
              v-bind:key="item"
            >
              <div class="description-title">
                <h6 v-if="country === 'United States'">
                  {{ plans[item].name }} PLAN
                </h6>
                <h6 v-else>PLAN {{ plans[item].name }}</h6>
                <div
                  :class="{
                    'clicked-on':
                      Object.keys(plans).indexOf(item) === activePlan,
                  }"
                  class="clickable-icon"
                  id="hover-white"
                  v-on:click="activePlan = Object.keys(plans).indexOf(item)"
                ></div>
              </div>
              <div
                v-if="activePlan === Object.keys(plans).indexOf(item)"
                class="vertical-container"
                id="plans-details"
              >
                <div class="section-text">
                  <p id="element-description">
                    {{ plans[item].content }}
                  </p>
                </div>
                <div class="section-container" id="plans">
                  <div class="vertical-container">
                    <div class="horizontal-container" id="price-number">
                      <div class="price-container">
                        <h3 style="color: #ff4b33">
                          {{ plans[Object.keys(plans)[activePlan]].percentage }}
                        </h3>
                        <h5 style="color: #ff4b33">
                          <b>{{
                            t.m11000_277.toUpperCase() || "VENTA NETA MENSUAL"
                          }}</b>
                        </h5>
                        <p>
                          <span>
                            {{ t.m11000_276 || "Desde" }}
                            {{ plans[Object.keys(plans)[activePlan]].price }} +
                            {{ plans[Object.keys(plans)[activePlan]].taxes }}
                          </span>
                        </p>
                        <p
                          style="font-size: 9px"
                          v-if="country === 'Argentina'"
                        >
                          *IVA no aplica para Tierra del Fuego
                        </p>
                        <b-button
                          class="button-red"
                          @click="redirect('contactSales')"
                        >
                          {{ t.m11000_431 || "Ver DEMO" }}
                        </b-button>
                      </div>
                    </div>
                    <h5 v-if="activePlan != 0 && country !== 'United States'">
                      PLAN
                      {{ plans[Object.keys(plans)[activePlan - 1]].name }} +
                    </h5>
                    <h5 v-if="activePlan != 0 && country === 'United States'">
                      {{ plans[Object.keys(plans)[activePlan - 1]].name }} PLAN
                      +
                    </h5>
                    <ul>
                      <li
                        v-for="(i, j) in plans[Object.keys(plans)[activePlan]]
                          .list"
                        v-bind:key="j"
                      >
                        <img
                          src="../../../../public/svgs/icons-svg/icono-check-rojo-toteat.svg"
                          id="check-icon"
                          alt="toteat sales manager"
                        />
                        <p>{{ i }}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  'specifications' in plans[item] &&
                  activePlan === Object.keys(plans).indexOf(item)
                "
                style="padding-top: 5%; font-size: small"
              >
                {{ plans[item].specifications[0] }}
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    plans: Object,
    country: String,
  },
  data() {
    return {
      width: window.innerWidth,
      activePlan: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
    },
    setActivePlan(value, refName) {
      this.activePlan = value;
      this.goTo(refName);
    },
    redirect(value) {
      if (this.$store.state.luces.language) {
        this.$router.push({
          name: value,
          params: {
            lang: this.$store.state.luces.language,
          },
        });
      }
    },
    goTo(refName) {
      const elem = document.getElementById(refName);
      const yOffset = 0;
      const y = elem.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    },
  },
};
</script>
