import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    luces: {
      country: null,
      language: "es-CL",
    },
  },
  getters: {
    getCountry(state) {
      return state.luces.country || "cl";
    },
    getLanguage(state) {
      return state.luces.language || "es-CL";
    },
  },
  actions: {
    updateLucesProp({ commit }, payload) {
      if (payload) commit("changeLucesProp", payload);
    },
  },
  mutations: {
    /**
     * Receives all the parameters you want to change from the luces object in the state and changes them.
     *
     * @param {object} state
     * @param {object} payload Variables to be changed with their value
     */
    changeLucesProp(state, payload) {
      if (payload) {
        for (var prop in payload) {
          // if (payload.hasOwnProperty(prop)){
          if (Object.prototype.hasOwnProperty.call(payload, prop)) {
            state.luces[prop] = payload[prop];
            if (prop === "connectionLogged")
              state["connectionLogged"] = payload[prop];
            console.log("Se cambio luces prop " + prop);
            console.log(payload[prop]);
          }
        }
      }
    },
  },
  modules: {},
});

window.VUE_STORE = true;
console.log("Finalizado store index.");
