<template>
  <div class="section-container" id="form">
    <div class="section-text">
      <h2>
        {{
          t.m11000_122 ||
          "Para nosotros es muy importante que nos cuentes sobre ti."
        }}
      </h2>
    </div>
    <div v-if="width > 1023" class="section-form" id="desktop-form">
      <form ref="salesForm" @submit.prevent="getFormValues">
        <div class="form-fields" id="business-form">
          <div class="form-left">
            <b-field :label="t.m11000_123 || 'Nombre y Apellido'">
              <b-input
                type="text"
                placeholder="Kevin Garvey"
                name="name"
                :title="t.m11000_388 || 'Completa este campo'"
                :validation-message="t.m11000_388 || 'Completa este campo'"
                autocomplete="off"
                required
              ></b-input>
            </b-field>

            <b-field :label="t.m11000_125 || 'Correo electrónico'">
              <b-input
                type="email"
                placeholder="kevingarvey@gmail.com"
                :title="t.m11000_388 || 'Completa este campo'"
                :validation-message="
                  t.m11000_392 || 'Tu correo debe poseer `@` y una dirección'
                "
                name="email"
                autocomplete="off"
                required
              ></b-input>
            </b-field>

            <b-field :label="t.m11000_127 || 'Tipo de negocio'">
              <b-select
                v-model="selectedArea"
                :placeholder="t.m11000_131 || 'Seleccione tipo de negocio'"
                :title="t.m11000_391 || 'Selecciona un elemento de la lista'"
                :validation-message="
                  t.m11000_391 || 'Selecciona un elemento de la lista'
                "
                name="area"
                required
              >
                <option value="1">{{ t.m11000_133 || "Restaurante" }}</option>
                <option value="2">{{ t.m11000_134 || "Cafetería" }}</option>
                <option value="3">{{ t.m11000_135 || "Dark Kitchen" }}</option>
                <option value="4">
                  {{ t.m11000_136 || "Bar/Discoteque" }}
                </option>
                <option value="5">
                  {{ t.m11000_137 || "Almacén/Minimarket" }}
                </option>
                <option value="6">
                  {{ t.m11000_138 || "Botillería/Licorería" }}
                </option>
                <option value="7">
                  {{ t.m11000_139 || "Panadería/Pastelería" }}
                </option>
                <option value="8">{{ t.m11000_140 || "Fast Food" }}</option>
                <option value="9">{{ t.m11000_141 || "Foodtruck" }}</option>
                <option value="10">{{ t.m11000_142 || "Heladería" }}</option>
                <option value="11">{{ t.m11000_143 || "Otro" }}</option>
              </b-select>
            </b-field>

            <b-button
              v-if="!openComment"
              class="button-empty-black"
              @click="openComment = !openComment"
              >{{ t.m11000_129 || "Agregar comentario" }}<b-icon icon="plus"
            /></b-button>

            <b-button
              v-if="openComment"
              class="button-empty-black"
              @click="openComment = !openComment"
              >{{ t.m11000_130 || "Quitar comentario" }}<b-icon icon="minus"
            /></b-button>

            <b-field v-if="openComment" :label="t.m11000_369 || 'Comentario'">
              <b-input
                :placeholder="
                  t.m11000_144 ||
                  '¿Algún servicio en específico? ¿Otro rubro distinto de las opciones? ¿Comentario adicional?'
                "
                name="comments"
                autocomplete="off"
                type="textarea"
              ></b-input>
            </b-field>
          </div>
          <div class="form-right">
            <b-field :label="t.m11000_124 || 'Teléfono'" expanded>
              <div class="prefix-number">
                <CountryFlag
                  @onSelect="onSelect"
                  :preferredCountries="['cl', 'ar', 'co', 'pe', 'es', 'us']"
                >
                </CountryFlag>
                ({{ prefix }})
              </div>
              <b-input
                type="number"
                :title="t.m11000_388 || 'Completa este campo'"
                :validation-message="t.m11000_388 || 'Completa este campo'"
                min="0"
                name="phone"
                required
                expanded
              ></b-input>
            </b-field>

            <b-field :label="t.m11000_126 || 'Ciudad'">
              <b-input
                placeholder="Santiago"
                :title="t.m11000_388 || 'Completa este campo'"
                :validation-message="t.m11000_388 || 'Completa este campo'"
                name="city"
                autocomplete="off"
                required
              ></b-input>
            </b-field>

            <b-field :label="t.m11000_128 || 'Nombre del negocio'">
              <b-input
                placeholder="La centinela"
                :title="t.m11000_388 || 'Completa este campo'"
                :validation-message="t.m11000_388 || 'Completa este campo'"
                name="businessName"
                autocomplete="off"
                required
              ></b-input>
            </b-field>
            <div class="submit-form">
              <b-button class="submit-button-black" native-type="submit">
                {{ t.m11000_132 || "Enviar formulario" }}
              </b-button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div v-else class="section-form" id="mobile-form">
      <form ref="salesForm" @submit.prevent="getFormValues">
        <div class="form-fields">
          <b-field>
            <b-input
              type="text"
              :title="t.m11000_388 || 'Completa este campo'"
              :placeholder="t.m11000_123 || 'Nombre y Apellido'"
              name="name"
              :validation-message="t.m11000_388 || 'Completa este campo'"
              autocomplete="off"
              required
            ></b-input>
          </b-field>

          <b-field>
            <b-input
              type="email"
              :placeholder="t.m11000_125 || 'Correo electrónico'"
              :title="t.m11000_388 || 'Completa este campo'"
              :validation-message="
                t.m11000_392 || 'Tu correo debe poseer `@` y una dirección'
              "
              name="email"
              autocomplete="off"
              required
            ></b-input>
          </b-field>

          <b-field expanded>
            <div class="prefix-number">
              <CountryFlag
                @onSelect="onSelect"
                :preferredCountries="['cl', 'ar', 'co', 'pe', 'es', 'us']"
              >
              </CountryFlag>
              ({{ prefix }})
            </div>
            <b-input
              :controls="false"
              type="number"
              min="0"
              name="phone"
              :placeholder="t.m11000_124 || 'Teléfono'"
              :title="t.m11000_388 || 'Completa este campo'"
              :validation-message="t.m11000_388 || 'Completa este campo'"
              required
              expanded
            >
            </b-input>
          </b-field>

          <b-field>
            <b-input
              :placeholder="t.m11000_126 || 'Ciudad'"
              :title="t.m11000_388 || 'Completa este campo'"
              :validation-message="t.m11000_388 || 'Completa este campo'"
              name="city"
              autocomplete="off"
              required
            ></b-input>
          </b-field>

          <b-field>
            <b-input
              :placeholder="t.m11000_128 || 'Nombre del negocio'"
              :title="t.m11000_388 || 'Completa este campo'"
              :validation-message="t.m11000_388 || 'Completa este campo'"
              name="businessName"
              autocomplete="off"
              required
            ></b-input>
          </b-field>

          <b-field>
            <b-select
              v-model="selectedArea"
              :placeholder="t.m11000_127 || 'Tipo de negocio'"
              :title="t.m11000_391 || 'Selecciona un elemento de la lista'"
              :validation-message="
                t.m11000_391 || 'Selecciona un elemento de la lista'
              "
              name="area"
              required
            >
              <option value="1">{{ t.m11000_133 || "Restaurante" }}</option>
              <option value="2">{{ t.m11000_134 || "Cafetería" }}</option>
              <option value="3">{{ t.m11000_135 || "Dark Kitchen" }}</option>
              <option value="4">
                {{ t.m11000_136 || "Bar/Discoteque" }}
              </option>
              <option value="5">
                {{ t.m11000_137 || "Almacén/Minimarket" }}
              </option>
              <option value="6">
                {{ t.m11000_138 || "Botillería/Licorería" }}
              </option>
              <option value="7">
                {{ t.m11000_139 || "Panadería/Pastelería" }}
              </option>
              <option value="8">{{ t.m11000_140 || "Fast Food" }}</option>
              <option value="9">{{ t.m11000_141 || "Foodtruck" }}</option>
              <option value="10">{{ t.m11000_142 || "Heladería" }}</option>
              <option value="11">{{ t.m11000_143 || "Otro" }}</option>
            </b-select>
          </b-field>

          <b-button
            v-if="!openComment"
            class="button-empty-black"
            @click="openComment = !openComment"
            >{{ t.m11000_129 || "Agregar comentario" }}<b-icon icon="plus"
          /></b-button>

          <b-button
            v-if="openComment"
            class="button-empty-black"
            @click="openComment = !openComment"
            >{{ t.m11000_130 || "Quitar comentario" }}<b-icon icon="minus"
          /></b-button>

          <b-field v-if="openComment" :label="t.m11000_369 || 'Comentario'">
            <b-input
              :placeholder="
                t.m11000_144 ||
                '¿Algún servicio en específico? ¿Otro rubro distinto de las opciones? ¿Comentario adicional?'
              "
              name="comments"
              autocomplete="off"
              type="textarea"
            ></b-input>
          </b-field>

          <div class="submit-form">
            <b-button class="submit-button-black" native-type="submit">
              {{ t.m11000_132 || "Enviar formulario" }}
            </b-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CountryFlag from "vue-country-code-select";
import sendForm from "@/services/gaeServices";

export default {
  components: {
    CountryFlag,
  },
  data() {
    return {
      prefix: "",
      selectedArea: null,
      name: null,
      email: null,
      phone: null,
      city: null,
      country: null,
      businessName: null,
      area: null,
      comments: null,
      width: window.innerWidth,
      body: null,
      openComment: false,
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    success() {
      this.$buefy.toast.open({
        message: "¡Formulario enviado correctamente!",
        duration: 6000,
        type: "is-success",
      });
    },
    danger(value) {
      this.$buefy.toast.open({
        message: value,
        duration: 6000,
        position: "is-bottom",
        type: "is-danger",
      });
    },
    onResize() {
      this.width = window.innerWidth;
    },
    onSelect({ dialCode, name }) {
      this.country = name;
      this.prefix = "+" + dialCode;
    },
    getFormValues(event) {
      this.name = event.target.elements.name.value;
      this.email = event.target.elements.email.value;
      this.phone = this.prefix + event.target.elements.phone.value;
      this.city = event.target.elements.city.value;
      this.area = event.target.elements.area.value;
      this.businessName = event.target.elements.businessName.value;
      if (event.target.elements.comments === undefined) {
        this.comments = "Formulario WEB";
      } else {
        if (event.target.elements.comments.value === "") {
          this.comments = "Formulario WEB";
        } else if (event.target.elements.comments.value.length > 5) {
          this.comments = event.target.elements.comments.value;
        } else {
          this.comments =
            "Comentarios Formulario WEB: " +
            event.target.elements.comments.value;
        }
      }
      this.body = {
        nombre: this.name,
        email: this.email,
        telefono: this.phone,
        contenido: this.comments,
        tipo_negocio: parseInt(this.area),
        nombre_negocio: this.businessName,
        asunto: "",
        ciudad: this.city,
        pais: this.country,
      };
      sendForm(this.body)
        .then((resp) => {
          if (resp) {
            this.success();
            this.$refs.salesForm.reset();
          } else {
            this.danger("Error al enviar formulario");
            console.log("Error en proceso"); // Colocar mensaje en pontalla bonito para errores
          }
        })
        .catch((err) => {
          this.danger("Error al enviar formulario");
          console.log("Error en proceso", err); // Colocar mensaje en pontalla bonito para errores
        });
    },
  },
};
</script>
