<template>
  <b-modal
    class="web-to-case"
    :active="showModal"
    @close="closeModal"
    :can-cancel="false"
  >
    <!-- Modal content -->
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          <h1>{{ t.m11000_321 || "¿Cómo podemos ayudarte?" }}</h1>
          <p>
            {{
              t.m11000_448 ||
              "Completa los datos para abordar correctamente tu caso."
            }}
          </p>
        </div>

        <button class="close-button" @click="closeModal">
          <b-icon icon="close"></b-icon>
        </button>
      </div>

      <form
        class="web-to-case-form"
        action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00DDn00000AChwc"
        method="POST"
      >
        <b-input type="hidden" name="orgid" value="00DDn00000AChwc" />
        <b-input
          type="hidden"
          name="retURL"
          :value="'https://toteat.com/' + language + '/gracias'"
        />

        <div class="inputs horizontal-container">
          <b-field
            :label="t.m11000_451 || 'Nombre negocio'"
            label-position="on-border"
            for="company"
          >
            <b-input id="company" maxlength="80" name="company" type="text" />
          </b-field>

          <b-field
            :label="t.m11000_467 || 'Nombre contacto'"
            label-position="on-border"
            for="name"
          >
            <b-input id="name" maxlength="80" name="name" type="text" />
          </b-field>
        </div>

        <div class="inputs horizontal-container">
          <div class="field is-floating-label">
            <label class="label"
              >{{ t.m11000_449 || "ID Toteat" }}
              <b-tooltip
                label=""
                position="is-right"
                type="is-light"
                size="is-small"
                :triggers="['click']"
                multilined
                append-to-body
                :auto-close="['outside', 'escape']"
              >
                <b-icon icon="help-circle-outline" size="is-small"></b-icon>
                <template v-slot:content>
                  <div class="tooltip-template">
                    <span id="content-text">
                      {{ t.m11000_452 || "Para encontrar tu" }}
                      <b>{{ t.m11000_449 || "ID Toteat" }}</b
                      >{{ t.m11000_453 || ", debes ingresar a tu" }}
                      <a href="https://res.toteat.com" target="_blank">
                        {{ t.m11000_454 || "ambiente" }}</a
                      >
                      {{ t.m11000_455 || "y dirigirte a " }}
                      <b-tag type="is-dark">{{
                        t.m11000_456 || "Restaurants"
                      }}</b-tag>
                      »
                      <b-tag type="is-dark">{{
                        t.m11000_457 || "Seleccionar"
                      }}</b-tag
                      >. <br />
                      {{
                        t.m11000_458 ||
                        "Ahí podrás ver el código de tu restaurante en las dos primeras columnas."
                      }}
                      <br />
                    </span>
                    <img
                      src="../../public/images/toteat-services/id-resto-local.png"
                      alt="resto local toteat"
                    />
                    <span id="footer-text">
                      {{
                        t.m11000_459 ||
                        "Considerar que el ID Local debe ser una longitud de 5 dígitos, por lo que debe completarse con ceros a la izquierda. Ejemplo: 5438765623917831-00001."
                      }}
                    </span>
                  </div>
                </template>
              </b-tooltip>
            </label>
            <b-input
              v-model="inputValue"
              @input="validateFormat"
              id="00NDn00000cVzuc"
              name="00NDn00000cVzuc"
              maxlength="22"
              minlength="22"
              :placeholder="t.m11000_450 || 'Ej: 5676785432965842-00001'"
            />
            <div v-if="showError" class="invalid-feedback">
              {{ errorMessage }}
            </div>
          </div>

          <b-field
            :label="t.m11000_125 || 'Correo electrónico'"
            label-position="on-border"
            for="email"
            ><b-input
              id="email"
              maxlength="80"
              name="email"
              type="email"
              required
            />
          </b-field>
        </div>

        <div class="inputs horizontal-container">
          <b-field
            :label="t.m11000_124 || 'Teléfono'"
            label-position="on-border"
            for="phone"
          >
            <b-input id="phone" maxlength="40" name="phone" type="text"
          /></b-field>
          <b-field
            :label="t.m11000_460 || 'Tipo de incidente'"
            label-position="on-border"
            for="type"
            ><b-select
              v-model="incidentType"
              id="type"
              name="type"
              :placeholder="t.m11000_461 || 'Selecciona una opción'"
              required
            >
              <option value="Problema">Problema</option>
              <option value="Solicitud">Solicitud</option>
              <option value="Reclamo">Reclamo</option></b-select
            >
          </b-field>

          <b-input
            style="display: none"
            type="hidden"
            id="recordType"
            name="recordType"
            :value="recordType"
          />
        </div>

        <div v-if="incidentType" class="level-container">
          <p>
            {{ t.m11000_465 || "Haz seleccionado la categoría" }}
            <b>{{ incidentType }}.</b>
            {{
              t.m11000_466 ||
              "Completa las subcategorías para mayor detalle del incidente."
            }}
          </p>
          <b-field
            :label="(t.m11000_473 || 'Nivel') + ' 1'"
            label-position="on-border"
            ><b-select
              v-model="level1"
              id="00NDn00000cVzud"
              name="00NDn00000cVzud"
              :placeholder="t.m11000_461 || 'Selecciona una opción'"
              required
            >
              <option
                v-for="(item, key, index) in incidentList"
                v-bind:key="index"
                :value="key"
              >
                {{ key }}
              </option>
            </b-select>
          </b-field>

          <b-field
            v-if="level1 && !(incidentList[level1] instanceof Array)"
            :label="(t.m11000_473 || 'Nivel') + ' 2'"
            label-position="on-border"
            ><b-select
              v-model="level2"
              id="00NDn00000cVzv9"
              name="00NDn00000cVzv9"
              :placeholder="t.m11000_461 || 'Selecciona una opción'"
              required
            >
              <option
                v-for="(item, key, index) in incidentList[level1]"
                v-bind:key="index"
                :value="key"
              >
                {{ key }}
              </option>
            </b-select>
          </b-field>

          <b-field
            v-if="level2 && incidentList[level1][level2].length > 1"
            :label="(t.m11000_473 || 'Nivel') + ' 3'"
            label-position="on-border"
            ><b-select
              v-model="level3"
              id="00NDn00000cVzvA"
              name="00NDn00000cVzvA"
              :placeholder="t.m11000_461 || 'Selecciona una opción'"
              required
            >
              <option
                v-for="(item, key, index) in incidentList[level1][level2]"
                v-bind:key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </b-select>
          </b-field>
        </div>

        <b-field
          :label="t.m11000_468 || 'Descripción'"
          label-position="on-border"
          for="description"
        >
          <b-input type="textarea" name="description"></b-input>
        </b-field>
        <div class="modal-footer">
          <b-button class="cancel-form-button" @click="closeModal">{{
            t.m11000_469 || "Cerrar"
          }}</b-button>
          <b-button
            class="send-form-button"
            native-type="submit"
            name="submit"
            >{{ t.m11000_470 || "Enviar" }}</b-button
          >
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      language: "",
      incidentType: null,
      recordType: "",
      level1: null,
      level2: null,
      level3: null,
      inputValue: "",
      isValidFormat: false,
      showError: false,
      errorMessage: "",
      problems: {
        INTEGRACIONES: {
          DELIVERY: [
            "UBER EATS",
            "PEDIDOSYA",
            "JUSTO",
            "MERCAT",
            "CLEVERLYNK",
            "RAPPI",
            "DIDI",
          ],
          "PASARELAS DE PAGO": [
            "MERCADO PAGO",
            "REDELCOM",
            "MATCH",
            "FPAY",
            "CHEK",
            "OTRO",
          ],
        },
        FACTURADORES: {
          NUBOX: [
            "DESCUADRATURAS",
            "FOLIOS",
            "FALLA DE GENERACION DE DOCUMENTOS",
            "OTRO (ACTIVACIONES - TOKEN)",
          ],
          CONTALINE: [
            "DESCUADRATURAS",
            "FOLIOS",
            "FALLA DE GENERACION DE DOCUMENTOS",
            "OTRO (ACTIVACIONES - TOKEN)",
          ],
          BSALE: [
            "DESCUADRATURAS",
            "FOLIOS",
            "FALLA DE GENERACION DE DOCUMENTOS",
            "OTRO (ACTIVACIONES - TOKEN)",
          ],
          "DTE TOTEAT": [
            "DESCUADRATURAS",
            "FOLIOS BOLETA Y FACTURA",
            "NOTAS DE CREDITO",
            "FALLA DE GENERACION DE DOCUMENTOS",
          ],
          FACTURA1: [
            "Descuadraturas",
            "Folios",
            "Fallas de generación de documentos",
          ],
          FACTURANTE: [
            "DESCUADRATURAS",
            "FOLIOS",
            "FALLA DE GENERACION DE DOCUMENTOS",
          ],
          "IMPRESORA FISCAL": ["CONEXION FISCAL", "REPORTES HISTORICOS"],
        },
        INVENTARIO: {
          "STOCK CONTROL": [
            "REPORTERIA",
            "REGISTRO CONTABLE",
            "USABILIDAD DEL MODULO",
            "CONSULTAS GENERALES",
            "ERRORES DE REPORTERIA",
          ],
          "INGREDIENTES Y RECETAS": [
            "REPORTERIA",
            "REGISTRO CONTABLE",
            "USABILIDAD DEL MODULO",
            "CONSULTAS GENERALES",
            "ERRORES DE REPORTERIA",
          ],
        },
        IMPRESION: {
          "GESTION GENERAL IMPRESORAS": [
            "PROBLEMAS DE CONEXION",
            "REGLAS DE IMPRESION",
            "RE INSTALACION DE IMPRESORAS",
            "INSTALACION DE NUEVAS IMPRESORAS",
            "IMPRESION DE COMANDAS",
          ],
        },
        REPORTES: {
          "REPORTERIA TOTEAT": [
            "DESCUADRATURAS",
            "REPORTE MULTILOCAL",
            "ERRORES DE IMPUESTOS",
            "TRANSFERENCIA DE ORDENES",
            "ELIMINACION DE ORDENES",
          ],
        },
        "USO DE LA PLATAFORMA": {
          "CONSULTAS OPERATIVAS": [
            "USABILIDAD Y CONSULTAS DE CAJA",
            "USABILIDAD Y CONSULTAS DE TURNOS",
            "CONSULTAS GENERALES DEL SISTEMA",
            "CONSULTAS TOTEATAPP",
          ],
          "CONSULTAS ADMINISTRATIVAS": [
            "CONSULTAS AJUSTES GENERALES",
            "CONSULTAS DE PERFILES DE USUARIO",
            "CONSULTAS DTE O FACTURADORES",
            "FACTURACION",
            "INVENTARIO EN LINEA",
          ],
        },
      },
      requests: {
        "NUEVAS FUNCIONALIDADES": {
          KDS: ["-- Seleccionar --"],
          NFC: ["-- Seleccionar --"],
          INVENTARIO: ["-- Seleccionar --"],
          INTEGRACIONES: ["-- Seleccionar --"],
          "PASARELAS DE PAGO": ["-- Seleccionar --"],
          "TOTEAT SHOP": ["-- Seleccionar --"],
          "DOCUMENTO TRIBUTARIO ELECTRONICO": ["-- Seleccionar --"],
          "CAMBIO DE CARTA": ["-- Seleccionar --"],
          OTRO: ["-- Seleccionar --"],
        },
        "BAJA DEL SISTEMA": {
          "PROBLEMAS COMERCIALES": [
            "SERVICIO COSTOSO",
            "NO ME ADAPTE",
            "PORCENTAJE DE VENTA",
          ],
          "PROBLEMAS DE SERVICIO": [
            "NO DAMOS SOLUCIONES RAPIDAS",
            "NO SOLUCIONAMOS PROBLEMAS",
            "MALA ATENCION",
          ],
          "PROBLEMAS DEL SISTEMA": ["LENTITUD", "ERRORES EN LA PLATAFORMA"],
          "CIERRE LOCAL": ["-- Seleccionar --"],
          OTRO: ["-- Seleccionar --"],
        },
        CAPACITACIONES: {
          "CAPACITACION CAJERO": ["-- Seleccionar --"],
          "CAPACITACION GARZON": ["-- Seleccionar --"],
          "CAPACITACION REPORTES": ["-- Seleccionar --"],
          "CAPACITACION INVENTARIO": ["-- Seleccionar --"],
          "CAPACITACION ADMINISTRATIVA": ["-- Seleccionar --"],
          "CAPACITACION OPERATIVA DE USO GENERAL": ["-- Seleccionar --"],
          OTRO: ["-- Seleccionar --"],
        },
        "RE ACTIVARSE": ["-- Seleccionar --"],
        "NUEVO LOCAL": ["-- Seleccionar --"],
        OTRO: ["-- Seleccionar --"],
      },
      claims: {
        RECLAMO: {
          "PROBLEMAS COMERCIALES": [
            "SERVICIO COSTOSO",
            "NO ME ADAPTE",
            "PORCENTAJE DE VENTA",
          ],
          "PROBLEMAS DE SERVICIO": [
            "NO DAMOS SOLUCIONES RAPIDAS",
            "NO SOLUCIONAMOS PROBLEMAS",
            "MALA ATENCION",
          ],
          "PROBLEMAS DEL SISTEMA": ["LENTITUD", "ERRORES EN LA PLATAFORMA"],
          OTRO: ["-- Seleccionar --"],
        },
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    validateFormat() {
      if (/[^0-9-]/.test(this.inputValue)) {
        this.showError = true;
        this.errorMessage =
          this.t.m11000_471 || "Se deben ingresar solo números.";
      } else {
        // Remove any non-digit characters and format the input
        let formattedInput = this.inputValue.replace(/[^0-9-]/g, "");
        if (formattedInput.length >= 16) {
          formattedInput =
            formattedInput.slice(0, 16) + "-" + formattedInput.slice(16);
        }
        // Remove extra hyphens
        formattedInput = formattedInput.replace(/-{2,}/g, "-");
        if (formattedInput.length > 22) {
          formattedInput = formattedInput.slice(0, 22);
        }
        this.inputValue = formattedInput;

        // Check if the formatted input is valid
        if (formattedInput === "" || /^\d{16}-\d{5}$/.test(formattedInput)) {
          this.showError = false;
        } else {
          this.showError = true;
          this.errorMessage =
            this.m11000_472 ||
            "Ingreso inválido. Por favor, usa el siguiente formato: 1234567890123456-12345";
        }
      }
    },
  },
  computed: {
    incidentList() {
      if (this.incidentType == "Problema") {
        return this.problems;
      } else if (this.incidentType == "Solicitud") {
        return this.requests;
      } else if (this.incidentType == "Reclamo") {
        return this.claims;
      } else {
        return {};
      }
    },
  },
  watch: {
    incidentType(newValue) {
      if (newValue == "Problema") {
        this.recordType = "012DN000000Ci4d";
      } else if (newValue == "Solicitud") {
        this.recordType = "012DN000000CkUT";
      } else if (newValue == "Reclamo") {
        this.recordType = "012DN000000Ci4Y";
      } else {
        this.recordType = "";
      }
      this.level1 = null;
      this.level2 = null;
      this.level3 = null;
    },
    level1(newValue) {
      this.level1 = newValue;
      this.level2 = null;
      this.level3 = null;
    },
    level2(newValue) {
      this.level2 = newValue;
      this.level3 = null;
    },
  },
  mounted() {
    this.language = document.location.pathname.split("/")[1];
  },
};
</script>
