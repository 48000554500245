export const animation = {
  v: "5.9.6",
  fr: 25,
  ip: 0,
  op: 125,
  w: 1834,
  h: 1920,
  nm: "Integraciones Home",
  ddd: 0,
  assets: [
    {
      id: "image_0",
      w: 900,
      h: 900,
      p: require("./video_images/img_0.png"),
      u: "",
      e: 0,
    },
    {
      id: "image_1",
      w: 512,
      h: 512,
      p: require("./video_images/img_1.png"),
      u: "",
      e: 0,
    },
    {
      id: "image_2",
      w: 500,
      h: 500,
      p: require("./video_images/img_2.png"),
      u: "",
      e: 0,
    },
    {
      id: "image_3",
      w: 900,
      h: 900,
      p: require("./video_images/img_3.png"),
      u: "",
      e: 0,
    },
    {
      id: "image_4",
      w: 1240,
      h: 955,
      p: require("./video_images/img_4.png"),
      u: "",
      e: 0,
    },
    {
      id: "image_5",
      w: 1240,
      h: 955,
      p: require("./video_images/img_5.png"),
      u: "",
      e: 0,
    },
    {
      id: "image_6",
      w: 1240,
      h: 955,
      p: require("./video_images/img_6.png"),
      u: "",
      e: 0,
    },
    {
      id: "image_7",
      w: 1240,
      h: 955,
      p: require("./video_images/img_7.png"),
      u: "",
      e: 0,
    },
    {
      id: "image_8",
      w: 1240,
      h: 955,
      p: require("./video_images/img_8.png"),
      u: "",
      e: 0,
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: "CLeverlynk.png",
      cl: "png",
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.833,
                y: 0.833,
              },
              o: {
                x: 0.167,
                y: 0.167,
              },
              t: 67,
              s: [429, 1000, 0],
              to: [39, 166.5, 0],
              ti: [-150, -1.5, 0],
            },
            {
              t: 81,
              s: [735, 1261, 0],
            },
          ],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [450, 450, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [0.833, 0.833, 0.833],
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0.167, 0.167, 0.167],
              },
              t: 67,
              s: [0, 0, 100],
            },
            {
              t: 81,
              s: [21, 21, 100],
            },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 125,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "Capa de formas 1",
      parent: 1,
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 28,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [1116.667, -783.333, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [388.72, 389.577, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ef: [
        {
          ty: 29,
          nm: "Desenfoque gaussiano",
          np: 5,
          mn: "ADBE Gaussian Blur 2",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Desenfoque",
              mn: "ADBE Gaussian Blur 2-0001",
              ix: 1,
              v: {
                a: 0,
                k: 37.6,
                ix: 1,
              },
            },
            {
              ty: 7,
              nm: "Dimensiones de desenfoque",
              mn: "ADBE Gaussian Blur 2-0002",
              ix: 2,
              v: {
                a: 0,
                k: 1,
                ix: 2,
              },
            },
            {
              ty: 7,
              nm: "Repetir píxeles del borde",
              mn: "ADBE Gaussian Blur 2-0003",
              ix: 3,
              v: {
                a: 0,
                k: 0,
                ix: 3,
              },
            },
          ],
        },
      ],
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "rc",
              d: 1,
              s: {
                a: 0,
                k: [213, 216],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              r: {
                a: 0,
                k: 20,
                ix: 4,
              },
              nm: "Trazado de rectángulo 1",
              mn: "ADBE Vector Shape - Rect",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.105881993911, 0.105881993911, 0.105881993911, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 0,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Trazo 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Relleno 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [-168.5, 321],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transformar",
            },
          ],
          nm: "Rectángulo 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 125,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: "didi.png",
      cl: "png",
      refId: "image_1",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.332,
                y: 1,
              },
              o: {
                x: 0.549,
                y: 0,
              },
              t: 49,
              s: [446, 1169, 0],
              to: [32.5, 176.5, 0],
              ti: [111.5, -50.5, 0],
            },
            {
              t: 69,
              s: [299, 1526, 0],
            },
          ],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [256, 256, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [0.833, 0.833, 0.833],
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0.167, 0.167, 0.167],
              },
              t: 49,
              s: [0, 0, 100],
            },
            {
              t: 63,
              s: [32, 32, 100],
            },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 125,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: "Dispapeles.png",
      cl: "png",
      refId: "image_2",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.192,
                y: 1,
              },
              o: {
                x: 0.604,
                y: 0,
              },
              t: 87,
              s: [487, 1125, 0],
              to: [308.5, 375.5, 0],
              ti: [-224.5, 77.5, 0],
            },
            {
              t: 103,
              s: [1438, 1506, 0],
            },
          ],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [250, 250, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [0.833, 0.833, 0.833],
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0.167, 0.167, 0.167],
              },
              t: 87,
              s: [0, 0, 100],
            },
            {
              t: 99,
              s: [35, 35, 100],
            },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 125,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: "Capa de formas 2",
      parent: 4,
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 28,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [630, -495.714, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [233.232, 233.746, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ef: [
        {
          ty: 29,
          nm: "Desenfoque gaussiano",
          np: 5,
          mn: "ADBE Gaussian Blur 2",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Desenfoque",
              mn: "ADBE Gaussian Blur 2-0001",
              ix: 1,
              v: {
                a: 0,
                k: 37.6,
                ix: 1,
              },
            },
            {
              ty: 7,
              nm: "Dimensiones de desenfoque",
              mn: "ADBE Gaussian Blur 2-0002",
              ix: 2,
              v: {
                a: 0,
                k: 1,
                ix: 2,
              },
            },
            {
              ty: 7,
              nm: "Repetir píxeles del borde",
              mn: "ADBE Gaussian Blur 2-0003",
              ix: 3,
              v: {
                a: 0,
                k: 0,
                ix: 3,
              },
            },
          ],
        },
      ],
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "rc",
              d: 1,
              s: {
                a: 0,
                k: [213, 216],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              r: {
                a: 0,
                k: 20,
                ix: 4,
              },
              nm: "Trazado de rectángulo 1",
              mn: "ADBE Vector Shape - Rect",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.105881993911, 0.105881993911, 0.105881993911, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 0,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Trazo 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Relleno 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [-168.5, 321],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transformar",
            },
          ],
          nm: "Rectángulo 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 125,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 2,
      nm: "Factura 1 app.png",
      cl: "png",
      refId: "image_3",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.833,
                y: 0.833,
              },
              o: {
                x: 0.167,
                y: 0.167,
              },
              t: 79,
              s: [403, 938, 0],
              to: [428.5, -88.5, 0],
              ti: [39.5, 178.5, 0],
            },
            {
              t: 91,
              s: [994, 443, 0],
            },
          ],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [450, 450, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [0.833, 0.833, 0.833],
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0.167, 0.167, 0.167],
              },
              t: 79,
              s: [0, 0, 100],
            },
            {
              t: 91,
              s: [19, 19, 100],
            },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 125,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: "Capa de formas 3",
      parent: 6,
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 28,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [1172.645, -903.118, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [429.638, 430.585, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ef: [
        {
          ty: 29,
          nm: "Desenfoque gaussiano",
          np: 5,
          mn: "ADBE Gaussian Blur 2",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Desenfoque",
              mn: "ADBE Gaussian Blur 2-0001",
              ix: 1,
              v: {
                a: 0,
                k: 44.4,
                ix: 1,
              },
            },
            {
              ty: 7,
              nm: "Dimensiones de desenfoque",
              mn: "ADBE Gaussian Blur 2-0002",
              ix: 2,
              v: {
                a: 0,
                k: 1,
                ix: 2,
              },
            },
            {
              ty: 7,
              nm: "Repetir píxeles del borde",
              mn: "ADBE Gaussian Blur 2-0003",
              ix: 3,
              v: {
                a: 0,
                k: 0,
                ix: 3,
              },
            },
          ],
        },
      ],
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "rc",
              d: 1,
              s: {
                a: 0,
                k: [213, 216],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              r: {
                a: 0,
                k: 20,
                ix: 4,
              },
              nm: "Trazado de rectángulo 1",
              mn: "ADBE Vector Shape - Rect",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.105881993911, 0.105881993911, 0.105881993911, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 0,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Trazo 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: "Relleno 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [-168.5, 321],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transformar",
            },
          ],
          nm: "Rectángulo 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 125,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 2,
      nm: "Rappi",
      refId: "image_4",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.23,
                y: 1,
              },
              o: {
                x: 0.115,
                y: 0,
              },
              t: 14,
              s: [393.234, 1241.975, 0],
              to: [112.667, -332.667, 0],
              ti: [223.333, 68.667, 0],
            },
            {
              t: 22,
              s: [277.234, 449.975, 0],
            },
          ],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [949.044, 841.707, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [0.833, 0.833, 0.833],
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0.167, 0.167, 0.167],
              },
              t: 14,
              s: [0, 0, 100],
            },
            {
              t: 25,
              s: [158, 158, 100],
            },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 125,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 2,
      nm: "Justo",
      refId: "image_5",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0,
                y: 1,
              },
              o: {
                x: 0.422,
                y: 0,
              },
              t: 34,
              s: [409.806, 1294.456, 0],
              to: [-112, -9.333, 0],
              ti: [0.667, 131.333, 0],
            },
            {
              t: 43,
              s: [217.806, 1046.456, 0],
            },
          ],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [1070.506, 466.227, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [0.833, 0.833, 0.833],
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0.167, 0.167, 0.167],
              },
              t: 35,
              s: [0, 0, 100],
            },
            {
              t: 46,
              s: [158, 158, 100],
            },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 125,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 2,
      nm: "Texto-Iconco",
      refId: "image_6",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [1051.275, 997.822, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [619.93, 477.15, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [158, 158, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 125,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 2,
      nm: "selección",
      refId: "image_7",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [1051.275, 997.822, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [619.93, 477.15, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [158, 158, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 125,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 2,
      nm: "Capa 1",
      refId: "image_8",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [1051.275, 997.822, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [619.93, 477.15, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [158, 158, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 125,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
  metadata: {
    filename: "integraciones.aep",
  },
};
