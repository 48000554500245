<template>
  <div class="section-container" id="users">
    <div class="section-text">
      <h4 class="hidden hidden-top" v-focus="'showElement'">
        {{
          t.m11000_45 ||
          "+150 mil usuarios en +60 ciudades a lo largo de Latinoamérica confían en Toteat"
        }}
      </h4>
    </div>
    <div class="marquee">
      <div class="track">
        <img
          v-for="(logo, index) in logos[countryImages]"
          v-bind:key="index"
          :src="logo.image"
          :alt="logo.title"
        />
        <!-- We repeat the img v-for to make the marquee works properly (loop). -->
        <!-- Inspired: https://codepen.io/abhikb/pen/yLgMvGw -->
        <img
          v-for="logo in logos[countryImages]"
          v-bind:key="logo.title"
          :src="logo.image"
          :alt="logo.title"
        />
      </div>
    </div>
  </div>
</template>

<script>
import mixinGlobals from "@/components/_mixins/globalMixins";

export default {
  mixins: [mixinGlobals],
  props: { country: String },
  data() {
    return {
      countryImages: "cl",
    };
  },
  computed: {
    logos() {
      return {
        ar: [
          {
            alt: this.t.m11000_37 || "Clientes Toteat",
            title: "Inn Amor Ato",
            image: require("../../../public/images/toteat-clients/ar/amor-ato-restaurante-argentina-toteat.webp"),
          },
          {
            title: "El 8",
            image: require("../../../public/images/toteat-clients/ar/el-8-restaurante-argentina-toteat.webp"),
          },
          {
            title: "Gontran Cherrier",
            image: require("../../../public/images/toteat-clients/ar/gontran-cherrier-restaurante-argentina-toteat.webp"),
          },
          {
            title: "Mansalva",
            image: require("../../../public/images/toteat-clients/ar/mansalva-restaurante-argentina-toteat.webp"),
          },
          {
            title: "Rocks and Ribs",
            image: require("../../../public/images/toteat-clients/ar/rocks-and-ribs-restaurante-argentina-toteat.webp"),
          },
          {
            title: "Roma",
            image: require("../../../public/images/toteat-clients/ar/roma-restaurante-argentina-toteat.webp"),
          },
          {
            title: "Tarantino",
            image: require("../../../public/images/toteat-clients/ar/tarantino-restaurante-argentina-toteat.webp"),
          },
          {
            title: "Vermu Fuerza",
            image: require("../../../public/images/toteat-clients/ar/vermu-fuerza-restaurante-argentina-toteat.webp"),
          },
        ],
        cl: [
          {
            alt: this.t.m11000_37 || "Clientes Toteat",
            title: "Ambrosia Bistro",
            image: require("../../../public/images/toteat-clients/cl/ambrosia-bistro-restaurante-toteat.webp"),
          },
          {
            title: "La Picantería",
            image: require("../../../public/images/toteat-clients/cl/la-picanteria-restaurante-toteat.webp"),
          },
          {
            title: "La Vinoteca",
            image: require("../../../public/images/toteat-clients/cl/la-vinoteca-restaurante-toteat.webp"),
          },
          {
            title: "Mesa Tropera",
            image: require("../../../public/images/toteat-clients/cl/mesa-tropera-restaurante-toteat.webp"),
          },
          {
            title: "Mokka",
            image: require("../../../public/images/toteat-clients/cl/mokka-cafeteria-toteat.webp"),
          },
          {
            title: "Pomeriggio Bistro",
            image: require("../../../public/images/toteat-clients/cl/pomeriggio-bistro-restaurante-toteat.webp"),
          },
          {
            title: "Santa Brasa",
            image: require("../../../public/images/toteat-clients/cl/santa-brasa-restaurante-toteat.webp"),
          },
          {
            title: "Sarita Colonia",
            image: require("../../../public/images/toteat-clients/cl/sarita-colonia-restaurante-toteat.webp"),
          },
        ],
        co: [
          {
            alt: this.t.m11000_37 || "Clientes Toteat",
            title: "Amor Perfecto",
            image: require("../../../public/images/toteat-clients/co/amor-perfecto-colombia-toteat.webp"),
          },
          {
            title: "Dippo",
            image: require("../../../public/images/toteat-clients/co/dippo-colombia-toteat.webp"),
          },
          {
            title: "Fasfú Burgers",
            image: require("../../../public/images/toteat-clients/co/fasfu204129-colombia-toteat.webp"),
          },
          {
            title: "Guerrero",
            image: require("../../../public/images/toteat-clients/co/guerrero-colombia-toteat.webp"),
          },
          {
            title: "Hungry Mob",
            image: require("../../../public/images/toteat-clients/co/hungry-mob-colombia-toteat.webp"),
          },
          {
            title: "Nimi's",
            image: require("../../../public/images/toteat-clients/co/nimis-colombia-toteat.webp"),
          },
          {
            title: "Franco Cocina Honesta",
            image: require("../../../public/images/toteat-clients/co/franco-cocina-honesta-colombia-toteat.webp"),
          },
          {
            title: "Oh My Sandwich",
            image: require("../../../public/images/toteat-clients/co/oh-my-restaurante-colombia-toteat.webp"),
          },
        ],
        mx: [
          {
            alt: this.t.m11000_37 || "Clientes Toteat",
            title: "Rock N' Wok",
            image: require("../../../public/images/toteat-clients/mx/ck-and-wok-restaurante-mexico-toteat.webp"),
          },
          {
            title: "El Churrasquito",
            image: require("../../../public/images/toteat-clients/mx/el-churrasquito-mexico-toteat.webp"),
          },
          {
            title: "Munchis Burger",
            image: require("../../../public/images/toteat-clients/mx/munchis-hamburguesas-mexico-toteat.webp"),
          },
          {
            title: "Mx Bistro",
            image: require("../../../public/images/toteat-clients/mx/mx-bistro-mexico-toteat.webp"),
          },
          {
            title: "La Parrillita",
            image: require("../../../public/images/toteat-clients/mx/parrillita-mexico-restaurante-toteat.webp"),
          },
          {
            title: "Tatel",
            image: require("../../../public/images/toteat-clients/mx/tatel-restaurante-mexico-toteat.webp"),
          },
          {
            title: "Tomahawk",
            image: require("../../../public/images/toteat-clients/mx/tomahawk-restaurante-mexico-toteat.webp"),
          },
          {
            title: "Tortas Locas",
            image: require("../../../public/images/toteat-clients/mx/tortas-locas-mexico-toteat.webp"),
          },
        ],
        pe: [
          {
            alt: this.t.m11000_37 || "Clientes Toteat",
            title: "Alba",
            image: require("../../../public/images/toteat-clients/pe/alba-restaurante-peru-toteat.webp"),
          },
          {
            title: "Hungry Mob",
            image: require("../../../public/images/toteat-clients/pe/hungry-restaurante-peru-toteat.webp"),
          },
          {
            title: "Inkay",
            image: require("../../../public/images/toteat-clients/pe/inkayrestaurante-peru-toteat.webp"),
          },
          {
            title: "La Burger",
            image: require("../../../public/images/toteat-clients/pe/la-burger-restaurante-peru-toteat.webp"),
          },
          {
            title: "Mooi Heladería",
            image: require("../../../public/images/toteat-clients/pe/mooi-heladeria-peru-toteat.webp"),
          },
          {
            title: "Nimi's",
            image: require("../../../public/images/toteat-clients/pe/nimis-restaurante-peru-toteat.webp"),
          },
          {
            title: "Oh My Sandwich",
            image: require("../../../public/images/toteat-clients/pe/oh-my-restaurante-peru-toteat.webp"),
          },
          {
            title: "P 27",
            image: require("../../../public/images/toteat-clients/pe/p-27-restaurante-peru-toteat.webp"),
          },
        ],
      };
    },
  },
  mounted() {
    if (Object.prototype.hasOwnProperty.call(this.logos, this.country)) {
      this.countryImages = this.country;
    }
  },
};
</script>
