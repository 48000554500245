<template>
  <section id="slider">
    <input type="radio" name="slider" id="s1" checked />
    <input type="radio" name="slider" id="s2" />
    <input type="radio" name="slider" id="s3" />
    <input type="radio" name="slider" id="s4" />
    <input type="radio" name="slider" id="s5" />

    <label for="s1" id="slide1"></label>
    <label for="s2" id="slide2"></label>
    <label for="s3" id="slide3"></label>
    <label for="s4" id="slide4"></label>
    <label for="s5" id="slide5"></label>
  </section>
</template>

<script>
var recurrent;
function Check_next() {
  var wanted = document.getElementsByName("slider");
  for (var i = 0; i < wanted.length; ++i) {
    if (wanted[i].checked == true) {
      if (i == wanted.length - 1) {
        document.getElementsByName("slider")[0].checked = true;
      } else {
        document.getElementsByName("slider")[i + 1].checked = true;
      }
      break;
    }
  }
}
export default {
  name: "SliderComponent",
  mounted() {
    clearInterval(recurrent);
    recurrent = setInterval(function () {
      Check_next();
    }, 3000);
  },
};
</script>
