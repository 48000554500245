<template>
  <b-navbar centered transparent shadow fixed-top>
    <template #brand>
      <b-navbar-item
        tag="router-link"
        :to="{
          name: 'homeLang',
          params: { lang: language },
        }"
      >
        <img
          class="logo"
          alt="Toteat"
          src="../../public/images/logos/toteat-black-4k.png"
        />
      </b-navbar-item>
    </template>
    <template #start>
      <div class="div-options-buttons" v-if="width <= 1023">
        <b-navbar-item class="is-client" id="user">
          <router-link
            :to="{
              name: 'toteatUser',
              params: { lang: language },
            }"
          >
            <b>{{ t.m11000_65 || "Soy Toteat" }}</b>
          </router-link></b-navbar-item
        >
        <b-navbar-item tag="div" id="sales">
          <router-link
            :to="{
              name: 'contactSales',
              params: { lang: language },
            }"
          >
            <b-button class="button-black">{{
              t.m11000_66 || "Contáctate con ventas"
            }}</b-button>
          </router-link>
        </b-navbar-item>
      </div>
      <div class="navbar-item has-dropdown is-hoverable">
        <router-link
          :to="{
            name: 'solutions',
            params: { lang: language },
          }"
        >
          <b-navbar-item
            v-if="width > 1023"
            class="navbar-link options-title"
            >{{ t.m11000_447 || "Productos" }}</b-navbar-item
          >

          <div
            v-else-if="width <= 1023"
            class="options-title"
            :key="1"
            @click="changeOption(1)"
          >
            <a class="navbar-link is-arrowless">{{
              t.m11000_447 || "Productos"
            }}</a>
            <div class="clickable-arrow" id="hover-black"></div></div
        ></router-link>
        <div
          class="navbar-dropdown"
          v-if="width > 1023 || (width <= 1023 && activateSectionIndex == 1)"
        >
          <b-navbar-item @click="goToPricing('/productos', 'checkin-title')">{{
            t.m11000_364 || "Toteat Shop"
          }}</b-navbar-item>
          <b-navbar-item @click="goToPricing('/productos', 'invoices-title')">{{
            t.m11000_67 || "Boleta y Factura Electrónica"
          }}</b-navbar-item>
          <b-navbar-item @click="goToPricing('/productos', 'point-of-sale')">{{
            t.m11000_68 || "Punto de Venta (POS)"
          }}</b-navbar-item>
          <b-navbar-item @click="goToPricing('/productos', 'KDS-system')">{{
            t.m11000_363 || "Kitchen Display System (KDS)"
          }}</b-navbar-item>
          <b-navbar-item
            @click="goToPricing('/productos', 'delivery-integration')"
            >{{ t.m11000_69 || "Integraciones" }}</b-navbar-item
          >
          <b-navbar-item
            @click="goToPricing('/productos', 'inventary-stock')"
            >{{ t.m11000_70 || "Recetas e Inventario" }}</b-navbar-item
          >
          <b-navbar-item @click="goToPricing('/productos', 'analytics')">
            {{ t.m11000_110 || "Toteat Analytics" }}
          </b-navbar-item>
        </div>
      </div>
      <hr v-if="width <= 1023" class="navbar-mobile" />
      <div class="navbar-item has-dropdown is-hoverable">
        <b-navbar-item
          v-if="width > 1023"
          class="navbar-link"
          id="business-types"
          >{{ t.m11000_62 || "Tipos de negocio" }}</b-navbar-item
        >
        <div
          v-else-if="width <= 1023"
          class="options-title"
          :key="2"
          @click="changeOption(2)"
        >
          <a class="navbar-link" id="business-types">{{
            t.m11000_62 || "Tipos de negocio"
          }}</a>
          <div class="clickable-arrow" id="hover-black"></div>
        </div>
        <div
          class="navbar-dropdown"
          v-if="width > 1023 || (width <= 1023 && activateSectionIndex == 2)"
        >
          <b-navbar-item
            tag="router-link"
            :to="{
              name: 'restaurant',
              params: { lang: language },
            }"
            >{{ t.m11000_71 || "Restaurantes" }}</b-navbar-item
          >
          <b-navbar-item
            tag="router-link"
            :to="{
              name: 'coffee',
              params: { lang: language },
            }"
            >{{ t.m11000_72 || "Cafeterías" }}</b-navbar-item
          >
          <b-navbar-item
            tag="router-link"
            :to="{
              name: 'fastFood',
              params: { lang: language },
            }"
            >{{ t.m11000_140 || "Fast Food" }}</b-navbar-item
          >
          <b-navbar-item
            tag="router-link"
            :to="{
              name: 'darkKitchen',
              params: { lang: language },
            }"
            >{{ t.m11000_135 || "Dark Kitchen" }}</b-navbar-item
          >
          <b-navbar-item
            tag="router-link"
            :to="{
              name: 'bar',
              params: { lang: language },
            }"
            >{{ t.m11000_73 || "Bares & Cervecerías" }}</b-navbar-item
          >
          <!-- <b-navbar-item
            tag="router-link"
            :to="{
              name: 'minimarket',
              params: { lang: language },
            }"
            >{{ t.m11000_74 || "Minimarkets & Almacenes" }}</b-navbar-item
          > -->
        </div>
      </div>
      <hr v-if="width <= 1023" class="navbar-mobile" />
      <b-navbar-item
        class="navbar-link options-title"
        tag="router-link"
        :to="{
          name: 'whyToteat',
          params: { lang: language },
        }"
        >{{ t.m11000_63 || "¿Por qué Toteat?" }}</b-navbar-item
      >
      <hr v-if="width <= 1023" class="navbar-mobile" />
      <b-navbar-item
        class="navbar-link options-title"
        tag="router-link"
        :to="{
          name: 'prices',
          params: { lang: language },
        }"
        >{{ t.m11000_75 || "Precios" }}</b-navbar-item
      >
      <hr v-if="width <= 1023" class="navbar-mobile" />
      <div class="navbar-item has-dropdown is-hoverable">
        <b-navbar-item
          v-if="width > 1023"
          class="navbar-link"
          id="toteat-resources"
          >{{ t.m11000_64 || "Recursos" }}</b-navbar-item
        >
        <div
          v-else-if="width <= 1023"
          class="options-title"
          :key="3"
          @click="changeOption(3)"
        >
          <a class="navbar-link is-arrowless" id="toteat-resources">{{
            t.m11000_64 || "Recursos"
          }}</a>
          <div class="clickable-arrow" id="hover-black"></div>
        </div>
        <div
          class="navbar-dropdown"
          v-if="width > 1023 || (width <= 1023 && activateSectionIndex == 3)"
        >
          <b-navbar-item
            tag="router-link"
            :to="{
              name: 'successStories',
              params: { lang: language },
            }"
            >{{ t.m11000_76 || "Casos de éxito" }}</b-navbar-item
          >
          <b-navbar-item
            tag="router-link"
            :to="{
              name: 'blog',
              params: { lang: language },
            }"
            >Blog</b-navbar-item
          >
          <b-navbar-item
            tag="router-link"
            :to="{
              name: 'workWithToteat',
              params: { lang: language },
            }"
            >{{ t.m11000_60 || "Trabaja con nosotros" }}</b-navbar-item
          >
        </div>
      </div>
      <hr v-if="width <= 1023" class="navbar-mobile" />
    </template>
    <template #end>
      <b-navbar-item
        v-if="width > 1023"
        tag="router-link"
        :to="{
          name: 'toteatUser',
          params: { lang: language },
        }"
        id="user"
      >
        <b v-if="width < 1110">{{ t.m11000_65 || "Soy Toteat" }}</b>
        <b v-else>{{ t.m11000_65 || "Soy Toteat" }}</b>
      </b-navbar-item>
      <b-navbar-item v-if="width > 1023" tag="div" id="sales">
        <b-button
          v-if="width < 1130"
          class="button-black"
          @click="redirect('contactSales')"
          >{{ t.m11000_362 || "Contacto" }}</b-button
        >
        <b-button
          v-else
          class="button-black"
          @click="redirect('contactSales')"
          >{{ t.m11000_66 || "Contáctate con ventas" }}</b-button
        >
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: "NavbarComponent",
  data() {
    return {
      width: window.innerWidth,
      activateSectionIndex: 0,
    };
  },
  methods: {
    redirect(value) {
      if (this.language) {
        this.$router.push({
          name: value,
          params: { lang: this.language },
        });
      }
    },
    goTo(refName) {
      const elem = document.getElementById(refName);
      const yOffset = -150;
      const y = elem.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    },
    goToPricing(path, refName) {
      if (!window.location.pathname.includes(path)) {
        var th = this;
        this.$router.push({ name: "solutions" }).then(() => {
          setTimeout(function () {
            th.goTo(refName);
          }, 200);
        });
      } else {
        this.goTo(refName);
      }
    },
    onResize() {
      this.width = window.innerWidth;
    },
    changeOption(num) {
      if (num == this.activateSectionIndex) {
        this.activateSectionIndex = 0;
      } else {
        this.activateSectionIndex = num;
      }
    },
  },
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
