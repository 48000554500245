<template>
  <div class="section-container" id="checkin">
    <div v-if="width > 1023" class="desktop-container" id="desktop-order">
      <div class="section-text">
        <h2 class="hidden" v-focus="'showElement'">
          {{ t.m11000_364 || "Toteat Shop" }}
        </h2>
        <p class="text-bold hidden-right" v-focus="'showElement'">
          {{
            t.m11000_33 ||
            "¡Conecta con tus clientes a través de tu propia vitrina digital!"
          }}
        </p>
        <p class="hidden hidden-right" v-focus="'showElement'">
          {{
            t.m11000_34 ||
            "Ofrece comida a domicilio, activa tu menú QR y permite que tus comensales pidan desde la mesa. Toteat Shop es el ecosistema para ayudarte a vender más."
          }}
        </p>
        <b-button class="button-red-black" @click="toteatShop('solutions')">{{
          t.m11000_35 || "Conoce más"
        }}</b-button>
      </div>
      <div class="toteat-app-slider">
        <img
          src="../../../public/images/bank-images/QR.png"
          :alt="t.m11000_82 || 'qr toteat'"
        />
        <Slider />
      </div>
    </div>
    <div v-else class="mobile-container" id="mobile-order">
      <div class="section-text">
        <h2>
          {{ t.m11000_364 || "Toteat Shop" }}
        </h2>
        <p>
          {{
            t.m11000_33 ||
            "¡Conecta con tus clientes a través de tu propia vitrina digital!"
          }}
        </p>
      </div>
      <div class="toteat-app-slider">
        <img
          src="../../../public/images/bank-images/QR.png"
          :alt="t.m11000_82 || 'qr toteat'"
        />
        <Slider />
      </div>
      <div class="section-text">
        <p>
          {{
            t.m11000_34 ||
            "Ofrece comida a domicilio, activa tu menú QR y permite que tus comensales pidan desde la mesa. Toteat Shop es el ecosistema para ayudarte a vender más."
          }}
        </p>
        <b-button class="button-red-black" @click="toteatShop('solutions')">{{
          t.m11000_35 || "Conoce más"
        }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import mixinGlobals from "@/components/_mixins/globalMixins";
import Slider from "./SliderHome.vue";

export default {
  name: "SliderComponent",
  mixins: [mixinGlobals],
  components: {
    Slider,
  },
  data() {
    return { width: window.innerWidth };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
    },
    toteatShop(value) {
      this.$router
        .push({
          name: value,
          params: { lang: this.$store.state.luces.language },
        })
        .then(() => {
          setTimeout(function () {
            const elem = document.getElementById("checkin-solution");
            const yOffset = 230;
            const y =
              elem.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: "smooth" });
          }, 200);
        });
    },
  },
};
</script>
