<template>
  <div class="workWithUs">
    <Header />
  </div>
</template>

<script>
import Header from "@/components/ResourcesToteat/WorkWithUs/HeaderWorkWithUs.vue";
import mixinGlobals from "@/components/_mixins/globalMixins";

export default {
  name: "WorkWithUsView",
  mixins: [mixinGlobals],
  metaInfo() {
    return {
      title: this.t.m11000_60 || "Trabaja con nosotros",
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            this.t.m11000_437 ||
            "Súmate a nuestro equipo y digitalicemos el rubro gastronómico",
        },
      ],
      // link: [
      //   {
      //     rel: "canonical",
      //     href: window.location.href,
      //   },
      // ],
    };
  },
  components: {
    Header,
  },
  mounted() {
    var language = document.location.pathname.split("/")[1];
    var routeName = this.$route.name;
    this.updateLang(language, routeName);
  },
};
</script>
