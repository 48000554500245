<template>
  <div class="section-container" id="services">
    <div
      v-if="country != 'pe' && country != 'mx'"
      class="info-container-left-white"
    >
      <div class="base-container">
        <img
          id="main-img-desktop"
          src="../../../public/images/bank-images/ticket-example.jpeg"
          :alt="t.m11000_67 || 'Boleta y Factura Electrónica'"
        />
        <img
          id="main-img-mobile"
          src="../../../public/images/bank-images/ticket-example-bigger.jpeg"
          :alt="t.m11000_67 || 'Boleta y Factura Electrónica'"
        />
        <div class="container-text" id="toteat-invoices">
          <h4 id="invoices-title">
            {{
              t.m11000_36 ||
              "Documentos Tributarios Electrónicos (DTE): Boletas y Facturas."
            }}
          </h4>
          <p id="container-description">
            {{
              t.m11000_37 ||
              "Folios ilimitados e historial de tus ventas en un solo lugar."
            }}
          </p>
          <ul>
            <li>
              <img
                src="../../../public/svgs/icons-svg/check.svg"
                id="check-icon"
                alt="toteat sales manager"
              />
              <p id="list-text">
                {{
                  t.m11000_40 || "Conexión directa con su sistema tributario."
                }}
                <!-- Conexión directa con su sistema tributario SII. -->
              </p>
            </li>
            <li>
              <img
                src="../../../public/svgs/icons-svg/check.svg"
                id="check-icon"
                alt="toteat restaurant manager"
              />
              <p id="list-text">
                {{ t.m11000_41 || "Impresión de boletas sin comisión extra." }}
              </p>
            </li>
            <li>
              <img
                src="../../../public/svgs/icons-svg/check.svg"
                id="check-icon"
                alt="toteat software gastronómico"
              />
              <p id="list-text">
                {{
                  t.m11000_42 ||
                  "Genera notas de crédito de forma fácil y rápida."
                }}
              </p>
            </li>
          </ul>
        </div>
        <img
          id="overhead"
          src="../../../public/images/toteat-services/electronic-ticket.png"
          alt="boleta electrónica"
        />
      </div>
    </div>
    <div class="container-text" id="middle-container">
      <h2 id="point-of-sale">{{ t.m11000_68 || "Punto de Venta (POS)" }}</h2>
      <p class="text-bold">
        {{
          t.m11000_92 ||
          "El negocio se administra mejor cuando todo funciona de manera integral."
        }}
      </p>
      <p id="container-description">
        {{
          t.m11000_93 ||
          "Contamos con todas las herramientas que harán crecer la productividad de tu negocio, te ayudamos a facturar de forma rápida y a llevar el control total de tus ingresos."
        }}
      </p>
      <img
        src="../../../public/images/toteat-services/punto-de-venta-pos-toteat.jpg"
        :alt="t.m11000_22 || 'punto de venta toteat'"
      />
    </div>
    <div class="info-container-right-white" id="kds">
      <div class="base-container">
        <div class="container-text" id="KDS">
          <h2 id="KDS-system">Kitchen Display System (KDS)</h2>
          <p id="container-description">
            {{
              t.m11000_94 ||
              "Sistema de exhibición que conecta a la perfección a tus meseros con el staff de cocina para que puedan ofrecer las mejores preparaciones."
            }}
          </p>
          <ul>
            <li v-for="(itemList, indexList) in kdsList" v-bind:key="indexList">
              <img
                src="../../../public/svgs/icons-svg/check.svg"
                id="check-icon"
                alt="toteat CRM"
              />
              <p id="list-text">
                {{ itemList.item }}
              </p>
            </li>
          </ul>
        </div>
        <img
          class="desktop"
          id="main-img"
          src="../../../public/images/toteat-services/kitchen-display-system-toteat.jpg"
          alt="kitchen display system"
        />
        <div class="images-container mobile">
          <img
            class="mobile"
            id="main-img"
            src="../../../public/images/toteat-services/kitchen-display-system-mobile-toteat.jpg"
            :alt="t.m11000_212 || 'kitchen display system'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      kdsList: [
        {
          item:
            this.t.m11000_95 ||
            "El KDS registrará tus pedidos en tiempo real a tu staff de cocina.",
        },
        {
          item:
            this.t.m11000_96 ||
            "Obtén información de los tiempos de elaboración de tus platos y los peaks de venta.",
        },
        {
          item:
            this.t.m11000_97 ||
            "Obtén información necesaria para controlar la gestión de tu equipo de cocina.",
        },
        {
          item:
            this.t.m11000_98 ||
            "Completamente customizable: configura los colores de tus preparaciones según la etapa de tu pedido y el tipo de preparación.",
        },
      ],
      activeIntegrationIndex: 0,
      width: window.innerWidth,
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
    },
  },
  computed: {
    country() {
      return this.$store.state.luces.language.split("-")[1].toLowerCase();
    },
  },
  watch: {
    country: {
      handler: function () {
        this.country;
      },
    },
  },
};
</script>
