import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import VueNumber from "vue-number-animation";
import VueCountryCode from "vue-country-code-select";
import VueMeta from "vue-meta";

Vue.config.productionTip = false;
Vue.use(Buefy);
Vue.use(VueNumber);
Vue.use(VueMeta);
Vue.component(VueCountryCode);

const esBuildProduccion = !(
  location.hostname.indexOf("localhost") >= 0 ||
  location.hostname.indexOf(".toteat.net") > 0
);
Vue.config.productionTip = false;
if (!esBuildProduccion) Vue.config.devtools = true;
else Vue.config.devtools = false;

// Ahora se carga plugin de LoginProfile que maneja toda la autenticacion y seguridad
// import LoginProfile from "@/plugins/LoginProfile";
// import {
//   // eslint-disable-next-line no-unused-vars
//   getBASE_URL_SERVICES11000,
//   // eslint-disable-next-line no-unused-vars
//   ambienteInicial,
// } from "@/plugins/BaseToteat/libs/comun";

import Traducciones from "@/plugins/Traducciones";
Vue.use(Traducciones, {
  modulo: 11000,
});

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
});

import { inicializaTraducciones, getIdioma } from "./plugins/Traducciones/core";
inicializaTraducciones(app.t).then(() => {
  // console.log(
  //   "Instalando Traducciones finalizada segunda fase, va a montar app"
  // );
  store.dispatch("updateLucesProp", { language: getIdioma() });
  app.$mount("#app"); // app se monta una vez leidas las traducciones para evitar errores por undefined
  console.log("Se monta app luego de Traducciones");
});

if (!esBuildProduccion) {
  // Codigo especial para Debug
  store.$app = app; // Para acceder facilmente a la instancia Vue desde store y asi por ejemplo emitir o recibir mensajes Sacar uego en PROD
  window.app = app; // Para acceder facilmente a la instancia Vue desde store y asi por ejemplo emitir o recibir mensajes
}
