<template>
  <div class="section-container" id="checkin-solution">
    <ClientsCounter />
    <div class="horizontal-container" id="upper-section">
      <img
        src="../../../public/images/toteat-services/pantalla-toteat-shop.png"
        alt="check-in"
      />
      <div class="section-text">
        <img
          id="qr-img"
          src="../../../public/images/bank-images/QR.png"
          alt="toteat QR"
        />
        <h2 id="checkin-title">{{ t.m11000_364 || "Toteat Shop" }}</h2>
        <h4>
          {{
            t.m11000_81 || "El ecosistema digital para ayudarte a vender más."
          }}
        </h4>
        <b-button class="button-red" @click="redirectToteatShop()">
          {{ this.t.m11000_35 || "Conoce más" }}
        </b-button>
      </div>
    </div>
    <div class="horizontal-container" id="accordeon-text">
      <div class="section-text">
        <div
          class="items-container"
          v-for="(item, itemIndex) in descriptionList"
          :key="itemIndex"
        >
          <div class="description-title">
            <h6>{{ item.title }}</h6>
            <div
              :class="{ 'clicked-on': itemIndex === activeItemIndex }"
              class="clickable-icon"
              id="hover-black"
              v-on:click="activeItemIndex = itemIndex"
            ></div>
          </div>
          <!-- <transition name="slide-fade"> -->
          <p v-if="activeItemIndex === itemIndex" id="element-description">
            {{ item.description }}
          </p>
          <!-- </transition> -->
          <hr class="hr-black" />
        </div>
      </div>
      <video
        autoplay
        loop
        muted
        class="desktop"
        playsinline
        disablePictureInPicture
        controlsList="nodownload"
      >
        <source src="../../../public/videos/toteat-shop.mp4" type="video/mp4" />
      </video>
    </div>
  </div>
</template>

<script>
import ClientsCounter from "@/components/Solutions/ClientsCounterSolutions.vue";
export default {
  components: {
    ClientsCounter,
  },
  data() {
    return {
      descriptionList: [
        {
          title: this.t.m11000_82 || "Menú QR",
          description:
            this.t.m11000_87 ||
            "Visualiza tu menú en una carta Digital intuitiva y amigable. Personalízala con tus fotos y descripciones. Sincronizada 100% con el POS.",
        },
        {
          title: this.t.m11000_83 || "Pedidos desde la mesa",
          description:
            this.t.m11000_88 ||
            "Acelera la toma de pedidos y permite que tus clientes puedan pedir y pagar desde sus celulares.",
        },
        {
          title: this.t.m11000_84 || "Delivery y Pick up",
          description:
            this.t.m11000_89 ||
            "Potencia y digitaliza tu canal propia venta en línea. No mas ventas por whatsapp o llamados.",
        },
        {
          title: this.t.m11000_85 || "Reservas",
          description:
            this.t.m11000_90 ||
            "Permite que tus clientes realicen reservas en línea. Lleva el control exacto de la disponibilidad de tu local.",
        },
        // {
        //   title: this.t.m11000_86 || "Divide y Paga",
        //   description:
        //     this.t.m11000_91 ||
        //     "Selecciona y paga solo los ítems que tú consumiste. Divide la cuenta en partes iguales o distintas.",
        // },
      ],
      activeItemIndex: 0,
    };
  },
  methods: {
    redirectToteatShop() {
      window.open("https://app.toteat.com/", "_blank");
    },
  },
};
</script>
