<template>
  <div class="prices">
    <Header />
    <!-- <Considerations /> -->
  </div>
</template>

<script>
import Header from "@/components/ResourcesToteat/Prices/HeaderPrices.vue";
// import Considerations from "@/components/ResourcesToteat/Prices/ConsiderationsPrices.vue";
import mixinGlobals from "@/components/_mixins/globalMixins";

export default {
  name: "PricesView",
  mixins: [mixinGlobals],
  metaInfo() {
    return {
      title: this.t.m11000_428 || "Planes y Precios",
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            this.t.m11000_429 ||
            "Cotiza el plan perfecto para digitalizar tu negocio gastronómico.",
        },
      ],
      // link: [
      //   {
      //     rel: "canonical",
      //     href: window.location.href,
      //   },
      // ],
    };
  },
  components: {
    Header,
    // Considerations,
  },
  mounted() {
    var language = document.location.pathname.split("/")[1];
    var routeName = this.$route.name;
    this.updateLang(language, routeName);
  },
};
</script>
